import AsyncStorage from '@react-native-async-storage/async-storage';

class PersistedStore {
  constructor(prefix) {
    this.prefix = prefix;
  }

  async setItem(name, values) {
    try {
      const key = `${this.prefix}${name}`;
      await AsyncStorage.setItem(key, JSON.stringify(values));
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async getItem(name) {
    try {
      const key = `${this.prefix}${name}`;
      const raw = await AsyncStorage.getItem(key);
      if (raw) {
        return JSON.parse(raw);
      }
      return undefined;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async removeItem(name) {
    const key = `${this.prefix}${name}`;
    AsyncStorage.removeItem(key);
  }

  async clear() {
    try {
      const keys = await AsyncStorage.getAllKeys();
      const filteredKeys = keys.filter(key => key.startsWith(this.prefix));
      if (filteredKeys.length > 0) {
        await AsyncStorage.multiRemove(filteredKeys);
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }
}

export default new PersistedStore('_persisted_');
