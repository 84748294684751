import React from 'react';
import { Platform } from 'react-native';
import KeyboardAvoidingViewDefault from './index.default';
import KeyboardAvoidingViewIOS from './index.ios';

const DerivedKeyboardAvoidingView = Platform.select({
  ios: KeyboardAvoidingViewIOS,
  default: KeyboardAvoidingViewDefault,
});

interface KeyboardAvoidingViewProps {
  extraWidth?: number;
  scrollViewRef?: any;
  hasMarginTop: boolean;
  children: React.ReactNode;
}

const KeyboardAvoidingView: React.FC<KeyboardAvoidingViewProps> = ({
  extraWidth,
  scrollViewRef,
  hasMarginTop = true,
  children,
}) => (
  <DerivedKeyboardAvoidingView
    scrollViewRef={scrollViewRef}
    extraWidth={extraWidth}
    hasMarginTop={hasMarginTop}
  >
    {children}
  </DerivedKeyboardAvoidingView>
);

export default KeyboardAvoidingView;
