import React, { useState, useContext, useCallback, useEffect } from 'react';
import { EditPage } from '@lendticket/ui/components/templates';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import api from 'reducers/slices/api';
import persisted from 'helpers/storage/persisted';
import PageDimensionsProvider from '@lendticket/ui/containers/PageDimensionsProvider';
import { NotificationContext } from '../../contexts';
import { userSelector } from 'reducers/slices/user';
import { shallowEqual } from 'react-redux';

const Edit: React.FC = () => {
  const { removeNotifications } = useContext(NotificationContext);
  const [allTicketsAndProfiles, setAllTicketsAndProfiles] = useState([]);
  const [lendTicketsAndProfiles, setLendTicketsAndProfiles] = useState([]);
  const [hasTicketsAndProfiles, setHasTicketsAndProfiles] = useState(true);

  const user = useAppSelector(userSelector, shallowEqual);
  const dispatch = useAppDispatch();
  const [deleteLendProfile] = api.useDeleteLendProfileMutation();
  const [deleteLendTicket] = api.useDeleteLendTicketMutation();

  const { getLendTicketsByUserId, getLendProfilesByUserId } = api.endpoints;

  const getUserLendTicketsAndProfiles = useCallback(async () => {
    const { id: userId } = user ?? {};
    const { refetch: refetchGetLendTicketsByUserId } = dispatch(
      getLendTicketsByUserId.initiate(userId),
    );
    const { refetch: refetchGetLendProfilesByUserId } = dispatch(
      getLendProfilesByUserId.initiate(userId),
    );

    const data = await Promise.all([
      refetchGetLendTicketsByUserId(),
      refetchGetLendProfilesByUserId(),
    ]);

    const { data: dataTickets } = data[0];
    const { data: dataProfiles } = data[1];

    const concatinatedData = [].concat(dataTickets.tickets, dataProfiles.profiles);

    setAllTicketsAndProfiles(concatinatedData);
    setLendTicketsAndProfiles(concatinatedData.slice(0, 10));
    setHasTicketsAndProfiles(concatinatedData.length !== 0);
  }, [dispatch, getLendProfilesByUserId, getLendTicketsByUserId, user]);

  const onEndReached = useCallback(() => {
    const numberOfVisualTickets = lendTicketsAndProfiles.length;
    const minimumRequireMentOfVisualTickets = numberOfVisualTickets >= 10;
    const allCreations = [...allTicketsAndProfiles].sort(
      (a: any, b: any) => b.createdAt - a.createdAt,
    );

    if (minimumRequireMentOfVisualTickets && numberOfVisualTickets < allTicketsAndProfiles.length) {
      const diff = allTicketsAndProfiles.length - numberOfVisualTickets;

      setLendTicketsAndProfiles(
        allCreations.concat(
          allCreations.slice(
            lendTicketsAndProfiles.length,
            lendTicketsAndProfiles.length + (diff >= 10 ? 10 : diff),
          ),
        ),
      );
    }
  }, [allTicketsAndProfiles, lendTicketsAndProfiles]);

  const onSwipeDeleteLendTicketOrProfile = useCallback(
    async ({ id, isLendProfile }: { id: string; isLendProfile: string }) => {
      const notifications = await persisted.getItem('notifications');

      if (notifications?.ticketId === id) {
        removeNotifications(id);
      }

      if (isLendProfile) {
        const filtered = lendTicketsAndProfiles.filter(({ id: derivedId }) => id !== derivedId);

        deleteLendProfile(id);
        setLendTicketsAndProfiles(filtered);
        setHasTicketsAndProfiles(filtered.length > 0);
      } else {
        const filtered = lendTicketsAndProfiles.filter(({ id: derivedId }) => id !== derivedId);

        deleteLendTicket(id);
        setLendTicketsAndProfiles(filtered);
        setHasTicketsAndProfiles(filtered.length > 0);
      }
    },
    [removeNotifications, lendTicketsAndProfiles, deleteLendTicket, deleteLendProfile],
  );

  useEffect(() => {
    getUserLendTicketsAndProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageDimensionsProvider>
      <EditPage
        cards={lendTicketsAndProfiles}
        hiddenCardText="slet"
        hasTicketsAndProfiles={hasTicketsAndProfiles}
        onEndReached={onEndReached}
        updateData={getUserLendTicketsAndProfiles}
        onSwipeAction={onSwipeDeleteLendTicketOrProfile}
      />
    </PageDimensionsProvider>
  );
};

export default Edit;
