import React, { useContext } from 'react';
import { LoadingPage } from '@lendticket/ui/components/templates';
import { Text, Wrapper, Margin } from '@lendticket/ui/components/atoms';
import { SvgImage, Gradient } from '@lendticket/ui/components/molecules';
import { LoadingContext } from 'contexts';
import { useTheme } from 'styled-components/native';

const Loading: React.FC = () => {
  const { settings } = useTheme();
  const { isLoading } = useContext(LoadingContext);

  const { COLORS } = settings;

  return (
    <>
      <Gradient />
      <LoadingPage isLoading={isLoading}>
        <Wrapper jc="center" ai="center">
          <SvgImage name="lendticket" />
          <Margin marginTop="xl">
            <Text fontType="h2" style={{ color: COLORS.WHITE }}>
              Lend Ticket
            </Text>
          </Margin>
        </Wrapper>
      </LoadingPage>
    </>
  );
};

export default Loading;
