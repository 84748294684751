import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useSharedValue } from 'react-native-reanimated';
import { SearchProviderWrapper } from '../../contexts';

interface SearchProviderProps {
  children: React.ReactNode;
}

const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
  const [jsSelectionState, setJsSelectionState] = useState({});
  const [searchQueries, setSearchQueries] = useState({});
  const animatedSearchQueries = useSharedValue({});
  const isLoading = useSharedValue(false);
  const uiSelectionState = useSharedValue({});
  const searchFromRef = useRef(null);
  const searchToRef = useRef(null);
  const searchDateRef = useRef(null);
  const refs = useMemo(
    () => ({
      from: searchFromRef,
      to: searchToRef,
      periodStart: searchDateRef,
    }),
    [],
  );

  const resetForm = useCallback(() => {
    uiSelectionState.value = {};
    animatedSearchQueries.value = {};
    refs.from.current?.clear();
    refs.to.current?.clear();
    refs.periodStart.current?.clear();
    setJsSelectionState({});
    setSearchQueries({});
  }, [animatedSearchQueries, refs, uiSelectionState]);

  return (
    <SearchProviderWrapper
      value={{
        searchQueries,
        animatedSearchQueries,
        uiSelectionState,
        isLoading,
        refs,
        jsSelectionState,
        resetForm,
        setJsSelectionState,
        setSearchQueries,
      }}
    >
      {children}
    </SearchProviderWrapper>
  );
};

export default SearchProvider;
