import Animated from 'react-native-reanimated';

interface GetButtonOnPressParams {
  i: number;
  currentItem: Animated.SharedValue<number>;
}

export const getButtonOnPress = ({ i, currentItem }: GetButtonOnPressParams) => {
  switch (i) {
    case 0: {
      return () => (currentItem.value = 1);
    }
    case 1: {
      return () => (currentItem.value = 2);
    }
    default: {
      return () => (currentItem.value = 3);
    }
  }
};
