import React, { useCallback } from 'react';
import i18next from 'i18next';
import styled, { useTheme } from 'styled-components/native';
import { Text } from '@lendticket/ui/components/atoms';
import { isWeb } from '@lendticket/app/src/helpers/platform';
import { Icon } from '@lendticket/ui/components/molecules';
import { Linking, Platform } from 'react-native';
import { useI18n } from '@lendticket/app/src/hooks/i18n';

const BORDER_WRAPPER_HEIGHT = isWeb ? 5 : 50;
const STORE_LINKS = {
  ios: {
    en: 'itms-apps://apps.apple.com/se/app/lend-ticket/id6445863215?mt=8&action=write-review',
    se: 'itms-apps://apps.apple.com/se/app/lend-ticket/id6445863215?mt=8&action=write-review',
    da: 'itms-apps://apps.apple.com/dk/app/lend-ticket/id6445863215?mt=8&action=write-review',
  },
  android: 'https://play.google.com/store/apps/details?id=com.lendticket',
} as Record<string, any>;

const BorderWrapper = styled.View`
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  height: ${BORDER_WRAPPER_HEIGHT}px;
  width: ${({ theme }) =>
    theme.deviceSizes.isMobile || theme.deviceSizes.isTablet
      ? '100%'
      : `${theme.settings.CONSTANTS.DESKTOP_TABLET_WIDTH}px`}
  background-color: ${({ theme }) => theme.settings.COLORS.BACKGROUND_LIGHT};
`;

const TouchableOpacity = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
`;

const openLink = async (url: string) => {
  try {
    await Linking.openURL(url);
  } catch (e) {
    console.log('something went wrong');
  }
};

const LeaveAReview: React.FC = () => {
  const theme = useTheme();
  const i18n = useI18n();

  const onPress = useCallback(() => {
    const storeReviewLink = Platform.select({
      ios: STORE_LINKS['ios'][i18next.language],
      android: STORE_LINKS['android'],
      default: '',
    });

    openLink(storeReviewLink);
  }, []);

  return (
    <BorderWrapper>
      {!isWeb && (
        <TouchableOpacity onPress={onPress} fd='row' pg='0px 16px' ai='center' jc='center'>
          <Text
            fontType={isWeb ? 'h5' : 'body'}
            style={{ marginRight: 6, color: theme.settings.COLORS.TEXT_BLACK_SECONDARY }}
          >
            {i18n.t('leaveAReview')}
          </Text>
          <Icon
            name='openLink'
            height={18}
            width={18}
            fill={theme.settings.COLORS.TEXT_BLACK_SECONDARY}
          />
        </TouchableOpacity>
      )}
    </BorderWrapper>
  );
};

export default LeaveAReview;
