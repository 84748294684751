import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import { useTheme } from 'styled-components/native';
import { Wrapper, Text, Margin } from '@lendticket/ui/components/atoms';
import { Icon } from '@lendticket/ui/components/molecules';
import { useI18n } from '@lendticket/app/src/hooks/i18n';
import type { CardProps } from '.';

const PADDING_LEFT = 16;
const HORIZONTAL_PADDING = 2;
const HEIGHT = 20;
const ICON_HEIGHT_APPLE = 14;
const ICON_HEIGHT_DEFAULT = 12;

const AuthorizationBar: React.FC<
  Pick<CardProps, 'isLoggedInWithApple' | 'isLoggedInWithGoogle' | 'isLoggedInWithFacebook'>
> = ({ isLoggedInWithApple, isLoggedInWithGoogle, isLoggedInWithFacebook }) => {
  const i18n = useI18n();
  const { COLORS } = useTheme().settings;

  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      locations={[0, 1]}
      colors={
        isLoggedInWithGoogle
          ? ['#89A3AA', '#89A3AA']
          : isLoggedInWithFacebook
          ? ['#788CB8', '#8597BF']
          : ['#494949', '#000000']
      }
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: PADDING_LEFT,
        paddingTop: HORIZONTAL_PADDING,
        paddingBottom: HORIZONTAL_PADDING,
      }}
    >
      <Wrapper fd="row" h={HEIGHT}>
        <Icon
          name={
            isLoggedInWithGoogle ? 'google' : isLoggedInWithFacebook ? 'facebook' : 'apple_logo'
          }
          height={isLoggedInWithApple ? ICON_HEIGHT_APPLE : ICON_HEIGHT_DEFAULT}
          width={isLoggedInWithApple ? ICON_HEIGHT_APPLE : ICON_HEIGHT_DEFAULT}
          fill="white"
        />
        <Margin marginLeft="s" />
        <Text fontType="subtitle" style={{ textAlign: 'left', color: COLORS.WHITE }}>
          {i18n.t('authorizedWith', {
            socialMedia: isLoggedInWithGoogle
              ? 'Google'
              : isLoggedInWithFacebook
              ? 'Facebook'
              : 'Apple',
          })}
        </Text>
      </Wrapper>
    </LinearGradient>
  );
};

export default AuthorizationBar;
