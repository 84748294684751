import { createSlice, PayloadAction, createEntityAdapter } from '@reduxjs/toolkit';
import { RootState } from 'helpers/redux/store';

export interface SearchState {
  [key: string]: string;
}

export const initialState: SearchState = {};

export const searchAdapter = createEntityAdapter<SearchState>();

export const { reducer: searchReducer, actions: searchActions } = createSlice({
  name: 'search',
  initialState,
  reducers: {
    addSearchQueries: (state, action: PayloadAction<any>) => action.payload,
    startSearchNatively: (state, action: PayloadAction<any>) => ({
      ...state,
      startSearchNatively: action.payload,
    }),
    resetSearchQueries: () => initialState,
  },
});

export const searchSelector = (state: RootState) => state.root.search;
