import React, { useCallback, useContext } from 'react';
import { TapGestureHandler as RNGHTapGestureHandler, State } from 'react-native-gesture-handler';
import { GestureContext } from '@lendticket/ui/containers/GestureProvider';

interface TapGestureHandlerProps {
  onPress?: () => void;
  children: React.ReactNode;
}

const TapGestureHandler: React.FC<TapGestureHandlerProps> = ({ onPress, children }) => {
  const { tapRef, isScrolling, isPanning } = useContext(GestureContext);

  const onHandlerStateChange = useCallback(
    (e: any) => {
      if (isScrolling?.value) {
        isScrolling.value = false;
      }

      if (e.nativeEvent.state === State.END && !isPanning?.value && onPress) {
        onPress();
      }
    },
    [isScrolling, isPanning, onPress],
  );

  return (
    <RNGHTapGestureHandler ref={tapRef} onHandlerStateChange={onHandlerStateChange}>
      {children}
    </RNGHTapGestureHandler>
  );
};

export default TapGestureHandler;
