import React, { useState } from 'react';
import * as routeNames from 'constants/routeNames';
import { Modal } from '@lendticket/ui/components/organisms';
import { useNavigation } from '@react-navigation/native';

const Unathorized: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);
  const navigation = useNavigation();

  return (
    <Modal
      isVisible={isVisible}
      buttonTitle="Login eller opret en bruger bruger"
      text="For at kunne bruge denne feature skal du have en brugerprofil"
      action={() => navigation.reset({ index: 1, routes: [{ name: routeNames.LOGIN }] })}
      closeModal={() => setIsVisible(false)}
      onClose={() => {
        navigation.navigate(routeNames.DRAWER_AND_TABBAR, {
          screen: routeNames.TABS,
          params: { screen: routeNames.FIND_TICKETS_AND_PROFILES },
        });
        setIsVisible(false);
      }}
    />
  );
};

export default Unathorized;
