import React from 'react';
import { useTheme } from 'styled-components/native';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { Wrapper } from '@lendticket/ui/components/atoms';
import {
  FullWrapperHeight,
  Gradient,
  Features,
  Policies,
} from '@lendticket/ui/components/molecules';
import { KeyboardAvoidingView } from '@lendticket/ui/components/organisms';

interface ContentPageProps {
  hasMarginTop?: boolean;
  features: {
    svgName: string;
    title: string;
    text: string;
  }[];
  policies: {
    title: string;
    text: string;
  }[];
}

const ContentPage: React.FC<ContentPageProps> = ({ features, policies }) => {
  const { deviceSizes, settings } = useTheme();
  const { CONSTANTS } = settings;
  const { isMobile } = deviceSizes;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;

  return (
    <SafeAreaProvider>
      <SafeAreaView>
        <Gradient />
        <FullWrapperHeight isModal hasBottomTabBar={false}>
          <Wrapper f={1} w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}>
            <KeyboardAvoidingView hasMarginTop={false}>
              {features && <Features items={features} />}
              {policies && <Policies items={policies} />}
            </KeyboardAvoidingView>
          </Wrapper>
        </FullWrapperHeight>
      </SafeAreaView>
    </SafeAreaProvider>
  );
};

export default ContentPage;
