import { Platform } from 'react-native';
import type { DefaultTheme } from 'styled-components/native';
import type { TextProps } from '@lendticket/ui/components/atoms/Text';

const isWeb = Platform.OS === 'web';

interface GetTextStyleParams {
  style?: TextProps['style'];
  fontType: TextProps['fontType'];
  theme: DefaultTheme;
}

export const getTextStyle = ({ fontType, theme, style = {} }: GetTextStyleParams) => {
  const { settings, deviceSizes } = theme;
  const { FONTS } = settings;

  if (isWeb) {
    const { fontSize, fontSizeWeb, fontWeight, fontStretch, lineHeightWeb } = deviceSizes.isMobile
      ? FONTS.SIZES.MOBILE[fontType]
      : FONTS.SIZES.DESKTOP[fontType];

    return {
      fontSize: fontSize ?? fontSizeWeb,
      lineHeight: lineHeightWeb,
      fontFamily: 'Hubot-Sans',
      userSelect: 'none',
      whiteSpace: 'pre-line',
      fontWeight,
      fontStretch,
      ...style,
    };
  }

  const { fontSize, fontSizeNative, lineHeightNative, fontFamily } = deviceSizes.isMobile
    ? FONTS.SIZES.MOBILE[fontType]
    : FONTS.SIZES.DESKTOP[fontType];

  return {
    fontSize: fontSizeNative ?? fontSize,
    lineHeight: lineHeightNative,
    fontFamily: fontFamily ?? 'Hubot-Sans',
    ...(style as Record<string, any>),
  };
};
