import React from 'react';
import { DefaultPage } from '@lendticket/ui/components/templates';
import { useI18n } from 'hooks/i18n';

const Loan: React.FC = () => {
  const i18n = useI18n();

  return (
    <DefaultPage
      teaserTitle={i18n.t('onboardingLoanTitle')}
      teaserText={i18n.t('onboardingLoanText')}
      svgName="loan"
    />
  );
};

export default Loan;
