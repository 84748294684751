import {
  Action,
  configureStore,
  ThunkAction,
  isPending,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import rootReducer from 'reducers';
import * as routeNames from 'constants/routeNames';
import api from 'reducers/slices/api';
import { userActions } from 'reducers/slices/user';
import persisted from 'helpers/storage/persisted';
import { navigationRef } from 'helpers/navigation/config';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { loadingActions } from 'reducers/slices/loading';
import { showMessage } from 'react-native-flash-message';
// import reduxFlipperMiddleware from 'redux-flipper';
import { i18n } from 'helpers/i18n';

const REQUESTS_WITH_LOADING_BACKGROUND = [
  'createLendProfile',
  'createLendTicket',
  'deleteUserResetPasswordUrl',
  'deleteUserByUserId',
  'updateUserPasswordByUserIdAndLinkId',
  'sendMailResetPassword',
];

/* NOTE: All tabbars */
const REQUEST_WITH_LOADING_INDICATOR_LINE = [
  'getLendTickets',
  'getChatsByUserId',
  'deleteChatByProfileId',
];

export const rtkQueryErrorLoggerAndSideEffects: Middleware =
  (middlewareApi: MiddlewareAPI) => next => async action => {
    const dispatch = middlewareApi.dispatch;
    const endpointName = action.meta?.arg?.endpointName;
    const isLoading = middlewareApi.getState().root.loading.isLoading;
    const hasLoadingRequest =
      REQUESTS_WITH_LOADING_BACKGROUND.includes(endpointName) ||
      REQUEST_WITH_LOADING_INDICATOR_LINE.includes(endpointName);

    if (isPending(action) && hasLoadingRequest && !isLoading) {
      dispatch(
        loadingActions.setLoadingContent({
          isLoading: true,
          hasBackgroundColor: REQUESTS_WITH_LOADING_BACKGROUND.includes(endpointName),
          indicator: REQUEST_WITH_LOADING_INDICATOR_LINE.includes(endpointName) ? 'line' : 'circle',
        }),
      );
    } else if (!isPending(action) && hasLoadingRequest && isLoading) {
      dispatch(
        loadingActions.setLoadingContent({
          isLoading: false,
          hasBackgroundColor: false,
          text: undefined,
        }),
      );
    }

    if (isRejectedWithValue(action)) {
      if (action.payload.originalStatus === 401) {
        /* NOTE: If API's fail then logout users gracefully */
        showMessage({
          message: (i18n as any).t('unauthorized'),
          description: (i18n as any).t('unauthorizedDescription'),
          style: {
            borderBottomLeftRadius: 7,
            borderBottomRightRadius: 7,
            flex: 1,
          },
          type: 'danger',
          icon: 'danger',
          position: 'top',
          duration: 7000,
        });

        await persisted.clear();
        middlewareApi.dispatch(userActions.resetUserInfo());
        navigationRef?.current?.reset({ index: 1, routes: [{ name: routeNames.LOGIN }] });
      } else {
        return next(action);
      }
    }

    return next(action);
  };

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    root: rootReducer,
  },
  middleware: getDefaultMiddleware => {
    let middlewareList = [api.middleware, rtkQueryErrorLoggerAndSideEffects];

    if (__DEV__) {
      // middlewareList = middlewareList.concat(reduxFlipperMiddleware());
    }

    return getDefaultMiddleware().concat(middlewareList);
  },
});

export type AppDispatch = typeof store.dispatch;
export interface RootState {
  root: ReturnType<typeof rootReducer>;
}
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
