import { createSlice, PayloadAction, createEntityAdapter } from '@reduxjs/toolkit';
import { RootState } from '../../helpers/redux/store';

export interface UserState {
  isChatDetailsVisible: boolean;
}

export const initialState: UserState = {
  isChatDetailsVisible: true,
};

export const chatsAdapter = createEntityAdapter<UserState>();

export const { reducer: chatsReducer, actions: chatsActions } = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    setChatDetailsVisibility: (state, action: PayloadAction<any>) => ({
      isChatDetailsVisible: action.payload,
    }),
    resetChatDetailsVisibility: () => initialState,
  },
});

export const chatsSelector = (state: RootState) => state.root.chats;
