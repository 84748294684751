import { createSlice, PayloadAction, createEntityAdapter } from '@reduxjs/toolkit';
import { RootState } from '../../helpers/redux/store';

export interface TicketsState {
  tickets?: object;
  ticket?: object;
  ticketIds: string[];
  isTicketOwner: boolean;
}

export const initialState: TicketsState = {
  tickets: undefined,
  isTicketOwner: false,
  ticketIds: [],
};

export const ticketsAdapter = createEntityAdapter<TicketsState>();

export const { reducer: ticketsReducer, actions: ticketActions } = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    addTickets: (_, action: PayloadAction<any>) => action.payload,
    setSelectedTicket: (state, action: PayloadAction<any>) => ({
      ...state,
      ticket: action.payload,
    }),
    setIsTicketOwner: (state, action: PayloadAction<any>) => ({
      ...state,
      isTicketOwner: action.payload,
    }),
    removeTickets: (state: any) => ({
      ...state,
      tickets: undefined,
      ticketIds: [],
    }),
  },
});

export const ticketsSelector = (state: RootState) => state.root.tickets;
