import React from 'react';
import styled, { useTheme } from 'styled-components/native';
import { Text, Wrapper } from '@lendticket/ui/components/atoms';
import { getTextStyle } from '@lendticket/ui/helpers';

const StyledBody = styled(Text)`
  fontsize: 14px;
  text-decoration-line: underline;
`;

interface LinkProps {
  onPress: () => void;
  children: React.ReactNode;
}

const Link: React.FC<LinkProps> = ({ onPress, children }) => {
  const theme = useTheme();

  return (
    <Wrapper>
      {typeof children === 'string' ? (
        <Text fontType="body">{children}</Text>
      ) : (
        React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              style: {
                ...getTextStyle({ fontType: 'body', theme }),
                textDecorationLine: 'underline',
              },
              onPress,
              color: 'blue',
            });
          }
          return child;
        })
      )}
      {/* <LinkIconWrapper testID={testID} onPress={onPress}>
        <Icon name="link" width={17} height={17} fill={updatedColor} />
      </LinkIconWrapper> */}
    </Wrapper>
  );
};

export default Link;
