import React, { useEffect, useCallback } from 'react';
import { Platform } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { Icon } from '@lendticket/ui/components/molecules';
import { Wrapper, Text, Margin } from '@lendticket/ui/components/atoms';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import BouncyCheckbox from 'react-native-bouncy-checkbox';

const isWeb = Platform.OS === 'web';
const ICON_DIMENSIONS = 14;
const BORDER_RADIUS = 999;
const BORDER_WIDTH = 1;
const WRAPPER_HEIGHT = 35;
const CHECKBOX_DIMENSIONS = 25;
const PADDING_TOP = 3;
const BORDER_COLOR = 'rgba(0,0,0,0.35)';

const TouchableOpacity = styled.TouchableOpacity``;

interface ICheckboxComponent {
  [key: string]: string;
}

interface CheckmarkIconParams {
  uiSelectionState: Animated.SharedValue<Record<string, any>>;
  jsSelectionState: Record<string, any>;
  id: string;
  color: string;
}

interface SelectableCheckboxesProps {
  onSearchChange: (value: string | boolean, name: string) => void;
  setJsSelectionState: (props: any) => void;
  selectableItems: ICheckboxComponent[];
  uiSelectionState: Animated.SharedValue<Record<string, any>>;
  jsSelectionState: Record<string, any>;
}

const generateToggleState = (array: ICheckboxComponent[], key: string, value: boolean) => {
  if (!array) {
    return [];
  }

  const initialValue = {};

  return array.reduce((obj, item) => {
    return item[key]
      ? {
          ...obj,
          [item[key].replace(/\s/, '')]: value,
        }
      : obj;
  }, initialValue);
};

const CheckmarkIcon: React.FC<CheckmarkIconParams> = ({ uiSelectionState, id, color }) => {
  const { settings } = useTheme();
  const { COLORS } = settings;

  const animatedStyle = useAnimatedStyle(() => ({
    display: uiSelectionState.value[id] ? 'flex' : 'none',
  }));

  const animatedStyleWrapper = useAnimatedStyle(() => ({
    width: CHECKBOX_DIMENSIONS,
    height: CHECKBOX_DIMENSIONS,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: BORDER_RADIUS,
    borderWidth: BORDER_WIDTH,
    borderColor: uiSelectionState.value[id] ? COLORS.BACKGROUND_PRIMARY : BORDER_COLOR,
    backgroundColor: uiSelectionState.value[id] ? COLORS.BACKGROUND_PRIMARY : 'transparent',
  }));

  return (
    <Animated.View style={animatedStyleWrapper}>
      <Animated.View style={animatedStyle}>
        <Icon name="check" height={ICON_DIMENSIONS} width={ICON_DIMENSIONS} fill={color} />
      </Animated.View>
    </Animated.View>
  );
};

const SelectableCheckboxes: React.FC<SelectableCheckboxesProps> = ({
  onSearchChange,
  setJsSelectionState,
  selectableItems,
  uiSelectionState,
  jsSelectionState,
}) => {
  const theme = useTheme();
  const { deviceSizes, settings } = theme;
  const { COLORS } = settings;
  const { isMobile } = deviceSizes;

  const handleToggleState = useCallback(
    (item: ICheckboxComponent) => {
      if (isWeb) {
        setJsSelectionState({
          ...jsSelectionState,
          [item.id]: !jsSelectionState[item.id],
        });

        onSearchChange(!jsSelectionState[item.id], item.id);
      } else {
        /* NOTE: Hack towards down prioritize Javascript execution logic through the event loop */
        uiSelectionState.value = {
          ...uiSelectionState.value,
          [item.id]: !uiSelectionState.value[item.id],
        };

        onSearchChange(!uiSelectionState.value[item.id], item.id);
      }
    },
    [jsSelectionState, onSearchChange, setJsSelectionState, uiSelectionState],
  );

  useEffect(() => {
    uiSelectionState.value = generateToggleState(selectableItems, 'id', false);
    setJsSelectionState(generateToggleState(selectableItems, 'id', false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {selectableItems.map(({ id, title }, i) => (
        <TouchableOpacity
          key={`${id}-${i}`}
          activeOpacity={1}
          onPress={() => handleToggleState({ id, title })}
          style={{
            width: '100%',
            alignItems: 'center',
            height: WRAPPER_HEIGHT,
            flexDirection: 'row',
          }}
        >
          {isWeb ? (
            <BouncyCheckbox
              disableBuiltInState
              fillColor={COLORS.BACKGROUND_LIGHT_ORANGE}
              useNativeDriver={false}
              innerIconStyle={{ borderWidth: BORDER_WIDTH }}
              isChecked={jsSelectionState[id]}
              key={id}
              onPress={() => handleToggleState({ id, title })}
              style={{ paddingBottom: 10 }}
              text={title}
              iconComponent={
                jsSelectionState[id] ? (
                  <Icon
                    name="check"
                    height={ICON_DIMENSIONS}
                    width={ICON_DIMENSIONS}
                    fill={COLORS.WHITE}
                  />
                ) : null
              }
              textStyle={{
                fontFamily: 'Hubot-Sans',
                fontSize: 16,
                overflow: 'hidden',
                color: COLORS.TEXT_LIGHT_PURPLE,
                fontWeight: 'normal',
                textAlign: 'left',
                width: '100%',
                textDecorationLine: 'none',
              }}
            />
          ) : (
            <CheckmarkIcon id={id} uiSelectionState={uiSelectionState} color={COLORS.WHITE} />
          )}
          <Margin marginLeft="m" />
          {!isWeb && (
            <Text
              fontType={isMobile ? 'body' : 'h5'}
              style={{ paddingTop: PADDING_TOP, textAlign: 'left', color: BORDER_COLOR }}
            >
              {title}
            </Text>
          )}
        </TouchableOpacity>
      ))}
    </>
  );
};

export default SelectableCheckboxes;
