import React, { useMemo, useContext, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Animated, {
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import {
  ParamListBase,
  NavigationHelpers,
  TabNavigationState,
  useNavigation,
  useRoute,
  useIsFocused,
} from '@react-navigation/native';
import styled, { useTheme } from 'styled-components/native';
import { Wrapper, Margin, Text } from '@lendticket/ui/components/atoms';
import { Icon, NumberOfNewMessages } from '@lendticket/ui/components/molecules';
import { useKeyboardDimensions } from '@lendticket/ui/hooks';
import Animation from './Animation';
import { NotificationContext } from '@lendticket/app/src/contexts';
import * as routeNames from '@lendticket/app/src/constants/routeNames';
import { useI18n } from '@lendticket/app/src/hooks/i18n';
import type { BottomTabNavigationEventMap } from '@react-navigation/bottom-tabs';

const isWeb = Platform.OS === 'web';
const isIOS = Platform.OS === 'ios';

export const HEIGHT = 60;
export const BORDER_WRAPPER_HEIGHT = 5;
const TABBAR_HEIGHT = 100;
const EXTRA_ICON_DIMENSIONS_FOR_MESSAGES = 6;
const ICON_DIMENSIONS_DEFAULT = 30;
const ICON_DIMENSIONS_MOBILE = 25;
const HIT_SLOP = {
  left: 15,
  right: 15,
  top: 5,
  bottom: 5,
};

interface BottomTabBarProps {
  navigation?: NavigationHelpers<ParamListBase, BottomTabNavigationEventMap>;
  state?: TabNavigationState<ParamListBase>;
}

const getTabBarItems = (i18n: any, removeNotifications: any) => [
  {
    routeName: routeNames.CREATE,
    svgName: 'lendTicketLogo',
    text: i18n.t('tabBarTitleCreate'),
    onPress: (navigation: Record<string, any>) => navigation.navigate(routeNames.CREATE),
  },
  {
    routeName: routeNames.FIND_TICKETS_AND_PROFILES,
    svgName: 'ticket',
    text: i18n.t('tabBarTitleFind'),
    onPress: (navigation: Record<string, any>) =>
      navigation.navigate(routeNames.FIND_TICKETS_AND_PROFILES),
  },
  {
    routeName: routeNames.MESSAGES,
    svgName: 'chat',
    text: i18n.t('tabBarTitleMessages'),
    onPress: (navigation: Record<string, any>) => {
      removeNotifications();
      navigation.navigate(routeNames.MESSAGES);
    },
  },
  {
    routeName: routeNames.FAVORITES,
    svgName: 'thumbsUp',
    text: i18n.t('tabBarTitleFavorites'),
    onPress: (navigation: Record<string, any>) => {
      navigation.navigate(routeNames.FAVORITES);
    },
  },
  {
    routeName: routeNames.HOME,
    svgName: 'house',
    text: i18n.t('tabBarTitleHome'),
    onPress: (navigation: Record<string, any>) => navigation.navigate(routeNames.HOME),
  },
];

const TouchableOpacity = styled.TouchableOpacity`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BottomNavigationBar: React.FC<BottomTabBarProps> = ({ state: routeState }) => {
  const i18n = useI18n();
  const { numberOfNotifications, removeNotifications } = useContext(NotificationContext);
  const tabBarItems = useMemo(
    () => getTabBarItems(i18n, removeNotifications),
    [i18n, removeNotifications],
  );
  const [currentSelectedTabbarIndex, setCurrentSelectedTabbarIndex] = useState(-1);
  const isVisible = useSharedValue(true);
  const { bottom } = useSafeAreaInsets();
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const route = useRoute();
  const { settings, deviceSizes, windowDimensions } = useTheme();
  const { windowWidth } = windowDimensions;
  const keyboardValues = useKeyboardDimensions();
  const { isMobile } = deviceSizes;
  const { COLORS, CONSTANTS } = settings;

  const animatedStyle = useAnimatedStyle(() => ({
    display: isVisible.value ? 'flex' : 'none',
  }));

  useAnimatedReaction(
    () => keyboardValues,
    res => {
      if (res.value.isVisible && !isIOS) {
        isVisible.value = false;
      } else if (!isVisible.value) {
        isVisible.value = true;
      }
    },
    [keyboardValues],
  );

  useEffect(() => {
    if (isFocused) {
      const { routes, index } = routeState ?? {};
      const currentSelectedRouteName = isWeb ? route.name : routes?.[index]?.name;

      const updateCurrentSelectedIndex = tabBarItems.findIndex(
        ({ routeName }) => routeName === currentSelectedRouteName,
      );

      if (currentSelectedTabbarIndex !== updateCurrentSelectedIndex) {
        setCurrentSelectedTabbarIndex(updateCurrentSelectedIndex);
      }
    }
  }, [currentSelectedTabbarIndex, isFocused, route, routeState, tabBarItems]);

  return (
    <Animated.View style={animatedStyle}>
      <Wrapper
        h={TABBAR_HEIGHT}
        style={
          isWeb
            ? { width: isMobile ? windowWidth : CONSTANTS.DESKTOP_TABLET_WIDTH }
            : { position: 'absolute', bottom: bottom ? bottom / 3 : 0 }
        }
      >
        <Wrapper w={isMobile ? undefined : CONSTANTS.DESKTOP_TABLET_WIDTH}>
          <Wrapper fd='row' style={{ height: TABBAR_HEIGHT }}>
            {tabBarItems.map(({ routeName, svgName, text, onPress }, i) => {
              const isSelected = i === currentSelectedTabbarIndex;
              const isRouteMessages = routeName === routeNames.MESSAGES;

              return (
                <Wrapper key={`${text}-${i}`} f={1}>
                  <TouchableOpacity
                    onPress={() => {
                      setCurrentSelectedTabbarIndex(i);
                      onPress(navigation);
                    }}
                    activeOpacity={1}
                    hitSlop={HIT_SLOP}
                  >
                    {isRouteMessages && numberOfNotifications > 0 && (
                      <NumberOfNewMessages numberOfNotifications={numberOfNotifications} />
                    )}
                    <Icon
                      name={svgName}
                      fill={isSelected ? COLORS.WHITE : COLORS.NOT_SELECTED}
                      height={
                        isMobile
                          ? ICON_DIMENSIONS_MOBILE +
                            (isRouteMessages ? EXTRA_ICON_DIMENSIONS_FOR_MESSAGES : 0)
                          : ICON_DIMENSIONS_DEFAULT
                      }
                      width={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS_DEFAULT}
                      style={{ zIndex: -1 }}
                    />
                    <Animation currentSelectedTabbarIndex={currentSelectedTabbarIndex} index={i}>
                      <Margin vertical='xs'>
                        <Text fontType='body' style={{ color: COLORS.WHITE }}>
                          {text}
                        </Text>
                      </Margin>
                    </Animation>
                  </TouchableOpacity>
                </Wrapper>
              );
            })}
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Animated.View>
  );
};

export default BottomNavigationBar;
