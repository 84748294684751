import React, { useMemo, useCallback } from 'react';
import { Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Controller, FieldValues, Control } from 'react-hook-form';
import { Wrapper, Margin } from '@lendticket/ui/components/atoms';
import { LabelAndDescription, InputField } from '@lendticket/ui/components/molecules';
import { useI18n } from '@lendticket/app/src/hooks/i18n';

const isIOS = Platform.OS === 'ios';
const BORDER_WIDTH = 3;
const BORDER_RADIUS = 7;
const INPUT_FIELD_HEIGHT = 50;
const PADDING_LEFT = 16;

const getFormData = (i18n: any) => [
  {
    placeholder: i18n.t('placeholderProviderExample'),
    name: 'provider',
    name_ticket: 'provider_ticket',
    name_profile: 'provider_profile',
    keyboardType: 'default',
  },
  {
    placeholder: i18n.t('placeholderLendTimeExample'),
    name: 'lendTime',
    name_ticket: 'lendTime_ticket',
    name_profile: 'lendTime_profile',
    keyboardType: isIOS ? 'numbers-and-punctuation' : 'default',
  },
  {
    placeholder: i18n.t('placeholderPriceExample'),
    name: 'price',
    name_ticket: 'price_ticket',
    name_profile: 'price_profile',
    keyboardType: isIOS ? 'numbers-and-punctuation' : 'default',
  },
  {
    placeholder: i18n.t('placeholderLendFromTimeExample'),
    name: 'lendFromTime',
    name_ticket: 'lendFromTime_ticket',
    name_profile: 'lendFromTime_profile',
    keyboardType: isIOS ? 'numbers-and-punctuation' : 'default',
  },
];

interface DestinationsProps {
  hasProvider?: boolean;
  isLendingOutSelected: boolean;
  control: Control<FieldValues, any>;
}

const Practical: React.FC<DestinationsProps> = ({ control, hasProvider, isLendingOutSelected }) => {
  const i18n = useI18n();
  const theme = useTheme();
  const { settings } = theme;
  const { COLORS } = settings;
  const formData = useMemo(() => getFormData(i18n), [i18n]);
  const filterHelper = useCallback(
    (item: any) => (hasProvider ? item : item.name !== 'provider'),
    [hasProvider],
  );

  return (
    <Wrapper ai="flex-start" jc="flex-start">
      <LabelAndDescription
        title={i18n.t('createPracticalInformation')}
        description={i18n.t('createPracticalDescription')}
      />
      <Margin marginTop="md" />
      {formData
        .filter(filterHelper)
        .map(({ placeholder, keyboardType, name_ticket, name_profile }, i) => (
          <Controller
            key={`${placeholder}-${i}`}
            control={control}
            name={isLendingOutSelected ? name_ticket : name_profile}
            render={({ field: { onChange, value, onBlur } }) => {
              const isFirst = i === 0;
              const isLast = i === formData.filter(filterHelper).length - 1;

              return (
                <Wrapper
                  h={INPUT_FIELD_HEIGHT}
                  jc="center"
                  ai="flex-start"
                  style={{
                    borderBottomWidth: isFirst || !isLast ? 0 : BORDER_WIDTH,
                    borderRightWidth: BORDER_WIDTH,
                    borderLeftWidth: BORDER_WIDTH,
                    borderTopWidth: BORDER_WIDTH,
                    borderColor: COLORS.BACKGROUND_LIGHT_ORANGE,
                    borderTopRightRadius: isFirst ? BORDER_RADIUS : 0,
                    borderTopLeftRadius: isFirst ? BORDER_RADIUS : 0,
                    borderBottomRightRadius: isFirst || !isLast ? 0 : BORDER_RADIUS,
                    borderBottomLeftRadius: isFirst || !isLast ? 0 : BORDER_RADIUS,
                  }}
                >
                  <InputField
                    hasFullWidth
                    keyboardType={keyboardType}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    style={{ paddingLeft: PADDING_LEFT }}
                  />
                </Wrapper>
              );
            }}
          />
        ))}
    </Wrapper>
  );
};

export default Practical;
