const commonDanish = require('./da/common.json');
const commonEnglish = require('./gb/common.json');
const commonSwedish = require('./se/common.json');

const resources = {
  da: { common: commonDanish },
  en: { common: commonEnglish },
  se: { common: commonSwedish },
};

export default resources;
