import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type { TagTypes } from '.';

interface GetSunsetInformationProps {
  sunsetInformation: {
    buildNumber: string | number;
    lockdownVersion: string | number;
    numberOfDaysForNewPrompt: number;
    safeTrigger?: boolean;
  };
}

export const sunset = (
  build: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
    TagTypes,
    'api'
  >,
) => ({
  getSunsetInformation: build.query<GetSunsetInformationProps, 'android' | 'ios'>({
    query: platform => `/sunset/${platform}`,
  }),
});
