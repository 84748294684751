import { runOnJS, withSpring } from 'react-native-reanimated';
import { DEFAULT_SPRING_CONFIG } from '@lendticket/ui/constants';
import type { GestureParams } from '../';

export const getPanOnEnd =
  ({
    prevDragX,
    dragX,
    translationX,
    panningDirection,
    textWidth,
    isLoading,
    onSwipeCallback,
  }: GestureParams) =>
  () => {
    'worklet';

    panningDirection.value = prevDragX.value > dragX.value ? 'left' : 'right';
    translationX.value = withSpring(0, DEFAULT_SPRING_CONFIG, isAnimationDone => {
      if (onSwipeCallback && isAnimationDone && dragX.value <= -textWidth.value) {
        runOnJS(onSwipeCallback)();
      }

      isLoading.value = false;
    });
  };
