import React from 'react';
import { DefaultPage } from '@lendticket/ui/components/templates';
import { useI18n } from 'hooks/i18n';

const Landing: React.FC = () => {
  const i18n = useI18n();

  return (
    <DefaultPage
      teaserTitle={i18n.t('onboardingLandingTitle')}
      teaserText={i18n.t('onboardingLandingText')}
      svgName="lendticket"
    />
  );
};

export default Landing;
