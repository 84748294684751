import React from 'react';
import { Wrapper, Text, Margin } from '@lendticket/ui/components/atoms';
import { Icon } from '@lendticket/ui/components/molecules';
import { useTheme } from 'styled-components/native';
import { SPACINGS } from '@lendticket/ui/theme';

const ICON_DIMENSIONS = 35;
const ICON_DIMENSIONS_MOBILE = 30;

interface FeaturesProps {
  items: {
    svgName: string;
    title: string;
    text: string;
  }[];
}

const Features: React.FC<FeaturesProps> = ({ items }) => {
  const { settings, deviceSizes } = useTheme();
  const { isMobile } = deviceSizes;
  const { COLORS, CONSTANTS } = settings;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;

  return (
    <>
      {items.map(({ title, svgName, text }, i) => (
        <Wrapper
          mt={i === 0 ? 0 : SPACINGS.md}
          w={isMobile ? undefined : DESKTOP_TABLET_WIDTH - 2 * SPACINGS.xl}
          key={`${title}-${i}`}
        >
          <Wrapper fd="row">
            <Icon
              name={svgName}
              fill={COLORS.WHITE}
              height={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS}
              width={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS}
            />
            <Margin marginLeft="md">
              <Text fontType={isMobile ? 'h4' : 'h3'} style={{ color: COLORS.WHITE }}>
                {title}
              </Text>
            </Margin>
          </Wrapper>
          <Wrapper fd="row">
            <Wrapper w={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS} />
            <Wrapper f={1} ai="flex-start">
              <Margin marginLeft="md">
                <Text fontType="body" style={{ color: COLORS.WHITE, textAlign: 'left' }}>
                  {text}
                </Text>
              </Margin>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      ))}
    </>
  );
};

export default Features;
