import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type { TagTypes } from '.';

export const lendprofiles = (
  build: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
    TagTypes,
    'api'
  >,
) => ({
  getLendProfiles: build.query<any, void>({
    query: () => '/lendprofiles',
  }),
  getLendProfilesByUserId: build.query<any, string>({
    query: userId => `/lendprofiles/profile/user/${userId}`,
  }),
  getLendProfileByProfileId: build.query<any, void>({
    query: profileId => `/lendprofiles/profile/id/${profileId}`,
  }),
  createLendProfile: build.mutation<any, any>({
    query: (data: any) => ({
      url: '/lendprofiles/create',
      method: 'POST',
      body: data,
    }),
  }),
  deleteLendProfile: build.mutation<any, any>({
    query: (id: string) => ({
      url: `/lendprofiles/profile/${id}`,
      method: 'DELETE',
    }),
  }),
});
