import React from 'react';
import Animated from 'react-native-reanimated';

interface GetAnimatedMeasureProps {
  ref: React.RefObject<Animated.View> | any;
  callback: ({ ...args }) => any;
}

export const getAnimatedMeasures = ({ ref, callback }: GetAnimatedMeasureProps): void => {
  ref.current.measure(
    (left: number, top: number, width: number, height: number, pageX: number, pageY: number) => {
      callback({ left, top, width, height, pageX, pageY });
    },
  );
};
