import React, { useState, useMemo, useCallback, useEffect } from 'react';
import * as routeNames from 'constants/routeNames';
import { useTheme } from 'styled-components/native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useForm, Controller } from 'react-hook-form';
import { ResetPasswordPage } from '@lendticket/ui/components/templates';
import { InputField, InputFieldWithIcon } from '@lendticket/ui/components/molecules';
import { BorderButton, Margin } from '@lendticket/ui/components/atoms';
import { usePasswordValidation } from 'areas/Login/hooks/usePasswordValidation';
import api from 'reducers/slices/api';
import { useAppDispatch } from 'helpers/redux/store';
import { getParameterByName } from 'helpers/getParameterByName';
import { alertMessage } from 'helpers/alertMessage';
import { showMessage } from 'react-native-flash-message';
import { useI18n } from 'hooks/i18n';

const getFormData = (i18n: any) => [
  {
    placeholder: i18n.t('placeholderPassword'),
    svgName: 'password',
    name: 'password',
  },
  {
    placeholder: i18n.t('placeholderConfirmPassword'),
    svgName: 'repeatPassword',
    name: 'repeatPassword',
  },
];

const ResetPassword: React.FC = () => {
  const i18n = useI18n();
  const [isLinkCheckPassed, setIsLinkCheckPassed] = useState<undefined | boolean>();
  const { deviceSizes, settings, windowDimensions } = useTheme();
  const { SPACINGS, CONSTANTS } = settings;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const { windowWidth } = windowDimensions;
  const { isMobile } = deviceSizes;
  const { getUserResetPasswordUrl, updateUserPasswordByUserIdAndLinkId } = api.endpoints;
  const { userId, linkId }: any = useRoute()?.params ?? {};
  const formData = useMemo(() => getFormData(i18n), [i18n]);
  const navigation = useNavigation();
  const dispatch = useAppDispatch();

  const passwordValidation = usePasswordValidation();
  const { control, handleSubmit } = useForm({ mode: 'onBlur' });

  const onSubmit = useCallback(
    async (data: any) => {
      if (!isLinkCheckPassed) {
        alertMessage(i18n.t('alertHmmSomethingWentWrongWithYourLink'));
      } else {
        const isPasswordValid = passwordValidation(data);

        if (isPasswordValid) {
          /* NOTE: Backend validation as well */
          const { data: dataResult }: any = await dispatch(
            updateUserPasswordByUserIdAndLinkId.initiate({ userId, linkId, ...data }),
          );

          if (dataResult?.status === 200) {
            navigation.navigate(routeNames.LOGIN);
            showMessage({
              message: 'Succes',
              description: i18n.t('yourPasswordIsUpdated'),
              icon: 'success',
              type: 'success',
              style: { backgroundColor: '#235347' },
            });
          } else {
            alertMessage(i18n.t('alertHmmSomethingWentWrong'));
          }
        }
      }
    },
    [
      i18n,
      isLinkCheckPassed,
      updateUserPasswordByUserIdAndLinkId,
      userId,
      linkId,
      navigation,
      passwordValidation,
      dispatch,
    ],
  );

  const initiate = useCallback(async () => {
    if (userId) {
      /* NOTE: Check if user has reset password url */
      const { data } = await dispatch(getUserResetPasswordUrl.initiate(userId));

      if (data?.resetPasswordParams) {
        const derivedLinkIdFromDatabase = getParameterByName({
          url: data.resetPasswordParams,
          search: 'linkId',
        });

        const derivedLinkIdFromUrl = getParameterByName({ search: 'linkId' });

        if (derivedLinkIdFromDatabase?.toLowerCase() === derivedLinkIdFromUrl?.toLowerCase()) {
          /* NOTE: Security check frontwise is fine... You can update the user password */
          setIsLinkCheckPassed(true);
        } else {
          alertMessage(
            'Hmmm... Noget er galt med dit link eller måden du har initieret denne side på',
          );

          setIsLinkCheckPassed(false);
        }
      } else {
        alertMessage(
          'Hmmm... Noget er galt med dit link eller måden du har initieret denne side på',
        );

        setIsLinkCheckPassed(false);
      }
    }
  }, [getUserResetPasswordUrl, userId, dispatch]);

  const width = isMobile
    ? windowWidth - SPACINGS.md * 2
    : DESKTOP_TABLET_WIDTH - SPACINGS.xxxxl * 2;

  useEffect(() => {
    initiate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ResetPasswordPage
      teaserTitle={i18n.t('createNewPasswordTitle')}
      teaserText={i18n.t('createNewPasswordText')}
      footerComponent={
        <Margin marginBottom="xl">
          <BorderButton
            style={{ width }}
            title={i18n.t('updateUser')}
            onPress={handleSubmit(onSubmit)}
          />
        </Margin>
      }
      inputFieldsComponent={
        <>
          <Margin marginTop="md" />
          {formData.map(({ placeholder, svgName, name }, i) => (
            <InputFieldWithIcon key={`${placeholder}-${i}`} svgName={svgName}>
              <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value, onBlur } }) => {
                  let keyboardType: any = 'default';

                  return (
                    <InputField
                      hasFullWidth
                      hasBorder
                      isPassword
                      hasPaddingLeft={false}
                      multiline={false}
                      keyboardType={keyboardType}
                      placeholder={placeholder}
                      onKeyDown={handleSubmit(onSubmit)}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  );
                }}
              />
            </InputFieldWithIcon>
          ))}
        </>
      }
    />
  );
};

export default ResetPassword;
