class SwiperButtonHandler {
  isLendingOutTicket: boolean;
  isFindingTicket: boolean;

  constructor() {
    this.isLendingOutTicket = true;
    this.isFindingTicket = true;
  }

  getIsFindingTicketSelected() {
    return this.isFindingTicket;
  }

  getIsLendingOutTicketSelected() {
    return this.isLendingOutTicket;
  }

  updateIsFindingTicket() {
    this.isFindingTicket = !this.isFindingTicket;
  }

  updateIsLendingOutTicket() {
    this.isLendingOutTicket = !this.isLendingOutTicket;
  }
}

const swiperButtonHandler = new SwiperButtonHandler();
export { swiperButtonHandler };
