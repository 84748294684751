import { createContext } from 'react';

export const LoadingContext = createContext<Record<string, any>>({});
export const { Provider: LoadingProviderWrapper } = LoadingContext;

export const UIProviderContext = createContext<Record<string, any>>({});
export const { Provider: UIProviderWrapper } = UIProviderContext;

export const I18nContext = createContext<Record<string, any>>({});
export const { Provider: I18nProviderWrapper } = I18nContext;

export const NotificationContext = createContext<Record<string, any>>({});
export const { Provider: NotificationProviderWrapper } = NotificationContext;

export const SocketContext = createContext<Record<string, any>>({});
export const { Provider: SocketProviderWrapper } = SocketContext;

export const ModalContext = createContext<Record<string, any>>({});
export const { Provider: ModalProviderWrapper } = ModalContext;

export const SearchContext = createContext<Record<string, any>>({});
export const { Provider: SearchProviderWrapper } = SearchContext;

export const TranslationContext = createContext<Record<string, any>>({});
export const { Provider: TranslationProviderWrapper } = TranslationContext;

export const NetInfoContext = createContext<Record<string, any>>({});
export const { Provider: NetInfoProviderWrapper } = NetInfoContext;

export const SunsetContext = createContext<Record<string, any>>({});
export const { Provider: SunsetProviderWrapper } = SunsetContext;
