import React from 'react';
import { useNavigation } from '@react-navigation/native';
import * as routeNames from 'constants/routeNames';
import { ForgotPasswordPage } from '@lendticket/ui/components/templates';
import { useI18n } from 'hooks/i18n';

const MailSent: React.FC = () => {
  const i18n = useI18n();
  const navigation = useNavigation();

  return (
    <ForgotPasswordPage
      teaserTitle={i18n.t('forgotPasswordTitle')}
      teaserText={i18n.t('forgotPasswordText')}
      footerText={i18n.t('end')}
      footerDescription={i18n.t('forgotPasswordDescription')}
      footerOnPress={() => navigation.navigate(routeNames.LOGIN)}
      svgName="mailSuccess"
    />
  );
};

export default MailSent;
