import React from 'react';
import { useTheme } from 'styled-components/native';
import { Modal as RNModal, Platform } from 'react-native';
import { Wrapper, Button, Text, Margin } from '@lendticket/ui/components/atoms';
import { Icon } from '@lendticket/ui/components/molecules';

const isWeb = Platform.OS === 'web';
const MARGIN_TOP = 22;
const NATIVE_LINE_HEIGHT = 25;
const ICON_DIMENSIONS = 20;
const HEADER_HEIGHT = 20;
const BUTTON_STYLE = {
  border: 'none',
  borderRadius: 7,
  paddingRight: 12,
  paddingLeft: 12,
  paddingTop: 6,
  paddingBottom: 6,
};
const WRAPPER_STYLE = {
  borderWidth: 4,
  borderRadius: 20,
  paddingRight: 25,
  paddingLeft: 25,
  paddingBottom: 25,
  alignItems: 'center',
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 4,
  elevation: 5,
};

interface ModalProps {
  text?: string;
  buttonTitle?: string;
  isVisible: boolean;
  secondaryButtonTitle?: string;
  children?: React.ReactNode;
  secondaryButtonAction?: () => void;
  action?: () => void;
  onClose: () => void;
  closeModal: () => void;
}

const Modal: React.FC<ModalProps> = ({
  isVisible,
  text,
  buttonTitle,
  secondaryButtonTitle,
  children,
  secondaryButtonAction,
  onClose,
  closeModal,
  action,
}) => {
  const { settings, deviceSizes, windowDimensions } = useTheme();
  const { isMobile } = deviceSizes;
  const { windowWidth } = windowDimensions;
  const { SPACINGS, CONSTANTS, COLORS } = settings;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const derivedWidth = (isMobile ? windowWidth : DESKTOP_TABLET_WIDTH / 1.5) - SPACINGS.xl * 2;
  const lineHeight = isWeb ? {} : { lineHeight: NATIVE_LINE_HEIGHT };

  return isVisible ? (
    <Wrapper
      style={{ position: isWeb ? 'fixed' : 'absolute' }}
      f={1}
      jc="center"
      ai="center"
      mt={MARGIN_TOP}
    >
      <RNModal animationType="fade" transparent={true} visible={isVisible} onRequestClose={onClose}>
        <Wrapper f={1} jc="center" ai="center" mt={MARGIN_TOP}>
          <Wrapper
            w={derivedWidth}
            style={{
              ...WRAPPER_STYLE,
              borderColor: COLORS.BACKGROUND_PRIMARY,
              backgroundColor: COLORS.WHITE,
            }}
          >
            <Margin marginTop="md" />
            <Wrapper fd="row" h={HEADER_HEIGHT} style={{ width: '100%' }}>
              <Wrapper ai="flex-end">
                <Button onPress={onClose}>
                  <Icon
                    name="cross"
                    fill={COLORS.BACKGROUND_PRIMARY}
                    height={ICON_DIMENSIONS}
                    width={ICON_DIMENSIONS}
                  />
                </Button>
              </Wrapper>
            </Wrapper>
            <Margin marginBottom="m" />
            {children && children}
            {text && (
              <Text
                fontType={isWeb ? 'h5' : 'body'}
                style={{ color: COLORS.TEXT_LIGHT_PURPLE, textAlign: 'center', ...lineHeight }}
              >
                {text}
              </Text>
            )}
            <Margin marginTop="lg">
              <Wrapper fd="column">
                {secondaryButtonTitle && (
                  <Margin marginBottom="s">
                    <Button
                      onPress={() => {
                        if (secondaryButtonAction) {
                          secondaryButtonAction();
                        }

                        closeModal();
                      }}
                      w={derivedWidth - SPACINGS.md * 2}
                      style={{
                        ...BUTTON_STYLE,
                        border: `2px solid ${COLORS.BACKGROUND_PRIMARY}`,
                        borderWidth: 2,
                        borderColor: COLORS.BACKGROUND_PRIMARY,
                      }}
                    >
                      <Text
                        fontType={isWeb ? 'h5' : 'body'}
                        style={{
                          color: COLORS.BACKGROUND_PRIMARY,
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        {secondaryButtonTitle}
                      </Text>
                    </Button>
                  </Margin>
                )}
                {typeof action === 'function' && (
                  <Button
                    onPress={() => {
                      if (action) {
                        action();
                      }

                      closeModal();
                    }}
                    w={derivedWidth - SPACINGS.md * 2}
                    style={{
                      ...BUTTON_STYLE,
                      backgroundColor: COLORS.BACKGROUND_PRIMARY,
                    }}
                  >
                    <Text
                      fontType={isWeb ? 'h5' : 'body'}
                      style={{ color: COLORS.WHITE, textAlign: 'center', fontWeight: 'bold' }}
                    >
                      {buttonTitle}
                    </Text>
                  </Button>
                )}
              </Wrapper>
            </Margin>
          </Wrapper>
        </Wrapper>
      </RNModal>
    </Wrapper>
  ) : null;
};

export default Modal;
