import { useCallback, useContext } from 'react';
import { alertMessage } from 'helpers/alertMessage';
import { useUpdateUserSetAuthTokenAndRedirect } from './useUpdateUserSetAuthTokenAndRedirect';
import { useSocialSignin } from './useSocialSignin';
import { useAppSelector } from 'hooks/redux';
import { userSelector } from 'reducers/slices/user';
import { shallowEqual } from 'react-redux';
import { getIsWebView } from 'helpers/getIsWebView';
import { isWeb, isAndroid } from 'helpers/platform';
import { useI18n } from 'hooks/i18n';
import { ModalContext } from 'contexts';

const domain = isAndroid ? 'http://10.0.2.2:8000' : 'http://localhost:8000';
const derivedDomain = __DEV__ ? domain : 'https://api.lendticket.se';
const isTryingToLoginViaWebView = getIsWebView();

export const useOnPressSocialSignin = () => {
  const i18n = useI18n();
  const { setModalState } = useContext(ModalContext);
  const updateUserSetAuthTokenAndRedirect = useUpdateUserSetAuthTokenAndRedirect();
  const socialSignin = useSocialSignin();
  const user = useAppSelector(userSelector, shallowEqual);

  return useCallback(
    async ({ method, isGoogle }: { method: string; isGoogle?: boolean }) => {
      const isMethodLogin = method === 'login';
      const isAlreadySignedInWithRelevantSocialUserId = isGoogle
        ? isMethodLogin && user?.socialUserIdGoogle
        : isMethodLogin && user?.socialUserIdFacebook;

      if (isTryingToLoginViaWebView) {
        setModalState({
          visible: true,
          onConfirmButtonText: i18n.t('close'),
          title: i18n.t('alertLinkedInWebViewTitle'),
          message: i18n.t('alertLinkedInWebView'),
        });
      } else {
        if (isAlreadySignedInWithRelevantSocialUserId) {
          updateUserSetAuthTokenAndRedirect();
        } else {
          socialSignin({
            url: `${derivedDomain}/user/auth/${
              isGoogle ? 'google' : 'facebook'
            }?method=${method}&platform=${isWeb ? 'web' : 'app'}`,
            onSuccess: (data: any) => {
              updateUserSetAuthTokenAndRedirect(data);
            },
            onFail: (data: any) => {
              setTimeout(() => {
                if (data.errorType) {
                  alertMessage(i18n.t('alertAUserWithThatEmailAlreadyExist'));
                }
              }, 250);
            },
          });
        }
      }
    },
    [user, setModalState, i18n, updateUserSetAuthTokenAndRedirect, socialSignin],
  );
};
