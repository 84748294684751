import React from 'react';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import { Button } from '@lendticket/ui/components/molecules/IconWrapper';

interface FooterButtonProps {
  isLeft?: boolean;
  i: number;
  currentItem: Animated.SharedValue<number>;
  children: React.ReactNode;
  onPress: () => void;
}

const Wrapper = Animated.createAnimatedComponent(Button);

const FooterButton: React.FC<FooterButtonProps> = ({
  currentItem,
  i,
  isLeft,
  children,
  onPress,
}) => {
  const animatedStyle = useAnimatedStyle(() => ({
    display: i === currentItem.value ? 'flex' : 'none',
  }));

  return (
    <Wrapper isLeft={isLeft} style={animatedStyle} onPress={onPress}>
      {children}
    </Wrapper>
  );
};

export default FooterButton;
