import React, { useRef } from 'react';
import { ScrollView, KeyboardAvoidingView as RNKeyboardAvoidingView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { Wrapper, MarginColumn } from '@lendticket/ui/components/atoms';
import { useKeyboardDimensions } from '@lendticket/ui/hooks';
import { useAnimatedReaction, useSharedValue } from 'react-native-reanimated';

const BOTTOM_TABBAR_HEIGHT = 97;
const SCROLL_EVENT_THROTTHLE = 16;

interface KeyboardAvoidingViewProps {
  extraWidth?: number;
  hasMarginTop: boolean;
  scrollViewRef?: any;
  children: React.ReactNode;
}

const KeyboardAvoidingView: React.FC<KeyboardAvoidingViewProps> = ({
  hasMarginTop,
  scrollViewRef: derivedScrollViewRef,
  extraWidth,
  children,
}) => {
  const scrollViewRef = useRef<ScrollView>(null);
  const { settings, deviceSizes } = useTheme();
  const { top } = useSafeAreaInsets();
  const keyboardValues = useKeyboardDimensions();
  const hasKeyboardBeenVisible = useSharedValue(false);
  const scrollPositionY = useSharedValue(0);

  const { isMobile } = deviceSizes;
  const { CONSTANTS, SPACINGS } = settings;
  const derivedBottomTabbarHeight = BOTTOM_TABBAR_HEIGHT + top;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;

  // const scrollToTop = useCallback(() => {
  //   if (scrollViewRef?.current) {
  //     scrollViewRef.current?.scrollTo({
  //       y: 0,
  //     });
  //   }
  // }, []);

  useAnimatedReaction(
    () => ({ keyboardValues, hasKeyboardBeenVisible, scrollPositionY }),
    res => {
      if (res.keyboardValues.value.isVisible && !res.hasKeyboardBeenVisible.value) {
        hasKeyboardBeenVisible.value = true;
      }

      if (
        !res.keyboardValues.value.isVisible &&
        res.hasKeyboardBeenVisible.value &&
        res.scrollPositionY.value > 0
      ) {
        res.hasKeyboardBeenVisible.value = false;
        res.scrollPositionY.value = 0;
      }
    },
    [keyboardValues, hasKeyboardBeenVisible, scrollPositionY],
  );

  return (
    <RNKeyboardAvoidingView
      style={{ flex: 1 }}
      behavior="padding"
      keyboardVerticalOffset={derivedBottomTabbarHeight}
    >
      <Wrapper f={1} fd="row" jc="center" ai="flex-start">
        <MarginColumn />
        <Wrapper f={1} fd="column" ai="flex-start" style={{ alignSelf: 'stretch' }}>
          <ScrollView
            ref={derivedScrollViewRef ?? scrollViewRef}
            scrollEventThrottle={SCROLL_EVENT_THROTTHLE}
            onScroll={e => {
              scrollPositionY.value = e.nativeEvent.contentOffset.y;
            }}
            style={{
              width: isMobile ? '100%' : DESKTOP_TABLET_WIDTH - SPACINGS.xl * 2 + (extraWidth ?? 0),
              marginBottom: SPACINGS.md,
              marginTop: hasMarginTop ? SPACINGS.md : 0,
            }}
          >
            {children}
          </ScrollView>
        </Wrapper>
        <MarginColumn />
      </Wrapper>
    </RNKeyboardAvoidingView>
  );
};

export default KeyboardAvoidingView;
