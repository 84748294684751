import { useEffect } from 'react';
import { Keyboard, KeyboardEventListener } from 'react-native';
import { useSharedValue } from 'react-native-reanimated';

export function useKeyboardDimensions() {
  const keyboardValues = useSharedValue({
    isVisible: false,
    keyboardHeight: 0,
    coordinates: {
      end: {} as any,
      start: {} as any,
    },
  });

  const handleKeyboardWillShow: KeyboardEventListener = e => {
    keyboardValues.value = {
      ...keyboardValues.value,
      isVisible: true,
      coordinates: {
        start: e.startCoordinates,
        end: e.endCoordinates,
      },
    };
  };

  const handleKeyboardDidShow: KeyboardEventListener = e => {
    keyboardValues.value = {
      isVisible: true,
      keyboardHeight: e.endCoordinates.height,
      coordinates: {
        start: e.startCoordinates,
        end: e.endCoordinates,
      },
    };
  };

  const handleKeyboardWillHide: KeyboardEventListener = e => {
    keyboardValues.value = {
      ...keyboardValues.value,
      isVisible: false,
      coordinates: {
        start: e.startCoordinates,
        end: e.endCoordinates,
      },
    };
  };

  const handleKeyboardDidHide: KeyboardEventListener = e => {
    keyboardValues.value = {
      isVisible: false,
      keyboardHeight: 0,
      coordinates: {
        start: e.startCoordinates,
        end: e.endCoordinates,
      },
    };
  };

  useEffect(() => {
    const subscriptions = [
      Keyboard.addListener('keyboardWillShow', handleKeyboardWillShow),
      Keyboard.addListener('keyboardDidShow', handleKeyboardDidShow),
      Keyboard.addListener('keyboardWillHide', handleKeyboardWillHide),
      Keyboard.addListener('keyboardDidHide', handleKeyboardDidHide),
    ];

    return () => {
      subscriptions.forEach(subscription => subscription.remove());
    };
  }, []);

  return keyboardValues;
}
