import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import persisted from 'helpers/storage/persisted';
import { isWeb, isAndroid } from 'helpers/platform';

const domain = isAndroid ? 'http://10.0.2.2:8000' : 'http://localhost:8000';
const derivedDomain = __DEV__ ? domain : 'https://api.lendticket.se';

/* NOTE: You can handle authentication in here
  baseUrl: isWeb ? derivedDomain : domain - for
  simulator purpose 
*/
export const apiBaseQuery = fetchBaseQuery({
  baseUrl: derivedDomain,
  prepareHeaders: async headers => {
    const token = await persisted.getItem('authToken');
    headers.set('x-platform', isWeb ? 'web' : 'app');

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});
