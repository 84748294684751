import { useCallback } from 'react';
import api from 'reducers/slices/api';
import { alertMessage } from 'helpers/alertMessage';
import { useUpdateUserSetAuthTokenAndRedirect } from './useUpdateUserSetAuthTokenAndRedirect';

export const useCreateUser = () => {
  const [createUser] = api.useCreateUserMutation();
  const updateUserSetAuthTokenAndRedirect = useUpdateUserSetAuthTokenAndRedirect();

  return useCallback(
    (data: any) => {
      createUser(data)
        .then((res: any) => {
          if (res.error) {
            alertMessage(res.error.data.message);
            return;
          }

          if (res.data.token) {
            updateUserSetAuthTokenAndRedirect(res.data);
          }
        })
        .catch(error => {
          alertMessage(error);
        });
    },
    [createUser, updateUserSetAuthTokenAndRedirect],
  );
};
