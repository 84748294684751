import { alertMessage } from 'helpers/alertMessage';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import api from 'reducers/slices/api';
import { getTimestamp } from 'helpers/strings';
import { useAppSelector } from 'hooks/redux';
import { userSelector } from 'reducers/slices/user';
import { ticketsSelector } from 'reducers/slices/tickets';
import { shallowEqual } from 'react-redux';
import type { RootState } from 'helpers/redux';
import { profilesSelector } from 'reducers/slices/profiles';

const selectUseCreateChat = (state: RootState) => ({
  user: userSelector(state),
  ticket: ticketsSelector(state)?.ticket,
  profile: profilesSelector(state)?.profile,
});

export const useCreateChat = () => {
  const [createChat] = api.useCreateChatMutation();
  const { user, ticket, profile } = useAppSelector(selectUseCreateChat, shallowEqual);

  return useCallback(
    async ({ data, message, callback }: any) => {
      const messageId = uuidv4();
      const { id: userId, name } = user ?? {};

      createChat({
        ...data,
        ticket,
        profile,
        messages: [
          {
            createdAt: new Date().getTime(),
            id: messageId,
            name,
            from: userId,
            text: message,
            timestamp: getTimestamp(),
          },
        ],
      })
        .then((res: any) => {
          callback(res.data.chat);
        })
        .catch(error => {
          alertMessage(error);
        });
    },
    [createChat, profile, ticket, user],
  );
};
