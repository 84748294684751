import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type { TagTypes } from '.';

export const destinations = (
  build: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
    TagTypes,
    'api'
  >,
) => ({
  getDestinationsBySearchParams: build.query<any, any>({
    query: searchParams => `/destinations/${searchParams}`,
    providesTags: ['Destinations'],
  }),
});
