import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { Wrapper } from '@lendticket/ui/components/atoms';
import { InputField, InputFieldWithIcon } from '@lendticket/ui/components/molecules';
import { ForgotPasswordPage } from '@lendticket/ui/components/templates';
import * as routeNames from 'constants/routeNames';
import api from 'reducers/slices/api';
import { alertMessage } from 'helpers/alertMessage';
import { useEmailValidation } from 'areas/Login/hooks/useEmailValidation';
import { useI18n } from 'hooks/i18n';

const DEFAULT_WIDTH = 500;

const ForgotPasswordNavigatior: React.FC = () => {
  const i18n = useI18n();
  const emailValidation = useEmailValidation();
  const [sendMailResetPassword] = api.useSendMailResetPasswordMutation();

  const { windowDimensions, deviceSizes, settings } = useTheme();
  const { SPACINGS } = settings;
  const navigation = useNavigation();
  const { isMobile } = deviceSizes;
  const { windowWidth } = windowDimensions;

  const { control, handleSubmit } = useForm({ mode: 'onBlur' });

  const onSubmit = useCallback(
    async (data: any) => {
      const isEmailValid = emailValidation(data);

      if (isEmailValid) {
        sendMailResetPassword(data.email)
          .then(() => navigation.navigate(routeNames.EMAIL_SENT))
          .catch(error => alertMessage(error));
      }
    },
    [sendMailResetPassword, emailValidation, navigation],
  );

  return (
    <ForgotPasswordPage
      teaserTitle={i18n.t('forgotPasswordFearNotTitle')}
      teaserText={i18n.t('forgotPasswordPleaseProvideEmail')}
      svgName="forgotPassword"
      footerText={i18n.t('forgotPasswordSendInstructions')}
      footerOnPress={handleSubmit(onSubmit)}
      inputFieldsComponent={
        <Wrapper ai="flex-start" w={isMobile ? windowWidth - SPACINGS.xl * 2 : DEFAULT_WIDTH}>
          <InputFieldWithIcon svgName="email">
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value, onBlur } }) => (
                <InputField
                  hasBorder
                  hasFullWidth={false}
                  hasPaddingLeft={false}
                  keyboardType="email-address"
                  placeholder={i18n.t('placeholderProvideEmailAddressVariantOne')}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                />
              )}
            />
          </InputFieldWithIcon>
        </Wrapper>
      }
    />
  );
};

export default ForgotPasswordNavigatior;
