import 'setimmediate';
import 'react-native-get-random-values';

import React, { useEffect } from 'react';
import { StatusBar } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Provider } from 'react-redux';
import { store } from 'helpers/redux/store';
import FontStyles from 'helpers/fontStyles';
import { isWeb, isAndroid } from 'helpers/platform';
import { Gradient, FlashNotification } from '@lendticket/ui/components/molecules';
import LoadingProvider from './containers/LoadingProvider';
import UIProvider from './containers/UIProvider';
import NotificationProvider from './containers/NotificationProvider';
import KeyboardProvider from '@lendticket/ui/containers/KeyboardProvider';
import SocketProvider from './containers/SocketProvider';
import SearchProvider from './containers/SearchProvider';
import NetInfoProvider from './containers/NetInfoProvider';
import TranslationProvider from './containers/TranslationProvider';
import SunsetProvider from './containers/SunsetProvider';
import ModalProvider from './containers/ModalProvider';
import AppRouter from './AppRouter';
import I18nProvider from 'containers/i18nProvider';
import ImmersiveMode from 'react-native-immersive-mode';

const App: React.FC = () => {
  useEffect(() => {
    if (isAndroid) {
      ImmersiveMode.setBarMode('immersive' as any);
      StatusBar.setHidden(true);
    }
  }, []);

  return (
    <Provider store={store}>
      <GestureHandlerRootView style={{ flex: 1 }}>
        <UIProvider>
          <SafeAreaProvider>
            {/* NOTE: Required for web, when navigating around.
            Using SVG as a background does not cooperate well
            with react navigation on web */}
            {isWeb && <Gradient />}
            <FontStyles />
            <SocketProvider>
              <I18nProvider>
                <TranslationProvider>
                  <SunsetProvider>
                    <LoadingProvider>
                      <NotificationProvider>
                        <KeyboardProvider>
                          <NetInfoProvider>
                            <SearchProvider>
                              <ModalProvider>
                                <AppRouter />
                              </ModalProvider>
                            </SearchProvider>
                            <FlashNotification />
                          </NetInfoProvider>
                        </KeyboardProvider>
                      </NotificationProvider>
                    </LoadingProvider>
                  </SunsetProvider>
                </TranslationProvider>
              </I18nProvider>
            </SocketProvider>
          </SafeAreaProvider>
        </UIProvider>
      </GestureHandlerRootView>
    </Provider>
  );
};

export default App;
