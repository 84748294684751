import React from 'react';
import { Wrapper } from '@lendticket/ui/components/atoms';

const PADDING_TOP = 0;
const BORDER_BOTTOM_WIDTH = 1;

const Border: React.FC = () => (
  <Wrapper
    style={{
      paddingTop: PADDING_TOP,
      borderBottomWidth: BORDER_BOTTOM_WIDTH,
      borderColor: 'rgba(0,0,0,0.125)',
      width: '100%',
    }}
  />
);

export default Border;
