import React, { useMemo } from 'react';
import { Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Wrapper, Text } from '@lendticket/ui/components/atoms';
import { Appearance } from '@lendticket/ui/components/organisms';

const isWeb = Platform.OS === 'web';
const isIOS = Platform.OS === 'ios';
const ICON_DIMENSIONS = 30;
const DIMENSIONS = 20;
const DIMENSIONS_MOBILE = 24;
const RADIUS_HACK = 999;

interface NumberOfNewMessagesProps {
  numberOfNotifications: number;
}

const NumberOfNewMessages: React.FC<NumberOfNewMessagesProps> = ({ numberOfNotifications }) => {
  const { settings, deviceSizes } = useTheme();
  const { isMobile } = deviceSizes;
  const { COLORS } = settings;
  const style = useMemo(
    () =>
      isWeb
        ? {
            top: ICON_DIMENSIONS / 2 - 3,
            backgroundColor: COLORS.BACKGROUND_LIGHT_ORANGE,
            borderRadius: RADIUS_HACK,
            position: 'absolute',
            left: ICON_DIMENSIONS,
          }
        : {
            zIndex: 999,
            top: 12,
            position: 'absolute',
            backgroundColor: COLORS.BACKGROUND_LIGHT_ORANGE,
            borderRadius: RADIUS_HACK,
          },
    [COLORS.BACKGROUND_LIGHT_ORANGE],
  );

  const derivedPadding = useMemo(() => {
    if (isIOS) {
      return {
        paddingTop: 1,
        paddingLeft: 1,
      };
    } else if (isWeb) {
      if (isMobile) {
        return {
          paddingTop: 4,
        };
      }

      return {
        paddingTop: 2,
      };
    } else {
      return {};
    }
  }, []);

  return (
    <Appearance>
      <Wrapper
        w={isMobile ? DIMENSIONS_MOBILE : DIMENSIONS}
        h={isMobile ? DIMENSIONS_MOBILE : DIMENSIONS}
        style={style}
      >
        <Text
          fontType="subtitle"
          style={{
            ...derivedPadding,
            color: COLORS.WHITE,
            fontWeight: 'bold',
          }}
        >
          {numberOfNotifications}
        </Text>
      </Wrapper>
    </Appearance>
  );
};

export default NumberOfNewMessages;
