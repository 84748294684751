import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { userSelector } from 'reducers/slices/user';
import api from 'reducers/slices/api';

export const useCheckForUserCreations = () => {
  const user = useAppSelector(userSelector, shallowEqual);
  const dispatch = useAppDispatch();

  return useCallback(
    async (callback: (res: boolean) => void) => {
      if (user?.id) {
        const { data } = await dispatch(api.endpoints.getLendTicketsByUserId.initiate(user.id));
        callback(data?.tickets?.length > 0);
      }
    },
    [dispatch, user],
  );
};
