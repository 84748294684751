import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type { TagTypes } from '.';

export const user = (
  build: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
    TagTypes,
    'api'
  >,
) => ({
  deleteUserResetPasswordUrl: build.mutation<any, any>({
    query: (userId: string) => ({
      url: `/user/${userId}/reset/url`,
      method: 'DELETE',
    }),
    invalidatesTags: ['User'],
  }),
  deleteUserByUserId: build.mutation<any, any>({
    query: (userId: string) => ({
      url: `/user/${userId}`,
      method: 'DELETE',
    }),
    invalidatesTags: ['User'],
  }),
  getUserResetPasswordUrl: build.query<any, any>({
    query: (userId: string) => `/user/${userId}/reset/url`,
    providesTags: ['User'],
  }),
  getUserFacebookLogin: build.query<any, any>({
    query: (method: string) => `/user/auth/facebook/${method}`,
    providesTags: ['User'],
  }),
  getUserGoogleLogin: build.query<any, any>({
    query: (method: string) => `/user/auth/google/${method}`,
    providesTags: ['User'],
  }),
  getUserByAttribute: build.query<any, any>({
    query: (attribute: string) => `/user/${attribute}`,
    providesTags: ['User'],
  }),
  updateUserPasswordByUserIdAndLinkId: build.mutation<any, any>({
    query: ({ userId, linkId, ...data }: any) => ({
      url: `/user/${userId}/link/${linkId}`,
      method: 'PUT',
      body: data,
    }),
  }),
  userAuthApple: build.mutation<boolean, any>({
    query: (data: any) => ({
      url: `/user/auth/apple/${data.method}`,
      method: 'POST',
      body: data,
    }),
  }),
  userLoginSocial: build.mutation<boolean, any>({
    query: (data: any) => ({
      url: '/user/login/social',
      method: 'POST',
      body: data,
    }),
    invalidatesTags: ['User'],
  }),
  userLoginNormal: build.mutation<boolean, any>({
    query: (data: any) => ({
      url: '/user/login/normal',
      method: 'POST',
      body: data,
    }),
    invalidatesTags: ['User'],
  }),
  updateUserByUserId: build.mutation<any, any>({
    query: ({ userId, data }: any) => ({
      url: `/user/${userId}`,
      method: 'PUT',
      body: data,
    }),
    invalidatesTags: ['User'],
  }),
  updateUserEvaluations: build.mutation<any, any>({
    query: (updateParams: any) => ({
      url: '/user/evaluations',
      method: 'PUT',
      body: updateParams,
    }),
    invalidatesTags: ['User'],
  }),
  updateUserDeviceToken: build.mutation<any, any>({
    query: (data: { userId: string; deviceToken: string }) => ({
      url: '/user/device/token',
      method: 'PUT',
      body: data,
    }),
  }),
  createUser: build.mutation<boolean, any>({
    query: (data: any) => ({
      url: '/user/create',
      method: 'POST',
      body: data,
    }),
    invalidatesTags: ['User'],
  }),
  createFavoritesByUserIds: build.mutation<any, any>({
    query: (userIds: string[]) => ({
      url: '/user/favorites',
      method: 'POST',
      body: { userIds },
    }),
  }),
  sendMailResetPassword: build.mutation<any, any>({
    query: (email: string) => ({
      url: '/user/send/mail',
      method: 'POST',
      body: { email },
    }),
    invalidatesTags: ['User'],
  }),
});
