import styled from 'styled-components';

const Gradient = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: linear-gradient(
      to left top,
      rgba(220, 134, 124) 0%,
      rgba(220, 134, 124) 0%,
      rgba(220, 134, 124) 0%,
      rgba(220, 121, 139) 100%
    ),
    linear-gradient(rgba(255, 255, 255, 1), rgba(0, 0, 0, 1));
`;

export default Gradient;
