import React from 'react';
import { useTheme } from 'styled-components/native';
import { Text, Margin } from '@lendticket/ui/components/atoms';

interface LabelAndDescriptionProps {
  title: string;
  description: string;
}

const LabelAndDescription: React.FC<LabelAndDescriptionProps> = ({ title, description }) => {
  const { COLORS } = useTheme().settings;

  return (
    <>
      <Text fontType="h3" style={{ color: COLORS.WHITE }}>
        {title}
      </Text>
      <Margin marginBottom="xs" />
      <Text fontType="body" style={{ color: COLORS.WHITE }}>
        {description}
      </Text>
    </>
  );
};

export default LabelAndDescription;
