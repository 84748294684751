import React, { useEffect, useState, useCallback } from 'react';
import { Keyboard, Platform } from 'react-native';

export const KeyboardContext = React.createContext({});
export const { Provider } = KeyboardContext;

const isIOS = Platform.OS === 'ios';

interface KeyboardProviderWrapperProps {
  children: React.ReactNode;
}

const KeyboardProviderWrapper: React.FC<KeyboardProviderWrapperProps> = ({ children }) => {
  const [keyboardMeasures, setKeyboardMeasures] = useState({
    isKeyboardVisible: false,
    keyboardHeight: 0,
    keyboardDuration: 0,
  });

  const { isKeyboardVisible, keyboardHeight, keyboardDuration } = keyboardMeasures;

  const show = isIOS ? 'keyboardWillShow' : 'keyboardDidShow';
  const hide = isIOS ? 'keyboardWillHide' : 'keyboardDidHide';

  const handleShow = useCallback(e => {
    setKeyboardMeasures({
      isKeyboardVisible: true,
      keyboardHeight: e.endCoordinates.height,
      keyboardDuration: e.duration,
    });
  }, []);

  const handleHide = useCallback(e => {
    setKeyboardMeasures({
      isKeyboardVisible: false,
      keyboardHeight: 0,
      keyboardDuration: e.duration,
    });
  }, []);

  useEffect(() => {
    const showSubscription = Keyboard.addListener(show, handleShow);
    const hideSubscription = Keyboard.addListener(hide, handleHide);
    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider
      value={{
        isKeyboardVisible,
        keyboardHeight,
        keyboardDuration,
      }}
    >
      {children}
    </Provider>
  );
};

export default KeyboardProviderWrapper;
