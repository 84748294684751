import React from 'react';
import styled from 'styled-components/native';
import styledWeb from 'styled-components';
import { SPACINGS } from '@lendticket/ui/theme';

interface MarginProps {
  all?: string;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  marginTop?: string;
  vertical?: string;
  horizontal?: string;
  children?: React.ReactNode;
}

const getSpacings = (a?: string, b?: string, c?: string): number => SPACINGS[a || b || c] || 0;

const Wrapper = styled.View<{
  marginTop?: MarginProps['marginTop'];
  marginBottom?: MarginProps['marginBottom'];
  marginRight?: MarginProps['marginRight'];
  marginLeft?: MarginProps['marginLeft'];
  vertical?: MarginProps['vertical'];
  horizontal?: MarginProps['horizontal'];
  all?: MarginProps['all'];
}>`
  margin-top: ${({ marginTop, vertical, all }): number => getSpacings(marginTop, vertical, all)}px;
  margin-bottom: ${({ marginBottom, vertical, all }): number =>
    getSpacings(marginBottom, vertical, all)}px;
  margin-left: ${({ marginLeft, horizontal, all }): number =>
    getSpacings(marginLeft, horizontal, all)}px;
  margin-right: ${({ marginRight, horizontal, all }): number =>
    getSpacings(marginRight, horizontal, all)}px;
`;

const Margin: React.FC<MarginProps> = ({ children, ...rest }) =>
  children ? <Wrapper {...rest}>{children}</Wrapper> : <Wrapper {...rest} />;

export default Margin;
