import React from 'react';
import { useTheme } from 'styled-components/native';
import { Icon } from '@lendticket/ui/components/molecules';

const ICON_WIDTH = 155;
const ICON_HEIGHT = 166;

const Logo: React.FC = () => {
  const { COLORS } = useTheme().settings;

  return <Icon name="lendTicketLogo" fill={COLORS.WHITE} height={ICON_HEIGHT} width={ICON_WIDTH} />;
};

export default Logo;
