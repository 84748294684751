import React from 'react';
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
  useAnimatedReaction,
} from 'react-native-reanimated';

interface AnimationProps {
  index: number;
  currentSelectedTabbarIndex: number;
  children: React.ReactNode;
}

const Animation: React.FC<AnimationProps> = ({ currentSelectedTabbarIndex, index, children }) => {
  const translationY = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => ({
    transform: [
      {
        translateY: translationY.value,
      },
    ],
  }));

  useAnimatedReaction(
    () => ({ currentSelectedTabbarIndex, index }),
    res => {
      translationY.value = withTiming(
        res.currentSelectedTabbarIndex === index && translationY.value !== 5 ? 5 : 0,
        { duration: 250 },
      );
    },
    [currentSelectedTabbarIndex, index],
  );

  return <Animated.View style={animatedStyle}>{children}</Animated.View>;
};

export default Animation;
