import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from 'helpers/redux';
import { chats } from './chats';
import { lendtickets } from './lendtickets';
import { lendprofiles } from './lendprofiles';
import { user } from './user';
import { destinations } from './destinations';
import { sunset } from './sunset';

export type TagTypes =
  | 'Sunset'
  | 'User'
  | 'Chats'
  | 'LendTickets'
  | 'LendProfiles'
  | 'Destinations';

const api = createApi({
  reducerPath: 'api',
  tagTypes: ['Sunset', 'User', 'Chats', 'LendTickets', 'LendProfiles', 'Destinations'],
  baseQuery: apiBaseQuery,
  endpoints: build => ({
    ...chats(build),
    ...user(build),
    ...lendtickets(build),
    ...lendprofiles(build),
    ...destinations(build),
    ...sunset(build),
  }),
});

export default api;
