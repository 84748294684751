import React from 'react';
import { ContentPage } from '@lendticket/ui/components/templates';

const POLICIES = [
  {
    title: 'Privacy notice',
    text: 'Last updated February 02, 2023',
  },
  {
    title: 'Introduction',
    text: `This privacy notice for LendTicket ("Company", "we", "us", or "our"), describes how and why we might collect, store, use and/or share ("process") your information when you use our services ("Services"), such as when you
   
• Visit our website at https://www.lendticket.se, or any website of ours that links to this privacy notice
• Download and use our mobile application (LendTicket), or any other application of ours that links to this privacy notice
• Engage with us in other related ways, including any sales, marketing, or events

Questions or conercns? Reading this privacy notice will help you understand your privacy rights and choices. If you do no agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at marcuzgabriel@gmail.com
  `,
  },
  {
    title: 'Summary of key points',
    text: `This summary provides key points from our privacy notice. The following areas will be answered:

What personal information do we process?

When you visit, use or navigate our Services, we may process personal information depending on how you interact with LendTicket and the Services, the choices you make, and the products and features you use.
    
Do we process any sensitive personal information?

We may process sesnsitive personal information when necessary with your consent or as otherwise permitted by applicable law.
    
Do we receive any information from third parties?

We may receive information from public databases, marketing partners, social media platforms, and other outside sources.
    
How do we process your information?

We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.

In what situations and with which parties do we share personal information?

We may share information in specific situations and with specific third parties.

How do we keep your information safe?

We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.

What are your rights?

Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.

How do you exercise your rights?

The easiest way to exercise your rights is by filling out our data subject request form available here: https://lendticket.se/update-user, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.

Want to learn more about what LendTicket does with any information we collect? Then please continue to read.
    `,
  },
  {
    title: 'Table of contens',
    text: `
1. What information do we collect?
2.	How do we process your information?
3.	What legal bases do we rely on to process your personal information?
4.	When and with whom do we share your personal information?
5.	How do we handle your social logins?
6.	How long do we keep your information?
7.	How do we keep your information safe?
8.	Do we collect information from minors?
9.	What are your privacy rights?
10.	Controls for do-not-track features
11.	Do we make updates to notice?
12.	How can you contact us about this notice?
13.	How can you review, update, or delete the data we collect from you?
    `,
  },
  {
    title: '1. What information do we collect?',
    text: `
In short: We collect personal information that you provide to us.

We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.

Personal information Provided by You
The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following: 

• Names
• Phone numbers
• Email addresses
• Mailing addresses
• Usernames
• Passwords
• Device token (for notifications)

Sensitive information
When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:

Social Media Login Data
We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called ‘How do we handle your social logins?’ below.

Application Data
If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:

Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device’s notifications, and other features. If you wish to change our access or permissions, you may do so in your device’s settings.

Mobile Device Data. We automatically collect device information (such as your mobile device ID, model, and manufacturer).

Push Notifications. We may request to send you push notifications regarding your account or certain features of the applications(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device’s settings.

This information is primarily needed to maintain the security and operations of our applications(s), for troubleshooting, and for our internal analytics and reporting purposes.

All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
`,
  },
  {
    title: '2. How do we process your information?',
    text: `
In short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.

We process your personal information for a variety of reasons, depending on how you interact with our Services, including:

• To facility account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.

• To request feedback. We may process your information when necessary to request feedback and to contact your about your use of our Services.

• To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.

• To save or protect an individual’s vital interest. We may process your information when necessary to save or protect and individual’s vital interest, such as to prevent harm.
`,
  },
  {
    title: '3. What legal bases do we rely on to process your information?',
    text: `
In short: We only process your personal information when we believe it is necessary and we have valid legal reason (i.e legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.

The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:

• Consent. We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw you consent at any time.

• Legitimate interests. We may process your information when we believe it is reasonable necessary to achieve our legitimate business interests and thoese interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes describes in order to:

1. Diagnose problems and/or prevent fraudulent activities
2. Understand how our users use our products and services so we can improve user experience

• Legal oblications. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.

• Vital interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential to the safety of any person.
`,
  },
  {
    title: '4. When and with whom do we share your personal information?',
    text: `
In short: We may share information in specific situations described in this section and/or with the following third parties.

We may need to share your personal information in the following situations:

Business transfers. We may share or transfer your information in connection with, or during negotiations of, any merge, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
`,
  },
  {
    title: '5. How do we handle your social logins?',
    text: `
In short: If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.

Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address and profile picture, as well as other information you choose to make public on such a social media platform.

We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We commend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.    
    `,
  },
  {
    title: '6. How long de we keep your information?',
    text: `
In short: We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.

We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period in which users have an account with us.

When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
    `,
  },
  {
    title: '7. How do we keep your information safe?',
    text: `
In short: We aim to protect your personal information through a system of organizational and technical security measures.

We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
    `,
  },
  {
    title: '8. Do we collect information from minors?',
    text: `
In short: We do not knowingly collect data from or market to children under 18 years of age.

We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data, we may have collected from children under age 18, please contact us at marcuzgabriel@gmail.com
    `,
  },
  {
    title: '9. What are your privacy rights?',
    text: `
In short: In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you have rights that allow you greater access to and control over your personal information. You may review, change or terminate your account at any time.

In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section ‘How can you contact us about this notice?’ below.

We will consider and act upon any request in accordance with applicable data protection laws.

If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.

Withdrawing your consent:
If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us 
    `,
  },
  {
    title: '10. controls for do-no-track-features',
    text: `
Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (‘DNT’) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
    `,
  },
  {
    title: '11. Do we make updates to notice?',
    text: `
In short: Yes, we will update this notice a necessary to stay compliant with relevant laws.

We may update this privacy notice from time to time. The updated version will be indicated by an updated ‘Revised’ date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
    `,
  },
  {
    title: '12. How can you contact us about this notice?',
    text: `
If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO), Marcuz Gabriel Larsen, by email at marcuzgabriel@gmail.com or by post to:

LendTicket
Marcuz Gabriel Larsen
Frederiksborggade 15, 3 sal (Consid ApS)
Copenhagen, København K 1360
Denmark
    `,
  },
  {
    title: '13. How can you review, update or delete the data we collect form?',
    text: `
Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update or delete your personal information, please visit: https://lendticket.se/update-user.
    `,
  },
];

const Upcoming: React.FC = () => {
  return <ContentPage policies={POLICIES} />;
};

export default Upcoming;
