import React, { useEffect, useState } from 'react';
import { AppState } from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import { NetInfoProviderWrapper } from 'contexts';
import { showMessage } from 'react-native-flash-message';
import { loadingActions } from 'reducers/slices/loading';
import { useAppDispatch } from 'helpers/redux/store';
import { useI18n } from 'hooks/i18n';

interface ConnectivityMonitorProviderProps {
  children: React.ReactNode;
}

const NetInfoProvider: React.FC<ConnectivityMonitorProviderProps> = ({ children }) => {
  const i18n = useI18n();
  const [connectionChange, setConnectionChange] = useState<any>({});
  const { isConnected } = connectionChange ?? {};
  const isDisconnected = typeof isConnected === 'boolean' && !isConnected;

  const dispatch = useAppDispatch();

  let connectionInfo: any = {};

  const handleConnectivityChange = (newConnectionInfo: any) => {
    // NOTE: preventing extra dispatches via a local state
    if (connectionInfo.type !== newConnectionInfo.type) {
      connectionInfo = newConnectionInfo;
      setConnectionChange(newConnectionInfo);
    }
  };

  const checkConnectivityChange = (nextAppState: any) => {
    if (nextAppState === 'active') {
      NetInfo.fetch().then(newConnectionInfo => handleConnectivityChange(newConnectionInfo));
    }
  };

  useEffect(() => {
    let unsubscribe: any;
    let appStateChangeSubscription: any;

    unsubscribe = NetInfo.addEventListener(handleConnectivityChange);
    appStateChangeSubscription = AppState.addEventListener('change', checkConnectivityChange);

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }

      appStateChangeSubscription.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDisconnected) {
      connectionInfo = {};
      setConnectionChange({});
      dispatch(loadingActions.setLoadingContent({ isLoading: false }));
      showMessage({
        message: i18n.t('noInternetConnection'),
        description: i18n.t('noInternetConnectionDescription'),
        type: 'warning',
        icon: 'warning',
        duration: 5000,
      });
    }
  }, [dispatch, isDisconnected]);

  return <NetInfoProviderWrapper value={{ connectionChange }}>{children}</NetInfoProviderWrapper>;
};

export default NetInfoProvider;
