import React, { useCallback, useContext, useState, useEffect } from 'react';
import { NotificationProviderWrapper, SocketContext } from '../../contexts';
import { useAppSelector } from 'hooks/redux';
import { userSelector } from 'reducers/slices/user';
import { shallowEqual } from 'react-redux';
import persisted from 'helpers/storage/persisted';

interface Props {
  children: React.ReactNode;
}

const NotificationProvider: React.FC<Props> = ({ children }): React.ReactElement => {
  const { createSocket, removeSocket } = useContext(SocketContext);
  const [socketData, setSocketData] = useState<any>(undefined);
  const [numberOfNotifications, setNumberOfNoticiations] = useState<any>(undefined);
  const user = useAppSelector(userSelector, shallowEqual);

  const setNotifications = useCallback(async () => {
    setSocketData(undefined);
    setNumberOfNoticiations(numberOfNotifications + 1);
    persisted.setItem('notifications', {
      numberOfNotifications: numberOfNotifications + 1,
      ticketId: socketData.ticketId,
      profileId: socketData.profileId,
    });
  }, [numberOfNotifications, socketData]);

  const removeNotifications = useCallback(async () => {
    setSocketData(undefined);
    setNumberOfNoticiations(0);
    persisted.removeItem('notifications');
  }, []);

  const initializeNotification = useCallback(async () => {
    const { numberOfNotifications: derivedNumberOfNotifications } =
      (await persisted.getItem('notifications')) ?? {};
    setNumberOfNoticiations(derivedNumberOfNotifications ?? 0);
  }, []);

  useEffect(() => {
    if (
      socketData?.chatParticipantIds?.includes(user?.id) &&
      socketData?.messageSentFromUserId !== user?.id
    ) {
      setNotifications();
    }
  }, [numberOfNotifications, setNotifications, socketData, user?.id]);

  useEffect(() => {
    if (!numberOfNotifications) {
      initializeNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const socketId = 'socketChat';
    const socketChat = createSocket(socketId);

    socketChat.on('foundNewChatMessageNotification', (data: any) => {
      setSocketData(data);
    });

    () => {
      socketChat.off('foundNewChatMessageNotification');
      removeSocket(socketId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NotificationProviderWrapper value={{ numberOfNotifications, removeNotifications }}>
      {children}
    </NotificationProviderWrapper>
  );
};

export default NotificationProvider;
