import React from 'react';
import { ActivityIndicator, Platform } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import { DrawerContentScrollView } from '@react-navigation/drawer';
import { Text, Wrapper, Margin } from '@lendticket/ui/components/atoms';
import { InputField } from '@lendticket/ui/components/molecules';
import SelectableCheckboxes from './SelectableCheckboxes';
import { getTextStyle } from '@lendticket/ui/helpers';
import { useI18n } from '@lendticket/app/src/hooks/i18n';

const isIOS = Platform.OS === 'ios';
const INPUT_HEIGHT = 40;
const INPUT_BORDER_COLOR = 'rgba(0, 0, 0, 0.05)';
const INPUT_BORDER_WIDTH = 1;
const INPUT_PLACEHOLDER_TEXT_COLOR = 'rgba(0, 0, 0, 0.4)';
const FONT_SIZE_MOBILE = 14;
const FONT_SIZE_DEFAULT = 16;
const BUTTON_HEIGHT = 35;
const BUTTON_BORDER_RADIUS = 7;
const BUTTON_BORDER_WIDTH = 2;
const SCROLL_VIEW_PADDING = 16;

interface SearchPageProps {
  onResetSearchQueries: () => void;
  onSearchChange: (value: string | boolean, name: string) => void;
  onSearch: () => void;
  setJsSelectionState: (props: any) => void;
  formData: {
    placeholder: string;
    name: string;
    ref: any;
  }[];
  isLoading: Animated.SharedValue<boolean>;
  uiSelectionState: Animated.SharedValue<Record<string, any>>;
  jsSelectionState: Record<string, any>;
  selectableItems: { [key: string]: string }[];
  rest: Record<string, any>;
}

const TouchableOpacity = styled.TouchableOpacity<{ hasBackgroundColor?: boolean }>`
  width: 100%;
  justify-content: center;
  align-items: center;
  height: ${BUTTON_HEIGHT}px;
  border-radius: ${BUTTON_BORDER_RADIUS}px;
  border-width: ${BUTTON_BORDER_WIDTH}px;
  border-color: ${({ theme }) => theme.settings.COLORS.BACKGROUND_PRIMARY};
  background-color: ${({ theme, hasBackgroundColor }) =>
    hasBackgroundColor ? theme.settings.COLORS.BACKGROUND_PRIMARY : 'transparent'};
`;

const SearchPage: React.FC<SearchPageProps> = ({
  setJsSelectionState,
  onSearchChange,
  onResetSearchQueries,
  onSearch,
  uiSelectionState,
  jsSelectionState,
  formData,
  isLoading,
  selectableItems,
  ...rest
}) => {
  const i18n = useI18n();
  const theme = useTheme();
  const { settings, deviceSizes } = theme;
  const { isMobile } = deviceSizes;
  const { COLORS } = settings;
  const textStyleBody = getTextStyle({ fontType: 'body', theme });

  const animatedStyleIsLoading = useAnimatedStyle(() => ({
    display: isLoading.value ? 'flex' : 'none',
  }));

  const animatedStyleIsNotLoading = useAnimatedStyle(() => ({
    display: isLoading.value ? 'none' : 'flex',
  }));

  return (
    <DrawerContentScrollView {...rest} style={{ flex: 1, padding: SCROLL_VIEW_PADDING }}>
      <Wrapper ai="flex-start">
        <Text fontType="h4" style={{ textAlign: 'left', color: COLORS.TEXT_LIGHT_PURPLE }}>
          {i18n.t('searchAfterTicketsAndProfiles')}
        </Text>
        <Margin marginTop="m" />
        {formData.map(({ placeholder, name, ref }, i) => (
          <Wrapper
            key={`${name}-${i}`}
            fd="row"
            ai="center"
            jc="center"
            h={INPUT_HEIGHT}
            style={{
              borderBottomWidth: INPUT_BORDER_WIDTH,
              borderColor: INPUT_BORDER_COLOR,
            }}
          >
            <InputField
              inputRef={ref}
              hasFullWidth
              keyboardType={isIOS ? 'web-search' : 'default'}
              placeholder={placeholder}
              onChange={value => onSearchChange(value, name)}
              placeholderTextColor={INPUT_PLACEHOLDER_TEXT_COLOR}
              textStyle={{
                ...textStyleBody,
                fontWeight: 'normal',
                fontSize: isMobile ? FONT_SIZE_MOBILE : FONT_SIZE_DEFAULT,
                paddingLeft: 0,
                color: COLORS.TEXT_LIGHT_PURPLE,
              }}
            />
          </Wrapper>
        ))}
        <Margin marginTop="md" />
        <SelectableCheckboxes
          onSearchChange={onSearchChange}
          setJsSelectionState={setJsSelectionState}
          jsSelectionState={jsSelectionState}
          uiSelectionState={uiSelectionState}
          selectableItems={selectableItems}
        />
        <Margin marginTop="md" />
        <TouchableOpacity onPress={onResetSearchQueries}>
          <Text fontType="body" style={{ color: COLORS.BACKGROUND_PRIMARY, fontWeight: 'bold' }}>
            {i18n.t('reset')}
          </Text>
        </TouchableOpacity>
        <Margin marginTop="s" />
        <TouchableOpacity onPress={onSearch} hasBackgroundColor>
          <Animated.View style={animatedStyleIsLoading}>
            <ActivityIndicator color={COLORS.WHITE} />
          </Animated.View>
          <Animated.View style={animatedStyleIsNotLoading}>
            <Text fontType="body" style={{ color: COLORS.WHITE, fontWeight: 'bold' }}>
              {i18n.t('search')}
            </Text>
          </Animated.View>
        </TouchableOpacity>
      </Wrapper>
    </DrawerContentScrollView>
  );
};

export default SearchPage;
