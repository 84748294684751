/* 
Inspiration: https://medium.com/edonec/redux-can-be-made-easier-with-redux-toolkit-b1d2d17b90ba
https://medium.com/geekculture/redux-toolkit-rtk-in-react-js-or-react-native-application-simplify-redux-with-react-and-apis-d61bdc67377e
https://medium.com/geekculture/redux-persist-redux-toolkit-implementation-made-easy-for-react-native-and-react-js-831ee1e3f22b
*/

import { combineReducers } from '@reduxjs/toolkit';
import { redirectReducer } from './slices/redirect';
import { userReducer } from './slices/user';
import { ticketsReducer } from './slices/tickets';
import { chatsReducer } from './slices/chats';
import { profilesReducer } from './slices/profiles';
import { loadingReducer } from './slices/loading';
import { searchReducer } from './slices/search';

const rootReducer = combineReducers({
  loading: loadingReducer,
  redirect: redirectReducer,
  user: userReducer,
  tickets: ticketsReducer,
  profiles: profilesReducer,
  chats: chatsReducer,
  search: searchReducer,
});

export default rootReducer;
