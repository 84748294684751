import type { GestureParams } from '../';

export const onActive = ({
  e,
  prevDragX,
  dragX,
  swiperWidth,
  translationX,
  ctx,
}: GestureParams) => {
  'worklet';

  prevDragX.value = dragX.value;
  dragX.value = ctx?.startX + e.translationX;

  if (dragX.value > 0 && dragX.value <= swiperWidth.value) {
    translationX.value = dragX.value;
  }
};
