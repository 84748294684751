import React from 'react';

import { useTheme } from 'styled-components/native';
import { LoginWithFBGoogleOrApple } from '@lendticket/ui/components/molecules';
import { Text, Margin, BorderButton, Button } from '@lendticket/ui/components/atoms';

const FORGET_BUTTON_HEIGHT = 20;

export interface FormActions {
  actions: {
    [key: string]: {
      title: string;
      action: (onSubmit?: () => void) => void;
    };
  };
}

interface LoginOrCreateButtonTypes {
  actions: FormActions['actions'];
  type: 'login' | 'create';
  onSubmit: () => void;
}

const LoginOrCreateButtons: React.FC<LoginOrCreateButtonTypes> = ({ type, actions, onSubmit }) => {
  const { settings } = useTheme();
  const { COLORS } = settings;

  return (
    <>
      {actions?.forget && (
        <Margin marginTop="md">
          <Button onPress={actions.forget.action} h={FORGET_BUTTON_HEIGHT}>
            <Text fontType="body" style={{ color: COLORS.WHITE, textAlign: 'center' }}>
              {actions.forget.title}
            </Text>
          </Button>
        </Margin>
      )}
      <Margin vertical="xl">
        <BorderButton title={actions[type].title} onPress={() => onSubmit()} />
      </Margin>
      <LoginWithFBGoogleOrApple actions={actions} type={type} />
    </>
  );
};

export default LoginOrCreateButtons;
