import React from 'react';
import { Platform } from 'react-native';
import { FieldValues, Control } from 'react-hook-form';
import Animated from 'react-native-reanimated';
import CreatePageFormDefault from './index.default';
import CreatePageFormIOS from './index.ios';

export interface CreatePageFormProps {
  control: Control<FieldValues, any>;
  selectedPeriods: {
    ticket: any[];
    profile: any[];
  };
  animatedValues: {
    swiperWidth: Animated.SharedValue<number>;
    translationX: Animated.SharedValue<number>;
  };
  datePickerDates: any;
  toggleTicketTypesAndPayment: any;
  isAllOfSkaeneSelected: { ticket: boolean; profile: boolean };
  setIsAllOfSkaeneSelected: () => void;
  setToggleTicketTypesAndPayment: (updateParams: any) => void;
  onDestinationSearch: (param: string) => void;
  updateSelectedPeriods: (selectedPeriod: any[]) => void;
  setDatePickerDates: (updateParams: any) => void;
}

const CreatePageForm: React.FC<CreatePageFormProps> = props =>
  Platform.select({
    default: <CreatePageFormDefault {...props} />,
    ios: <CreatePageFormIOS {...props} />,
  });

export default CreatePageForm;
