import React from 'react';
import { useTheme } from 'styled-components/native';
import { Controller, Control } from 'react-hook-form';
import { Wrapper } from '@lendticket/ui/components/atoms';
import { InputField } from '@lendticket/ui/components/molecules';

const BORDER_WIDTH = 3;
const BORDER_RADIUS = 7;
const INPUT_FIELD_HEIGHT = 50;
const PADDING_LEFT = 16;

interface SearchProps {
  control: Control;
  onSearchChange: (string: value) => void;
}

const Search: React.FC<SearchProps> = ({ control, onSearchChange }) => {
  const { settings } = useTheme();
  const { COLORS } = settings;

  return (
    <Wrapper ai="flex-start" jc="flex-start">
      <Controller
        control={control}
        name="search_favorite_name"
        render={({ field: { value, onBlur } }) => (
          <Wrapper
            h={INPUT_FIELD_HEIGHT}
            jc="center"
            ai="flex-start"
            style={{
              borderWidth: BORDER_WIDTH,
              borderColor: COLORS.BACKGROUND_LIGHT_ORANGE,
              borderRadius: BORDER_RADIUS,
            }}
          >
            <InputField
              hasFullWidth
              keyboardType="default"
              placeholder="Søg på favorit navn"
              onBlur={onBlur}
              onChange={onSearchChange}
              value={value}
              style={{ paddingLeft: PADDING_LEFT }}
            />
          </Wrapper>
        )}
      />
    </Wrapper>
  );
};

export default Search;
