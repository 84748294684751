import React from 'react';
import { useTheme } from 'styled-components/native';
import { Wrapper, Text } from '@lendticket/ui/components/atoms';

interface DefaultProfileImageProps {
  width: number;
  height: number;
  backgroundColor: string;
  text: string;
}

const DefaultProfileImage: React.FC<DefaultProfileImageProps> = ({
  width,
  height,
  backgroundColor,
  text,
}) => {
  const { settings } = useTheme();
  const { COLORS } = settings;

  return (
    <Wrapper
      style={{
        backgroundColor,
        width: width,
        height: height,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 999,
      }}
    >
      <Text fontType="h3" style={{ color: COLORS.WHITE }}>
        {text}
      </Text>
    </Wrapper>
  );
};

export default DefaultProfileImage;
