import React, { useState } from 'react';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { Wrapper } from '@lendticket/ui/components/atoms';
import { PanGestureHandler } from '@lendticket/ui/components/organisms';

interface SliderProps {
  items: React.FC<{}>[];
  translationX: Animated.SharedValue<number>;
  currentItem: Animated.SharedValue<number>;
}

const Slider: React.FC<SliderProps> = ({ items, translationX, currentItem }) => {
  const [visibleIndexes, setVisibleIndexes] = useState([0, 1]);

  const { windowDimensions } = useTheme();
  const { windowWidth } = windowDimensions;

  const animatedStyle = useAnimatedStyle(() => ({
    height: '100%',
    width: windowWidth,
    transform: [
      {
        translateX: translationX.value,
      },
    ],
  }));

  return (
    <PanGestureHandler
      disallowSlideLeftOnFirstScreen
      currentItem={currentItem}
      translationX={translationX}
      visibleIndexes={visibleIndexes}
      setVisibleIndexes={setVisibleIndexes}
      itemWidth={windowWidth}
      maxNumberOfItems={items.length - 1}
    >
      <Wrapper f={1} ai="flex-start" fd="row" ofx="hidden" w={windowWidth}>
        {items.map((Component: any, i) => (
          <Animated.View key={`${Component}-${i}`} style={animatedStyle}>
            <Component />
          </Animated.View>
        ))}
      </Wrapper>
    </PanGestureHandler>
  );
};

export default Slider;
