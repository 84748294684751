import { useNavigation } from '@react-navigation/native';
import * as routeNames from 'constants/routeNames';
import type { RootState } from 'helpers/redux/store';
import { useAppDispatch } from 'helpers/redux/store';
import { useAppSelector } from 'hooks/redux';
import { useCallback, useContext, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import api from 'reducers/slices/api';
import { loadingActions } from 'reducers/slices/loading';
import { profileActions, profilesSelector } from 'reducers/slices/profiles';
import { ticketActions } from 'reducers/slices/tickets';
import { userSelector } from 'reducers/slices/user';
import { isWeb } from 'helpers/platform';
import { SocketContext } from '../../../contexts';

export const INITIAL_STATE = {
  deletedProfiles: {} as Record<string, any>,
  newProfiles: {
    profiles: [],
    profileIds: [],
  },
  liveProfiles: {
    allProfiles: [],
    profiles: [],
    profileIds: [],
    searchProfiles: [] as { [key: string]: string }[],
    newProfileIds: [],
    isSearching: false,
    isInitiated: false,
    checkForUpdates: false,
    hasProfiles: true,
  },
};

const findProfilesSelector = (state: RootState) => ({
  user: userSelector(state),
  profiles: profilesSelector(state),
});

export const useGenerateProfiles = () => {
  const { createSocket, removeSocket } = useContext(SocketContext);
  const [liveProfiles, setLiveProfiles] = useState(INITIAL_STATE.liveProfiles);
  const [newProfiles, setNewProfiles] = useState(INITIAL_STATE.newProfiles);
  const [deletedProfiles, setDeletedProfiles] = useState(INITIAL_STATE.deletedProfiles);
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(findProfilesSelector, shallowEqual);

  const { getChatByProfileIdAndUserId, getLendProfiles } = api.endpoints;

  const searchReset = useCallback(() => {
    setLiveProfiles({
      ...liveProfiles,
      isSearching: false,
      searchProfiles: INITIAL_STATE.liveProfiles.searchProfiles,
      hasProfiles: liveProfiles.profiles.length > 0,
    });
  }, [liveProfiles]);

  const onSwipeAndOnPressAction = useCallback(
    async ({ id }: any) => {
      const params = { profileId: id, userId: user?.id };

      const { refetch } = dispatch(getChatByProfileIdAndUserId.initiate(params));
      const { data } = await refetch();
      const { status: profileDataStatus, chat } = data ?? {};
      const isTicketOwner =
        chat?.[0]?.createdByUserId === user?.id ||
        liveProfiles.profiles
          .filter(({ id: profileId }) => profileId === id)
          .some(
            ({ createdByUserId: derivedCreatedByUserId }) => derivedCreatedByUserId === user?.id,
          );

      if (profileDataStatus === 200) {
        dispatch(loadingActions.setLoadingContent({ isLoading: false }));
        dispatch(ticketActions.setIsTicketOwner(isTicketOwner));
        dispatch(
          profileActions.setSelectedProfile(
            liveProfiles.profiles.filter(({ id: profileId }) => profileId === id),
          ),
        );

        if (chat?.[0]?.id) {
          navigation.navigate(routeNames.CHAT, {
            profileId: id,
            chatId: data?.chat?.[0]?.id,
            userId: user?.id,
          });
        } else {
          navigation.navigate(routeNames.CHAT, { profileId: id, userId: user?.id });
        }
      }
    },
    [user, getChatByProfileIdAndUserId, liveProfiles, navigation, dispatch],
  );

  /* NOTE: Initial - show first 10 for performance reason */
  const initiate = useCallback(async () => {
    const { refetch } = dispatch(getLendProfiles.initiate());
    const { data } = await refetch();
    const { profiles } = data ?? {};

    if (Object.keys(profiles).length > 0) {
      const profilesFirstTen = [...profiles]
        .sort((a: any, b: any) => b.createdAt - a.createdAt)
        .slice(0, isWeb ? 10 : profiles.length);

      setLiveProfiles({
        ...liveProfiles,
        allProfiles: profiles,
        profiles: profilesFirstTen,
        profileIds: profilesFirstTen.map(({ id }: { id: string }) => id),
        hasProfiles: profiles?.length > 0,
        isInitiated: true,
        checkForUpdates: false,
      });
    } else {
      setLiveProfiles({
        ...INITIAL_STATE.liveProfiles,
        isInitiated: true,
        hasProfiles: false,
      });
    }
  }, [dispatch, getLendProfiles, liveProfiles]);

  /* NOTE: Reset and updating behaviour based on new tickets */
  const updateNewProfiles = useCallback(
    ({ profiles, newProfileIds }: any) => {
      const updatedSetOfProfiles = liveProfiles.profiles.concat(profiles);

      setLiveProfiles({
        ...liveProfiles,
        profiles: updatedSetOfProfiles,
        profileIds: updatedSetOfProfiles.map(({ id }) => id),
        newProfileIds,
        hasProfiles: true,
      });
    },
    [liveProfiles],
  );

  /* NOTE: Profile deletion */
  const deleteProfiles = useCallback(
    ({ profileId, profileIds }: any) => {
      const updatedSetOfProfiles = liveProfiles.profiles.filter(({ id }) =>
        profileIds ? !profileIds.includes(id) : id !== profileId,
      );

      setLiveProfiles({
        ...liveProfiles,
        allProfiles: updatedSetOfProfiles,
        profiles: updatedSetOfProfiles,
        profileIds: updatedSetOfProfiles.map(({ id }) => id),
        hasProfiles: updatedSetOfProfiles.length > 0,
      });
    },
    [liveProfiles],
  );

  /* NOTE: Handle ticket deletions */
  useEffect(() => {
    if (Object.keys(deletedProfiles)?.length > 0) {
      setDeletedProfiles(INITIAL_STATE.deletedProfiles);
      deleteProfiles(deletedProfiles);
    }
  }, [deleteProfiles, deletedProfiles]);

  /* NOTE: Handle socket updates */
  useEffect(() => {
    if (newProfiles.profileIds.length > 0) {
      setNewProfiles(INITIAL_STATE.newProfiles);
      updateNewProfiles({
        profiles: newProfiles.profiles,
        newProfileIds: newProfiles.profileIds,
      });
    }
  }, [user, newProfiles, updateNewProfiles]);

  useEffect(() => {
    if (!liveProfiles.isInitiated) {
      initiate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* NOTE: Socket connection */
  useEffect(() => {
    const socketId = 'socketLendProfiles';
    const socket = createSocket(socketId);

    socket.on(
      'profileDeletion',
      ({ profileId, profileIds }: { profileId: string; profileIds: string[] }) => {
        setDeletedProfiles({ profileId, profileIds });
      },
    );

    socket.on('foundLendProfiles', (data: any) => {
      setNewProfiles({
        profiles: data,
        profileIds: data.map(({ id }: any) => id),
      });
    });

    return () => {
      socket.off('profileDeletion');
      socket.off('foundLendProfiles');
      removeSocket(socketId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEndReached = useCallback(() => {
    if (!isWeb) {
      return;
    }

    const numberOfVisualProfiles = liveProfiles.profiles.length;
    const minimumRequireMentOfVisualProfiles = numberOfVisualProfiles >= 10;
    const allProfiles = [...liveProfiles.allProfiles].sort(
      (a: any, b: any) => b.createdAt - a.createdAt,
    );

    if (minimumRequireMentOfVisualProfiles && numberOfVisualProfiles < allProfiles.length) {
      const diff = allProfiles.length - numberOfVisualProfiles;
      const loadMoreLiveProfiles = liveProfiles.profiles.concat(
        allProfiles.slice(
          liveProfiles.profiles.length,
          liveProfiles.profiles.length + (diff >= 10 ? 10 : diff),
        ),
      );

      setLiveProfiles({
        ...liveProfiles,
        profiles: loadMoreLiveProfiles,
        profileIds: loadMoreLiveProfiles.map(({ id }) => id),
      });
    }
  }, [liveProfiles]);

  return {
    liveProfiles,
    cards: liveProfiles.isSearching
      ? liveProfiles.searchProfiles.sort((a: any, b: any) => b.createdAt - a.createdAt)
      : liveProfiles.profiles.sort((a: any, b: any) => b.createdAt - a.createdAt),
    handleProfileUpdates: initiate,
    searchReset,
    setLiveProfiles,
    onEndReached,
    onSwipeAndOnPressAction,
  };
};
