import { createGlobalStyle } from 'styled-components';
import RobotoWoff2 from '@lendticket/ui/assets/fonts/Hubot-Sans.woff2';

const FontStyles = createGlobalStyle`
  @font-face {
    font-family: 'Hubot-Sans';
    src: url(${RobotoWoff2}) format('woff2 supports variations'),
    url(${RobotoWoff2}) format('woff2-variations');
    font-weight: 200 900;
    font-stretch: 75% 125%;
  }
`;

export default FontStyles;
