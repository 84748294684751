import React from 'react';
import { useTheme } from 'styled-components/native';
import { Wrapper, Margin } from '@lendticket/ui/components/atoms';
import { Icon } from '@lendticket/ui/components/molecules';

const ICON_DIMENSIONS_MOBILE = 25;
const ICON_DIMENSIONS_DESKTOP_TABLET = 35;
export const HEIGHT_MOBILE = 60;
export const HEIGHT_DESKTOP_TABLET = 70;

interface InputFieldWithIconProps {
  svgName: string;
  children: React.ReactNode;
}

const InputFieldWithIcon: React.FC<InputFieldWithIconProps> = ({ svgName, children }) => {
  const { settings, deviceSizes } = useTheme();
  const { COLORS } = settings;
  const { isMobile } = deviceSizes;
  const iconDimensions = isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS_DESKTOP_TABLET;

  return (
    <Wrapper fd="row">
      <Margin marginRight="md">
        <Icon name={svgName} fill={COLORS.WHITE} height={iconDimensions} width={iconDimensions} />
      </Margin>
      {children}
    </Wrapper>
  );
};

export default InputFieldWithIcon;
