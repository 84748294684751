import { GestureUpdateEvent, PanGestureHandlerEventPayload } from 'react-native-gesture-handler';
import type { GestureParams } from '../';

export const getPanOnActive =
  ({
    startX,
    prevDragX,
    dragX,
    translationX,
    isLoading,
    cardWidth,
    textWidth,
    onSwipeCallback,
  }: GestureParams) =>
  (e: GestureUpdateEvent<PanGestureHandlerEventPayload>) => {
    'worklet';

    prevDragX.value = translationX.value;
    dragX.value = startX.value + e.translationX;

    if (dragX.value < 0 && dragX.value >= -cardWidth.value) {
      if (onSwipeCallback && !isLoading.value && dragX.value <= -textWidth.value) {
        isLoading.value = true;
      }
      translationX.value = dragX.value;
    }
  };
