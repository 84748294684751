import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components/native';
import { isWeb, isIOS } from 'helpers/platform';
import { Text, Wrapper, Margin, MarginColumn } from '@lendticket/ui/components/atoms';
import { useGetNotificationPermissions } from 'hooks/notifications/permissions';
import { useI18n } from 'hooks/i18n';

const NotificationInformation: React.FC = () => {
  const i18n = useI18n();
  const { settings } = useTheme();
  const { COLORS } = settings;
  const getNotificationPermissions = useGetNotificationPermissions();
  const [isNotificationAccepted, setIsNotificationsAccepted] = useState(false);

  useEffect(() => {
    if (getNotificationPermissions) {
      getNotificationPermissions((res: boolean) => setIsNotificationsAccepted(res));
    }
  }, []);

  return (
    <Wrapper ai="flex-start">
      <Margin marginTop="md">
        <Wrapper fd="row">
          <MarginColumn />
          {isWeb && (
            <Text fontType="body" style={{ textAlign: 'left', color: COLORS.WHITE }}>
              {i18n.t('notificationInformation')}
            </Text>
          )}
          {!isWeb && (
            <Text
              fontType="body"
              style={{ textAlign: 'left', color: COLORS.WHITE, paddingRight: isIOS ? 40 : 0 }}
            >
              {i18n.t('nativeNotificationInformation', {
                isNotificationAccepted: isNotificationAccepted ? 'til' : 'fra',
              })}
            </Text>
          )}
          <MarginColumn />
        </Wrapper>
      </Margin>
    </Wrapper>
  );
};

export default NotificationInformation;
