import React from 'react';
import { useTheme } from 'styled-components/native';
import { Wrapper, Text, Button } from '@lendticket/ui/components/atoms';

const HEIGHT = 50;

interface DropdownProps {
  data: any;
  onPressDestination: (destination: any) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ data, onPressDestination }) => {
  const { settings, deviceSizes } = useTheme();
  const { COLORS, SPACINGS } = settings;
  const { isMobile } = deviceSizes;

  return (
    <>
      {data?.destinations?.length > 0 && (
        <Wrapper jc="center" ai="flex-start" fd="column" bc={COLORS.BACKGROUND_LIGHT_ORANGE}>
          {data.destinations.map((destination: string, i: number) => (
            <Button
              jc="center"
              ai="flex-start"
              h={HEIGHT}
              key={`${destination}-${i}`}
              onPress={() => onPressDestination(destination)}
              parentStyle={{ paddingLeft: SPACINGS.md, width: '100%' }}
            >
              <Text
                fontType={isMobile ? 'body' : 'h4'}
                style={{ textAlign: 'left', color: COLORS.WHITE }}
              >
                {destination}
              </Text>
            </Button>
          ))}
        </Wrapper>
      )}
    </>
  );
};

export default Dropdown;
