import React, { useEffect } from 'react';
import { useTheme } from 'styled-components/native';
import Animated, { withTiming, useSharedValue, useAnimatedStyle } from 'react-native-reanimated';
import { Wrapper, Text } from '@lendticket/ui/components/atoms';
import { useI18n } from '@lendticket/app/src/hooks/i18n';

const HEIGHT = 25;

interface SelectedPeriodProps {
  periodStart: string;
  periodEnd: string;
  i: number;
}

const SelectedPeriod: React.FC<SelectedPeriodProps> = ({ periodStart, periodEnd, i }) => {
  const i18n = useI18n();
  const { settings } = useTheme();
  const { COLORS } = settings;
  const translationY = useSharedValue(20);

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [
      {
        translateY: translationY.value,
      },
    ],
  }));

  useEffect(() => {
    translationY.value = withTiming(0, { duration: 250 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Animated.View style={animatedStyle}>
      <Wrapper h={HEIGHT} ai="flex-start">
        <Text fontType="body" style={{ textAlign: 'left', color: COLORS.WHITE }}>
          {i18n.t('calendarPeriod', { var1: i + 1 })}
          {`${periodStart} - ${periodEnd}`}
        </Text>
      </Wrapper>
    </Animated.View>
  );
};

export default SelectedPeriod;
