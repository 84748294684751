import React from 'react';
import { Platform, Text as TextRN } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Wrapper, Text, Margin } from '@lendticket/ui/components/atoms';
import { Icon } from '@lendticket/ui/components/molecules';

const isWeb = Platform.OS === 'web';
const FONT_WEIGHT_DESKTOP = 'bold';
const DOT_WIDTH = 30;
const ICON_DIMENSIONS = 35;
const ICON_DIMENSIONS_MOBILE = 30;

interface BottomLabelMessageProps {
  latestMessage: string;
  isMessageNew?: boolean;
}

const BottomLabelMessage: React.FC<BottomLabelMessageProps> = ({ isMessageNew, latestMessage }) => {
  const { settings, deviceSizes } = useTheme();
  const { isMobile } = deviceSizes;
  const { COLORS, SPACINGS } = settings;
  const styleWeb = isWeb ? { paddingTop: SPACINGS.sm, paddingBottom: SPACINGS.sm } : {};

  return (
    <>
      {isMessageNew ? (
        <Wrapper jc="center" ai="center" style={{ width: DOT_WIDTH }}>
          <Icon
            name="dot"
            fill={COLORS.BACKGROUND_PRIMARY}
            height={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS}
            width={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS}
          />
        </Wrapper>
      ) : (
        <Margin marginLeft="md" />
      )}
      <Wrapper
        f={1}
        ai="flex-start"
        jc="center"
        h={!isWeb ? ICON_DIMENSIONS_MOBILE : undefined}
        style={styleWeb}
      >
        <TextRN numberOfLines={1}>
          <Text
            fontType={isMobile ? 'subtitle' : 'body'}
            style={{
              fontWeight: isMobile ? undefined : FONT_WEIGHT_DESKTOP,
              color: COLORS.TEXT_LIGHT_PURPLE,
            }}
          >
            {latestMessage}
          </Text>
        </TextRN>
      </Wrapper>
      {/* <Wrapper fd="row" h={BOTTOM_CARD_HEIGHT} style={{ position: 'absolute', flex: 1 }}>
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          locations={[0, 0.75, 0.93, 0.98, 1]}
          colors={[
            'rgba(255, 255, 255, 0)',
            'rgba(255, 255, 255, 0)',
            'rgba(255, 255, 255, 0)',
            COLORS.WHITE,
            COLORS.WHITE,
          ]}
          style={{
            flex: 1,
            height: BOTTOM_CARD_HEIGHT,
          }}
        />
      </Wrapper> */}
    </>
  );
};

export default BottomLabelMessage;
