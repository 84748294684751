import React from 'react';
import styledWeb from 'styled-components';
import { Margin } from '@lendticket/ui/components/atoms';

const DIMENSIONS = 6;

interface CustomScrollViewProps {
  horizontal?: boolean;
  children: React.ReactNode;
}

const WebScrollView = styledWeb.div<{ horizontal?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  overflow-x: ${({ horizontal }) => (horizontal ? 'auto' : 'hidden')};
  overflow-y: ${({ horizontal }) => (horizontal ? 'hidden' : 'auto')};
  padding-bottom: 12px;

  &::-webkit-scrollbar {
    width: ${DIMENSIONS}px;
    height: ${DIMENSIONS}px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0,0,0,0.125);
    border-radius: 4px;
  }
  
  &::-webkit-box-shadow {
    inset 0 0 5px rgba(0,0,0,0.125);
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: ${({ theme }) => theme.settings.COLORS.BACKGROUND_LIGHT};
  }
`;

const CustomScrollView: React.FC<CustomScrollViewProps> = ({ horizontal, children }) => (
  <WebScrollView horizontal={horizontal}>{children}</WebScrollView>
);

export default CustomScrollView;
