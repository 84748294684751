import React, { useCallback, useMemo, useEffect } from 'react';
import { useTheme } from 'styled-components/native';
import { Wrapper, Margin } from '@lendticket/ui/components/atoms';
import BouncyCheckbox from 'react-native-bouncy-checkbox';
import { Icon, LabelAndDescription } from '@lendticket/ui/components/molecules';
import { swiperButtonHandler } from '@lendticket/ui/components/organisms/SwiperButton/handler';
import { useI18n } from '@lendticket/app/src/hooks/i18n';

const BORDER_WIDTH = 3;
const PADDING_BOTTOM = 10;
const ICON_DIMENSIONS = 14;
const FONT_SIZE = 16;

interface ICheckboxComponent {
  id: string;
  title: string;
}

interface TicketTypesAndPaymentProps {
  isLendingOutSelected: boolean;
  toggleTicketTypesAndPayment: any;
  setToggleTicketTypesAndPayment: (updateParams: any) => void;
}

const generateToggleState = (array: ICheckboxComponent[], key: string, value: boolean) => {
  if (!array) {
    return [];
  }

  const isLendingOutSelected = swiperButtonHandler.getIsLendingOutTicketSelected();
  const initialValue = { ticket: {}, profile: {} };
  return array.reduce((obj, item: any) => {
    return item[key] && isLendingOutSelected
      ? {
          ticket: {
            ...obj.ticket,
            [item[key].replace(/\s/, '')]: value,
          },
          profile: {
            ...obj.profile,
            [item[key].replace(/\s/, '')]: value,
          },
        }
      : obj;
  }, initialValue as any);
};

const TicketTypesAndPayment: React.FC<TicketTypesAndPaymentProps> = ({
  isLendingOutSelected,
  toggleTicketTypesAndPayment,
  setToggleTicketTypesAndPayment,
}) => {
  const i18n = useI18n();
  const { settings } = useTheme();
  const { COLORS } = settings;

  const itemList = useMemo(
    () => [
      { id: 'isStudent', title: i18n.t('ticketTypeAndPaymentOptionsIsStudent') },
      { id: 'isAdult', title: i18n.t('ticketTypeAndPaymentOptionsIsAdult') },
      { id: 'isFirstClass', title: i18n.t('ticketTypeAndPaymentOptionsIsFirstClass') },
      { id: 'isIncludingMetro', title: i18n.t('ticketTypeAndPaymentOptionsIncludingMetro') },
      { id: 'isPayableWithMobilePay', title: i18n.t('cardInfoMobilePay') },
      { id: 'isPayableWithSwish', title: i18n.t('cardInfoSwish') },
    ],
    [i18n],
  );

  const handleToggleState = useCallback(
    (item: ICheckboxComponent) => {
      const updateParams = isLendingOutSelected
        ? {
            ticket: {
              ...toggleTicketTypesAndPayment.ticket,
              [item.id]: !toggleTicketTypesAndPayment.ticket[item.id],
            },
          }
        : {
            profile: {
              ...toggleTicketTypesAndPayment.profile,
              [item.id]: !toggleTicketTypesAndPayment.profile[item.id],
            },
          };

      setToggleTicketTypesAndPayment({
        ...toggleTicketTypesAndPayment,
        ...updateParams,
      });
    },
    [isLendingOutSelected, toggleTicketTypesAndPayment, setToggleTicketTypesAndPayment],
  );

  useEffect(() => {
    setToggleTicketTypesAndPayment(generateToggleState(itemList, 'id', false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper ai="flex-start" jc="flex-start">
      <LabelAndDescription
        title={i18n.t('ticketTypeAndPaymentOptionsTitle')}
        description={i18n.t('ticketTypeAndPaymentOptionsDescription')}
      />
      <Margin marginTop="md" />
      {itemList.map(({ id, title }) => (
        <BouncyCheckbox
          disableBuiltInState
          fillColor={COLORS.BACKGROUND_LIGHT_ORANGE}
          useNativeDriver={false}
          innerIconStyle={{ borderWidth: BORDER_WIDTH }}
          isChecked={
            isLendingOutSelected
              ? toggleTicketTypesAndPayment.ticket[id]
              : toggleTicketTypesAndPayment.profile[id]
          }
          key={id}
          onPress={() => handleToggleState({ id, title })}
          style={{ paddingBottom: PADDING_BOTTOM }}
          text={title}
          iconComponent={
            isLendingOutSelected && toggleTicketTypesAndPayment.ticket[id] ? (
              <Icon
                name="check"
                height={ICON_DIMENSIONS}
                width={ICON_DIMENSIONS}
                fill={COLORS.WHITE}
              />
            ) : !isLendingOutSelected && toggleTicketTypesAndPayment.profile[id] ? (
              <Icon
                name="check"
                height={ICON_DIMENSIONS}
                width={ICON_DIMENSIONS}
                fill={COLORS.WHITE}
              />
            ) : null
          }
          textStyle={{
            fontFamily: 'Hubot-Sans',
            fontSize: FONT_SIZE,
            overflow: 'hidden',
            color: COLORS.WHITE,
            fontWeight: 'bold',
            textAlign: 'left',
            width: '100%',
            textDecorationLine: 'none',
          }}
        />
      ))}
    </Wrapper>
  );
};

export default TicketTypesAndPayment;
