import { useEffect, useContext } from 'react';
import { SearchContext } from 'contexts';
import { swiperButtonHandler } from '@lendticket/ui/components/organisms/SwiperButton/handler';
import { useGetDerivedSearchResultStates } from '../useGetDerivedSearchResultStates';

interface UseHandleSearchChangesProps {
  setLiveTickets: (props: any) => void;
  setLiveProfiles: (props: any) => void;
  liveTickets: Record<string, any>;
  liveProfiles: Record<string, any>;
}

export const useHandleSearchChanges = ({
  setLiveTickets,
  setLiveProfiles,
  liveTickets,
  liveProfiles,
}: UseHandleSearchChangesProps) => {
  const { searchQueries } = useContext(SearchContext);
  const getDerivedSearchResultStates = useGetDerivedSearchResultStates();
  const isFindingTicketSelected = swiperButtonHandler.getIsFindingTicketSelected();

  const { searchResults, hasSearchChanges, setSearchToTrueWithNoResult, resetIsSearching } =
    getDerivedSearchResultStates({
      list: isFindingTicketSelected ? liveTickets.allTickets : liveProfiles.allProfiles,
      listToObserve: isFindingTicketSelected
        ? liveTickets.searchTickets
        : liveProfiles.searchProfiles,
      isSearching: isFindingTicketSelected ? liveTickets.isSearching : liveProfiles.isSearching,
      search: searchQueries,
    });

  return useEffect(() => {
    if (hasSearchChanges) {
      if (isFindingTicketSelected) {
        setLiveTickets({
          ...liveTickets,
          isSearching: searchResults.length > 0,
          searchTickets: searchResults,
          hasTickets:
            (Object.values(searchQueries).filter(item => item).length === 0 &&
              liveTickets.tickets.length > 0) ||
            searchResults.length > 0,
        });
      } else {
        setLiveProfiles({
          ...liveProfiles,
          isSearching: searchResults.length > 0,
          searchProfiles: searchResults,
          hasProfiles:
            (Object.values(searchQueries).filter(item => item).length === 0 &&
              liveProfiles.profiles.length > 0) ||
            searchResults.length > 0,
        });
      }
    } else if (setSearchToTrueWithNoResult) {
      if (isFindingTicketSelected) {
        setLiveTickets({
          ...liveTickets,
          isSearching: true,
          searchTickets: [],
          hasTickets: false,
        });
      } else {
        setLiveProfiles({
          ...liveProfiles,
          isSearching: true,
          searchProfiles: [],
          hasProfiles: false,
        });
      }
    } else if (resetIsSearching) {
      if (isFindingTicketSelected) {
        setLiveTickets({
          ...liveTickets,
          isSearching: false,
          searchTickets: [],
          hasTickets: liveTickets.tickets.length > 0,
        });
      } else {
        setLiveProfiles({
          ...liveProfiles,
          isSearching: false,
          searchProfiles: [],
          hasProfiles: liveProfiles.profiles.length > 0,
        });
      }
    }
  }, [
    liveProfiles,
    liveTickets,
    setLiveProfiles,
    setLiveTickets,
    hasSearchChanges,
    isFindingTicketSelected,
    resetIsSearching,
    searchResults,
    setSearchToTrueWithNoResult,
    searchQueries,
  ]);
};
