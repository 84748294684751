import React, { createContext } from 'react';
import { useSharedValue } from 'react-native-reanimated';

export const PageDimensionsContext = createContext<Record<string, any>>({});
export const { Provider: PageDimensionsProviderWrapper } = PageDimensionsContext;

interface PageDimensionsContextProps {
  children: React.ReactNode;
}

const PageDimensionsProvider: React.FC<PageDimensionsContextProps> = ({ children }) => {
  const headerHeight = useSharedValue(0);
  const contentHeight = useSharedValue(0);
  const footerHeight = useSharedValue(0);
  const extraHeight = useSharedValue(0);

  return (
    <PageDimensionsProviderWrapper
      value={{ headerHeight, contentHeight, footerHeight, extraHeight }}
    >
      {children}
    </PageDimensionsProviderWrapper>
  );
};

export default PageDimensionsProvider;
