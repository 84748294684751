import React, { useRef, createContext } from 'react';
import { useSharedValue } from 'react-native-reanimated';

export const GestureContext = createContext<Record<string, any>>({});
export const { Provider: GestureProviderWrapper } = GestureContext;

interface GestureContextProps {
  children: React.ReactNode;
}

const GestureProvider: React.FC<GestureContextProps> = ({ children }) => {
  const tapRef = useRef();
  const panRef = useRef();
  const nativeScrollRef = useRef();
  const scrollViewRef = useRef();
  const flatListRef = useRef();
  const isPanning = useSharedValue(false);
  const isScrolling = useSharedValue(false);

  return (
    <GestureProviderWrapper
      value={{
        flatListRef,
        isScrolling,
        scrollViewRef,
        tapRef,
        panRef,
        nativeScrollRef,
        isPanning,
      }}
    >
      {children}
    </GestureProviderWrapper>
  );
};

export default GestureProvider;
