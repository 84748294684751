import React, { useMemo } from 'react';
import { ContentPage } from '@lendticket/ui/components/templates';
import { useI18n } from 'hooks/i18n';

const getFeatures = (i18n: any) => [
  {
    title: i18n.t('upcomingFeatureBiometricLoginTitle'),
    svgName: 'biometric',
    text: i18n.t('upcomingFeatureBiometricLoginText'),
  },
  {
    title: i18n.t('upcomingFeatureSelectiveChatActivityTitle'),
    svgName: 'startChat',
    text: i18n.t('upcomingFeatureSelectiveChatActivityText'),
  },
  {
    title: i18n.t('upcomingFeaturePaymentModuleTitle'),
    svgName: 'payment',
    text: i18n.t('upcomingFeaturePaymentModuleText'),
  },
  {
    title: i18n.t('upcomingFeatureBeTheFirstTitle'),
    svgName: 'notification',
    text: i18n.t('upcomingFeatureBeTheFirstText'),
  },
];

const Upcoming: React.FC = () => {
  const i18n = useI18n();
  const features = useMemo(() => getFeatures(i18n), [i18n]);

  return <ContentPage features={features} />;
};

export default Upcoming;
