import React from 'react';
import { useTheme } from 'styled-components/native';
import { Wrapper, Text, Margin } from '@lendticket/ui/components/atoms';
import { Icon } from '@lendticket/ui/components/molecules';

const HEIGHT = 30;
const HEIGHT_DESKTOP = 30;
const WIDTH_MOBILE = 250;
const WIDTH = 300;
const WIDTH_WITH_ICON = 275;
const BORDER_RADIUS = 5;

interface LabelProps {
  textColor?: string;
  text: string;
  backgroundColor: string;
  svgName?: string;
  svgDimensions?: number;
}

const Label: React.FC<LabelProps> = ({
  svgName,
  svgDimensions,
  text,
  textColor,
  backgroundColor,
}) => {
  const { settings, deviceSizes } = useTheme();
  const { COLORS } = settings;
  const { isMobile } = deviceSizes;
  const derivedTextColor = textColor ?? COLORS.WHITE;
  const fontType = isMobile ? 'body' : 'h4';

  return (
    <Wrapper
      h={isMobile ? HEIGHT : HEIGHT_DESKTOP}
      w={svgName ? WIDTH_WITH_ICON : isMobile ? WIDTH_MOBILE : WIDTH}
      fd="row"
      bc={backgroundColor}
      style={{ borderRadius: BORDER_RADIUS }}
    >
      {svgName && <Margin marginLeft="m" />}
      <Wrapper f={1} jc={svgName ? 'flex-start' : 'center'} ai={svgName ? 'flex-start' : 'center'}>
        <Text fontType={fontType} style={{ color: derivedTextColor }}>
          {text}
        </Text>
      </Wrapper>
      {svgName && (
        <Margin horizontal="s">
          <Icon name={svgName} fill={textColor} height={svgDimensions} width={svgDimensions} />
        </Margin>
      )}
    </Wrapper>
  );
};

export default Label;
