import React from 'react';
import { Platform } from 'react-native';
import Animated, {
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
  useDerivedValue,
} from 'react-native-reanimated';

const isIOS = Platform.OS === 'ios';
const EXTRA_HEIGHT = 70;
const EXTRA_HEIGHT_IOS = 76;

interface AnimatedShowHideProps {
  shouldTransition: Animated.SharedValue<boolean | undefined>;
  addHeight?: Animated.SharedValue<boolean>;
  backgroundColor?: string;
  borders?: {
    borderBottomRightRadius?: number;
    borderBottomLeftRadius?: number;
  };
  parent?: React.ReactNode;
  children: React.ReactNode;
}

const AnimatedShowHide: React.FC<AnimatedShowHideProps> = ({ shouldTransition, children }) => {
  const opacity = useSharedValue(0);
  const display = useSharedValue<'flex' | 'none'>('none');

  const animatedStyle = useAnimatedStyle(() => ({
    display: display.value,
    opacity: opacity.value,
    width: '100%',
  }));

  useAnimatedReaction(
    () => shouldTransition,
    () => {
      display.value = shouldTransition.value ? 'flex' : 'none';
      opacity.value = shouldTransition.value ? 1 : 0;
    },
    [shouldTransition],
  );

  return <Animated.View style={animatedStyle}>{children}</Animated.View>;
};

export default AnimatedShowHide;
