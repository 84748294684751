import React from 'react';
import Animated, { useSharedValue } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { Wrapper } from '@lendticket/ui/components/atoms';
import { FullWrapperHeight, Gradient } from '@lendticket/ui/components/molecules';
import { TapGestureHandler, FooterOnboarding, Slider } from '@lendticket/ui/components/organisms';
import GestureProvider from '@lendticket/ui/containers/GestureProvider';

interface OnboardingSliderPageProps {
  navigateToLoginScreen: () => void;
  navigateToTicketsAndProfilesOverview: () => void;
  onClose: () => void;
  title: string;
  items: React.FC<{}>[];
  footerActionTexts: {
    textLeft: string;
    textRight: string;
  }[];
}

const OnboardingSliderPage: React.FC<OnboardingSliderPageProps> = ({
  items,
  footerActionTexts,
  navigateToLoginScreen,
  navigateToTicketsAndProfilesOverview,
}) => {
  const { settings } = useTheme();
  const { SPACINGS } = settings;
  const currentItem = useSharedValue(0);
  const translationX = useSharedValue(0);

  return (
    <SafeAreaProvider>
      <SafeAreaView>
        <Gradient />
        <Wrapper>
          <FullWrapperHeight
            isModal
            hasBottomTabBar={false}
            footer={
              <FooterOnboarding
                translationX={translationX}
                currentItem={currentItem}
                footerActionTexts={footerActionTexts}
                navigateToLoginScreen={navigateToLoginScreen}
                navigateToTicketsAndProfilesOverview={navigateToTicketsAndProfilesOverview}
              />
            }
          >
            <GestureProvider>
              <Wrapper f={1} ai="flex-start" mt={SPACINGS.md} mb={SPACINGS.md}>
                <TapGestureHandler>
                  <Animated.View style={{ flex: 1 }}>
                    <Slider items={items} translationX={translationX} currentItem={currentItem} />
                  </Animated.View>
                </TapGestureHandler>
              </Wrapper>
            </GestureProvider>
          </FullWrapperHeight>
        </Wrapper>
      </SafeAreaView>
    </SafeAreaProvider>
  );
};

export default OnboardingSliderPage;
