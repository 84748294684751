import React from 'react';
import Animated, {
  useAnimatedStyle,
  useAnimatedReaction,
  useSharedValue,
  withTiming,
  interpolate,
} from 'react-native-reanimated';
import { useKeyboardDimensions } from '@lendticket/ui/hooks';

interface HideOnKeyboardProps {
  invisible?: boolean;
  children: React.ReactNode;
}

const HideOnKeyboard: React.FC<HideOnKeyboardProps> = ({ invisible, children }) => {
  const animationClock = useSharedValue(0);
  const keyboardValues = useKeyboardDimensions();
  const animatedStyle = useAnimatedStyle(() => ({
    opacity: interpolate(animationClock.value, [0, 20], [1, invisible ? 0 : 0.1]),
  }));

  useAnimatedReaction(
    () => keyboardValues,
    res => {
      if (res.value.isVisible && animationClock.value === 0) {
        animationClock.value = withTiming(20, { duration: 150 });
      }

      if (!res.value.isVisible && animationClock.value === 20) {
        animationClock.value = withTiming(0, { duration: 150 });
      }
    },
    [keyboardValues],
  );

  return <Animated.View style={animatedStyle}>{children}</Animated.View>;
};

export default HideOnKeyboard;
