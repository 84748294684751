import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FindTicketsAndProfilesPage } from '@lendticket/ui/components/templates';
import Animated, {
  interpolate,
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import { useAppDispatch } from 'helpers/redux/store';
import { isWeb } from 'helpers/platform';
import { Text, Wrapper } from '@lendticket/ui/components/atoms';
import { swiperButtonHandler } from '@lendticket/ui/components/organisms/SwiperButton/handler';
import GestureProvider from '@lendticket/ui/containers/GestureProvider';
import styled, { useTheme } from 'styled-components/native';
import { useGenerateTickets } from './hooks/useGenerateTickets';
import { useGenerateProfiles } from './hooks/useGenerateProfiles';
import { searchActions, searchSelector } from 'reducers/slices/search';
import Overview from './Overview';
import PageDimensionsProvider from '@lendticket/ui/containers/PageDimensionsProvider';
import { useHandleSearchChanges } from './hooks/useHandleSearchChanges';
import { useAppSelector } from 'hooks/redux';
import { shallowEqual } from 'react-redux';
import { useSearchNatively } from './hooks/useSearchNatively';
import * as routeNames from 'constants/routeNames';
import { useNavigation } from '@react-navigation/native';
import { SearchContext } from 'contexts';
import { useI18n } from 'hooks/i18n';
import { loadingActions } from 'reducers/slices/loading';
import { useHeadlessTask } from '@lendticket/ui/hooks';

const ChildWrapper = styled.View<{ derivedWidth: number }>`
  flex-direction: ${isWeb ? 'column' : 'row'};
  align-items: flex-start;
  overflow: hidden;
  height: 100%;
  width: ${({ derivedWidth, theme }) => {
    const { settings, deviceSizes } = theme;
    const { isMobile } = deviceSizes;
    const { SPACINGS } = settings;

    return `${derivedWidth + (isMobile ? 0 : SPACINGS.xl)}px`;
  }};
  ${() => {
    if (!isWeb) {
      return 'flex: 1';
    }
  }}
`;

const FindTicketsAndProfiles: React.FC = () => {
  const i18n = useI18n();
  const { resetForm } = useContext(SearchContext);
  const [shouldSort, setShouldSort] = useState<boolean | undefined>();
  const translationX = useSharedValue(0);
  const swiperWidth = useSharedValue(0);
  const profilesDisplay = useSharedValue('none');
  const navigation = useNavigation();
  const { windowDimensions, deviceSizes, settings } = useTheme();
  const { windowWidth } = windowDimensions;
  const { CONSTANTS, SPACINGS } = settings;
  const { isMobile, isDesktop } = deviceSizes;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const derivedWidth = isMobile
    ? windowWidth - SPACINGS.md * 2
    : isDesktop
    ? DESKTOP_TABLET_WIDTH - SPACINGS.xxl * 2
    : DESKTOP_TABLET_WIDTH - SPACINGS.xxl * 2;
  const ticketPosition = isWeb ? { position: 'absolute ' } : {};
  const dispatch = useAppDispatch();
  const { isLoading } = useContext(SearchContext);
  const search = useAppSelector(searchSelector, shallowEqual);
  const searchNatively: any = useSearchNatively();

  const {
    liveTickets,
    cards: ticketCards,
    handleTicketUpdates,
    setLiveTickets,
    searchReset: ticketsSearchReset,
    onEndReached: ticketsOnEndReached,
    onSwipeAndOnPressAction: ticketsOnSwipeAndOnPressAction,
  } = useGenerateTickets();

  const {
    liveProfiles,
    cards: profileCards,
    handleProfileUpdates,
    setLiveProfiles,
    searchReset: profilesSearchReset,
    onEndReached: profilesOnEndReached,
    onSwipeAndOnPressAction: profilesOnSwipeAndOnPressAction,
  } = useGenerateProfiles();

  const animatedStyleLiveTickets = useAnimatedStyle((): any => ({
    ...ticketPosition,
    width: derivedWidth,
    opacity: interpolate(translationX.value, [0, swiperWidth.value / 2], [1, 0]),
    transform: [
      {
        translateX: interpolate(
          translationX.value,
          [0, swiperWidth.value / 1.5],
          [0, -derivedWidth],
        ),
      },
    ],
  }));

  const searchReset = useCallback(() => {
    const isFindingTicketSelected = swiperButtonHandler.getIsFindingTicketSelected();
    resetForm();
    return isFindingTicketSelected ? ticketsSearchReset() : profilesSearchReset();
  }, [profilesSearchReset, resetForm, ticketsSearchReset]);

  const animatedStyleLiveProfiles = useAnimatedStyle(() => ({
    width: derivedWidth,
    opacity: interpolate(translationX.value, [0, swiperWidth.value / 2], [0, 1]),
    transform: [
      {
        translateX: isWeb
          ? interpolate(translationX.value, [0, swiperWidth.value], [derivedWidth, 0])
          : interpolate(translationX.value, [0, swiperWidth.value], [0, -derivedWidth]),
      },
    ],
  }));

  const prematureStartLoader = useCallback(() => {
    dispatch(
      loadingActions.setLoadingContent({
        isLoading: true,
        hasBackgroundColor: true,
        indicator: 'circle',
      }),
    );
  }, [dispatch]);

  useAnimatedReaction(
    () => translationX,
    res => {
      if (isWeb) {
        if (swiperWidth.value > 0 && res.value >= 5 && profilesDisplay.value === 'none') {
          profilesDisplay.value = 'flex';
        } else if (res.value < 5 && profilesDisplay.value === 'flex') {
          profilesDisplay.value = 'none';
        }
      }
    },
    [translationX],
  );

  const updateData = useCallback(async () => {
    const isFindingTicketSelected = swiperButtonHandler.getIsFindingTicketSelected();
    isFindingTicketSelected ? await handleTicketUpdates() : await handleProfileUpdates();
  }, [handleProfileUpdates, handleTicketUpdates]);

  const sortList = useCallback(() => {
    setShouldSort(!shouldSort);
  }, [shouldSort]);

  const swiperCallback = useCallback(() => {
    swiperButtonHandler.updateIsFindingTicket();
  }, []);

  useHandleSearchChanges({ setLiveTickets, setLiveProfiles, liveTickets, liveProfiles });

  useEffect(() => {
    if (!isWeb && search?.startSearchNatively) {
      searchNatively({
        setLiveTickets,
        setLiveProfiles,
        liveTickets,
        liveProfiles,
      }).then(res => {
        if (res) {
          dispatch(searchActions.startSearchNatively(false));

          setTimeout(() => {
            isLoading.value = false;
            navigation.navigate(routeNames.DRAWER_AND_TABBAR, {
              screen: routeNames.TABS,
              params: { screen: routeNames.FIND_TICKETS_AND_PROFILES },
            });
          }, 100);
        }
      });
    }
  }, [
    dispatch,
    isLoading,
    liveProfiles,
    liveTickets,
    navigation,
    search,
    searchNatively,
    setLiveProfiles,
    setLiveTickets,
  ]);

  useHeadlessTask({
    matchRoute: routeNames.FIND_TICKETS_AND_PROFILES,
    handleHeadlessTask: () => {
      handleTicketUpdates();
    },
  });

  return (
    <GestureProvider>
      <PageDimensionsProvider>
        <FindTicketsAndProfilesPage
          animatedValues={{ translationX, swiperWidth }}
          swiperCallback={swiperCallback}
          updateData={updateData}
          searchReset={searchReset}
          sortList={sortList}
          shouldSort={shouldSort}
          title={i18n.t('findTicketOrOneWhoSearch')}
        >
          <ChildWrapper derivedWidth={derivedWidth}>
            {!liveTickets.isInitiated && (
              <Wrapper jc='center' ai='center'>
                <Text fontType={isWeb ? 'h5' : 'body'} style={{ color: 'white' }}>
                  {i18n.t('pleaseWaitFetchingTickets')}
                </Text>
              </Wrapper>
            )}
            <Animated.View style={animatedStyleLiveTickets}>
              <Overview
                updateData={updateData}
                cards={[
                  ...(typeof shouldSort === 'boolean'
                    ? ticketCards.sort((a: any, b: any) =>
                        shouldSort
                          ? a.periodStart.split('-').join('') - b.periodStart.split('-').join('')
                          : b.periodStart.split('-').join('') - a.periodStart.split('-').join(''),
                      )
                    : ticketCards),
                ]}
                hasCards={liveTickets.hasTickets}
                isSearching={liveTickets.isSearching}
                newCardIds={liveTickets.newTicketIds}
                onEndReached={ticketsOnEndReached}
                prematureStartLoader={prematureStartLoader}
                onSwipeAndOnPressAction={ticketsOnSwipeAndOnPressAction}
              />
            </Animated.View>
            <Animated.View style={animatedStyleLiveProfiles}>
              <Overview
                updateData={updateData}
                cards={profileCards}
                hasCards={liveProfiles.hasProfiles}
                isSearching={liveProfiles.isSearching}
                newCardIds={liveProfiles.newProfileIds}
                onEndReached={profilesOnEndReached}
                prematureStartLoader={prematureStartLoader}
                onSwipeAndOnPressAction={profilesOnSwipeAndOnPressAction}
              />
            </Animated.View>
          </ChildWrapper>
        </FindTicketsAndProfilesPage>
      </PageDimensionsProvider>
    </GestureProvider>
  );
};

export default FindTicketsAndProfiles;
