import React from 'react';
import { DefaultPage } from '@lendticket/ui/components/templates';
import { useI18n } from 'hooks/i18n';

const Information: React.FC = () => {
  const i18n = useI18n();

  return (
    <DefaultPage
      teaserTitle={i18n.t('onboardingInformationTitle')}
      teaserText={i18n.t('onboardingInformationText')}
      svgName="introduction"
    />
  );
};

export default Information;
