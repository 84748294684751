import React, { useState, useCallback, useEffect } from 'react';
import { changeLanguage } from 'i18next';
import { i18n } from 'helpers/i18n';
import { I18nProviderWrapper } from 'contexts';
import persisted from 'helpers/storage/persisted';

interface I18nProviderProps {
  children: React.ReactNode;
}

const I18nProvider: React.FC<I18nProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  const initializeTranslation = useCallback(async (): Promise<any> => {
    await i18n.init();
  }, []);

  const changeLanguageCallback = useCallback(async (language: string) => {
    changeLanguage(language).then(() => {
      persisted.setItem('language', language);
    });
  }, []);

  useEffect(() => {
    initializeTranslation().then(() => {
      setIsLoading(false);
    });
  }, [initializeTranslation]);

  return (
    <I18nProviderWrapper value={{ changeLanguage: changeLanguageCallback }}>
      {!isLoading && children}
    </I18nProviderWrapper>
  );
};

export default I18nProvider;
