import React from 'react';
import { useTheme } from 'styled-components/native';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { Wrapper, MarginColumn } from '@lendticket/ui/components/atoms';
import { FullWrapperHeight, Gradient } from '@lendticket/ui/components/molecules';

interface TranslationPageProps {
  children: React.ReactNode;
}

const TranslationPage: React.FC<TranslationPageProps> = ({ children }) => {
  const { deviceSizes, settings } = useTheme();
  const { CONSTANTS, SHADOWS } = settings;
  const { isMobile } = deviceSizes;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;

  return (
    <SafeAreaProvider>
      <SafeAreaView>
        <Gradient />
        <Wrapper>
          <FullWrapperHeight isModal hasBottomTabBar={false}>
            <Wrapper f={1} w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}>
              <Wrapper fd="row">
                <MarginColumn />
                {children}
                <MarginColumn />
              </Wrapper>
            </Wrapper>
          </FullWrapperHeight>
        </Wrapper>
      </SafeAreaView>
    </SafeAreaProvider>
  );
};

export default TranslationPage;
