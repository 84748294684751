import { useCallback, useContext } from 'react';
import * as routeNames from 'constants/routeNames';
import { navigationRef } from 'helpers/navigation/config';
import persisted from 'helpers/storage/persisted';
import { alertMessage } from 'helpers/alertMessage';
import api from 'reducers/slices/api';
import { NotificationContext } from 'contexts';

export const useHandleTicketDeletion = () => {
  const { removeNotifications } = useContext(NotificationContext);

  const [deleteLendProfile] = api.useDeleteLendProfileMutation();
  const [deleteLendTicket] = api.useDeleteLendTicketMutation();
  const [deleteChatByTicketId] = api.useDeleteChatByTicketIdMutation();
  const [deleteChatByProfileId] = api.useDeleteChatByProfileIdMutation();
  const [deleteChatByChatId] = api.useDeleteChatByChatIdMutation();

  return useCallback(async () => {
    const route = navigationRef.current?.getCurrentRoute();
    const { params } = route ?? {};
    const { ticketId, profileId, isLendProfile, chatId }: any = params ?? {};
    const derivedIsLendProfile = isLendProfile || typeof ticketId === 'undefined';
    const notifications = await persisted.getItem('notifications');

    if (notifications?.ticketId === ticketId) {
      removeNotifications(ticketId);
    }

    if (derivedIsLendProfile) {
      deleteLendProfile(profileId)
        .then((resDeleteLendProfile: any) => {
          if (resDeleteLendProfile.data.status === 200) {
            if (chatId) {
              deleteChatByChatId(chatId)
                .then((resDeleteChatByChatId: any) => {
                  if (resDeleteChatByChatId.data.status === 200) {
                    navigationRef.current?.goBack();
                  }
                })
                .catch((err: any) => {
                  alertMessage(err);
                });
            } else {
              deleteChatByProfileId(profileId)
                .then((resDeleteChatByProfileId: any) => {
                  if (resDeleteChatByProfileId.data.status === 200) {
                    navigationRef.current?.navigate(routeNames.DRAWER_AND_TABBAR, {
                      screen: routeNames.TABS,
                      params: {
                        screen: routeNames.FIND_TICKETS_AND_PROFILES,
                      },
                    });
                  }
                })
                .catch((err: any) => {
                  alertMessage(err);
                });
            }
          }
        })
        .catch((err: any) => {
          alertMessage(err);
        });
    } else {
      deleteLendTicket(ticketId)
        .then((resDeleteLendTicket: any) => {
          if (resDeleteLendTicket.data.status === 200) {
            if (chatId) {
              deleteChatByChatId(chatId)
                .then((resDeleteChatByChatId: any) => {
                  if (resDeleteChatByChatId.data.status === 200) {
                    navigationRef.current?.goBack();
                  }
                })
                .catch((err: any) => {
                  alertMessage(err);
                });
            } else {
              /* NOTE: I do not believe we enter this scope as chatId
                is always provided */
              deleteChatByTicketId(ticketId)
                .then((resDeleteChatByTicketId: any) => {
                  if (resDeleteChatByTicketId.data.status === 200) {
                    navigationRef.current?.goBack();
                  }
                })
                .catch((err: any) => {
                  alertMessage(err);
                });
            }
          }
        })
        .catch((err: any) => {
          alertMessage(err);
        });
    }
  }, [
    deleteChatByChatId,
    deleteChatByProfileId,
    deleteChatByTicketId,
    deleteLendProfile,
    deleteLendTicket,
    removeNotifications,
  ]);
};
