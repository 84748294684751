import { createSlice, PayloadAction, createEntityAdapter } from '@reduxjs/toolkit';
import { RootState } from '../../helpers/redux/store';

export interface LoadingState {
  isLoading: boolean;
  isCancelable?: boolean;
  hasBackgroundColor: boolean;
  indicator: string;
  text?: string;
}

export const initialState: LoadingState = {
  isLoading: false,
  isCancelable: false,
  hasBackgroundColor: false,
  indicator: 'circle',
  text: undefined,
};

export const modalAdapter = createEntityAdapter<LoadingState>();

export const { reducer: loadingReducer, actions: loadingActions } = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoadingContent: (state, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const loadingSelector = (state: RootState) => state.root.loading;
