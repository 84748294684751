import React from 'react';
import { useTheme } from 'styled-components/native';
import { Wrapper, Margin, Text } from '@lendticket/ui/components/atoms';
import { Icon } from '@lendticket/ui/components/molecules';

const ICON_DIMENSIONS = 30;
const ICON_DIMENSIONS_ARROW = 20;
const BORDER_TOP_RADIUS = 7;
const HEIGHT = 50;

interface SettingsRowProps {
  iconName: string;
  text: string;
  index: number;
}

const SettingsRow: React.FC<SettingsRowProps> = ({ iconName, text, index }) => {
  const { deviceSizes, settings } = useTheme();
  const { isMobile } = deviceSizes;
  const { COLORS, CONSTANTS, SHADOWS } = settings;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const shadow = isMobile ? SHADOWS.BOX_SHADOW_BOTTOM_SECONDARY : {};
  const borderTopStyle = {
    ...shadow,
    borderTopLeftRadius: BORDER_TOP_RADIUS,
    borderTopRightRadius: BORDER_TOP_RADIUS,
  };

  return (
    <Wrapper
      bc="white"
      fd="column"
      h={HEIGHT}
      jc="center"
      ai="center"
      w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}
      style={{
        ...(index === 0 ? borderTopStyle : {}),
      }}
    >
      <Wrapper fd="row">
        <Margin marginLeft="md" />
        <Icon
          name={iconName}
          fill={COLORS.BACKGROUND_PRIMARY}
          height={ICON_DIMENSIONS}
          width={ICON_DIMENSIONS}
        />
        <Margin marginLeft="md">
          <Text fontType="body" style={{ color: COLORS.TEXT_PURPLE }}>
            {text}
          </Text>
        </Margin>
        <Wrapper f={1} />
        <Wrapper f={1} ai="flex-start" style={{ transform: [{ rotate: '180deg' }] }}>
          <Icon
            name="arrowLeft"
            fill={COLORS.BACKGROUND_PRIMARY}
            height={ICON_DIMENSIONS_ARROW}
            width={ICON_DIMENSIONS_ARROW}
          />
        </Wrapper>
        <Margin marginRight="md" />
      </Wrapper>
    </Wrapper>
  );
};

export default SettingsRow;
