import React from 'react';
import { useTheme } from 'styled-components/native';
import Animated from 'react-native-reanimated';
import { MarginColumn, Text, Wrapper } from '@lendticket/ui/components/atoms';
import { SliderButton, FooterButton } from '@lendticket/ui/components/molecules';
import { useGetOnboardingFooterActions } from '@lendticket/ui/hooks';

const NUMBER_OF_SLIDER_BUTTONS = 3;

interface FooterOnboardingProps {
  footerActionTexts: { textLeft: string; textRight: string }[];
  translationX: Animated.SharedValue<number>;
  currentItem: Animated.SharedValue<number>;
  navigateToLoginScreen: () => void;
  navigateToTicketsAndProfilesOverview: () => void;
}

const FooterOnboarding: React.FC<FooterOnboardingProps> = ({
  translationX,
  currentItem,
  footerActionTexts,
  navigateToLoginScreen,
  navigateToTicketsAndProfilesOverview,
}) => {
  const { settings, deviceSizes } = useTheme();
  const { isMobile, isTablet } = deviceSizes;
  const { CONSTANTS, SHADOWS } = settings;
  const { TEXT_SHADOW } = SHADOWS;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const textStyle = { color: 'white', textAlign: 'center', textShadow: TEXT_SHADOW };
  const onboardingFooterActions = useGetOnboardingFooterActions({
    currentItem,
    navigateToLoginScreen,
    navigateToTicketsAndProfilesOverview,
  });

  return (
    <Wrapper
      fd="row"
      w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}
      style={{ paddingTop: 16, paddingBottom: 16 }}
    >
      {isTablet && <MarginColumn />}
      {footerActionTexts.map(({ textLeft }, i) => (
        <FooterButton
          key={`${textLeft}-${i}`}
          i={i}
          currentItem={currentItem}
          onPress={onboardingFooterActions[i].leftAction}
          isLeft
        >
          <Text fontType="h3" style={textStyle}>
            {textLeft}
          </Text>
        </FooterButton>
      ))}
      <Wrapper fd="row" f={1} jc="center" ai="center">
        {[...new Array(NUMBER_OF_SLIDER_BUTTONS)].map((_, i) => (
          <SliderButton
            key={`${_}-${i}`}
            translationX={translationX}
            currentItem={currentItem}
            i={i}
          />
        ))}
      </Wrapper>
      {footerActionTexts.map(({ textRight }, i) => (
        <FooterButton
          key={`${textRight}-${i}`}
          onPress={onboardingFooterActions[i].rightAction}
          currentItem={currentItem}
          i={i}
        >
          <Text fontType="h3" style={textStyle}>
            {textRight}
          </Text>
        </FooterButton>
      ))}
      {isTablet && <MarginColumn />}
    </Wrapper>
  );
};

export default FooterOnboarding;
