import React, { useMemo, useCallback, useEffect, useContext } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useAppDispatch } from 'helpers/redux/store';
import { searchActions } from 'reducers/slices/search';
import * as routeNames from 'constants/routeNames';
import { SearchPage } from '@lendticket/ui/components/templates';
import { useI18n } from '@lendticket/app/src/hooks/i18n';
import { isWeb } from 'helpers/platform';
import { SearchContext } from 'contexts';

const getFormData = (i18n: any, refs: any) => [
  {
    placeholder: i18n.t('placeholderFromExample'),
    name: 'from',
    ref: refs.from,
  },
  {
    placeholder: i18n.t('placeholderToExample'),
    name: 'to',
    ref: refs.to,
  },
  {
    placeholder: i18n.t('placeholderDateExample'),
    name: 'periodStart',
    ref: refs.periodStart,
  },
];

const getSelectableItems = (i18n: any) => [
  { id: 'isIncludingAllOfSkaene', title: i18n.t('searchIsIncludingAllOfSkaene') },
  { id: 'isStudent', title: i18n.t('searchIsStudent') },
  { id: 'isAdult', title: i18n.t('searchIsAdult') },
  { id: 'isFirstClass', title: i18n.t('searchIsFirstClass') },
  { id: 'isIncludingMetro', title: i18n.t('searchIsIncludingMetro') },
  { id: 'isPayableWithMobilePay', title: i18n.t('searchIsPayableWithMobilePay') },
  { id: 'isPayableWithSwish', title: i18n.t('searchIsPayableWithSwish') },
];

const Search: React.FC = (props: any) => {
  const i18n = useI18n();
  const {
    refs,
    searchQueries,
    animatedSearchQueries,
    resetForm,
    uiSelectionState,
    setSearchQueries,
    isLoading,
    jsSelectionState,
    setJsSelectionState,
  } = useContext(SearchContext);
  const dispatch = useAppDispatch();
  const formData = useMemo(() => getFormData(i18n, refs), [i18n, refs]);
  const selectableItems = useMemo(() => getSelectableItems(i18n), [i18n]);
  const navigation = useNavigation();

  /* NOTE: Dynamic approach towards resetting the values, so
  a dispatching event can be called from the outside */
  useEffect(() => {
    if (Object.values(searchQueries).length === 0 && Object.keys(jsSelectionState).length > 0) {
      resetForm();
    }
  }, [jsSelectionState, resetForm, searchQueries]);

  const onSearchChange = useCallback(
    (value: string | number | boolean, name: string) => {
      if (isWeb) {
        setSearchQueries({
          ...searchQueries,
          [name]:
            (typeof value === 'boolean' && !value) || (typeof value === 'string' && value === '')
              ? undefined
              : value,
        });
      } else {
        animatedSearchQueries.value = {
          ...animatedSearchQueries.value,
          [name]:
            (typeof value === 'boolean' && !value) || (typeof value === 'string' && value === '')
              ? undefined
              : value,
        };
      }
    },
    [animatedSearchQueries, searchQueries, setSearchQueries],
  );

  const onSearch = useCallback(() => {
    if (!isWeb) {
      isLoading.value = true;

      setSearchQueries(animatedSearchQueries.value);
      dispatch(searchActions.startSearchNatively(true));
    } else {
      navigation.navigate(routeNames.DRAWER_AND_TABBAR, {
        screen: routeNames.TABS,
        params: { screen: routeNames.FIND_TICKETS_AND_PROFILES },
      });
    }
  }, [animatedSearchQueries.value, isLoading, navigation, setSearchQueries, dispatch]);

  return (
    <SearchPage
      onResetSearchQueries={resetForm}
      onSearchChange={onSearchChange}
      setJsSelectionState={setJsSelectionState}
      jsSelectionState={jsSelectionState}
      uiSelectionState={uiSelectionState}
      onSearch={onSearch}
      formData={formData}
      selectableItems={selectableItems}
      isLoading={isLoading}
      {...props}
    />
  );
};

export default Search;
