import React from 'react';
import { useTheme } from 'styled-components/native';
import { Icon } from '@lendticket/ui/components/molecules';
import BouncyCheckbox from 'react-native-bouncy-checkbox';
import { useI18n } from '../../../../app/src/hooks/i18n';

const BORDER_WIDTH = 3;
const PADDING_BOTTOM = 10;
const ICON_DIMENSIONS = 14;
const FONT_SIZE = 16;

interface SelectAllOfSkaeneProps {
  isChecked: boolean;
  handleToggleState: () => void;
}

const SelectAllOfSkaene: React.FC<SelectAllOfSkaeneProps> = ({ isChecked, handleToggleState }) => {
  const i18n = useI18n();
  const { settings } = useTheme();
  const { COLORS } = settings;

  return (
    <BouncyCheckbox
      disableBuiltInState
      fillColor={COLORS.BACKGROUND_LIGHT_ORANGE}
      useNativeDriver={false}
      innerIconStyle={{ borderWidth: BORDER_WIDTH }}
      isChecked={isChecked}
      onPress={() => handleToggleState()}
      style={{ paddingBottom: PADDING_BOTTOM }}
      text={i18n.t('allOfSkaene')}
      iconComponent={
        isChecked && (
          <Icon name="check" height={ICON_DIMENSIONS} width={ICON_DIMENSIONS} fill={COLORS.WHITE} />
        )
      }
      textStyle={{
        fontFamily: 'Hubot-Sans',
        fontSize: FONT_SIZE,
        overflow: 'hidden',
        color: COLORS.WHITE,
        fontWeight: 'bold',
        textAlign: 'left',
        width: '100%',
        textDecorationLine: 'none',
      }}
    />
  );
};

export default SelectAllOfSkaene;
