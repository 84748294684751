import React, { useContext, useCallback, useState, useEffect } from 'react';
import styled from 'styled-components/native';
import i18next from 'i18next';
import { Wrapper, Text, Margin } from '@lendticket/ui/components/atoms';
import { Icon } from '@lendticket/ui/components/molecules';
import { Modal } from '@lendticket/ui/components/organisms';
import { TranslationProviderWrapper, I18nContext } from 'contexts';
import { isWeb } from 'helpers/platform';

const TRANSLATION_LANGUAGES = [
  { type: 'da', iconName: 'daFlag', language: 'Dansk' },
  { type: 'se', iconName: 'seFlag', language: 'Svenska' },
  { type: 'en', iconName: 'enFlag', language: 'English' },
];
const DEFAULT_SELECTED_LANGUAGE = 'se';
const ICON_DIMENSIONS = 32;
const NATIVE_LINE_HEIGHT = 25;
const ICON_DIMENSION_OFFSET = 5;

interface TranslationProviderProps {
  children: React.ReactNode;
}

const TouchableOpacity = styled.TouchableOpacity``;

const TranslationProvider: React.FC<TranslationProviderProps> = ({ children }) => {
  const [currentSelectedLanguage, setCurrentSelectedLanguage] = useState(DEFAULT_SELECTED_LANGUAGE);
  const { changeLanguage } = useContext(I18nContext);

  const [isVisible, setIsVisible] = useState(false);
  const lineHeight = isWeb ? {} : { lineHeight: NATIVE_LINE_HEIGHT };

  useEffect(() => {
    if (i18next.language !== currentSelectedLanguage) {
      setCurrentSelectedLanguage(i18next.language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = useCallback(() => {
    setIsVisible(false);
  }, []);

  return (
    <TranslationProviderWrapper
      value={{ currentSelectedLanguage, openTranslationModal: () => setIsVisible(true) }}
    >
      {children}
      <Modal isVisible={isVisible} closeModal={onClose} onClose={onClose}>
        <Wrapper fd="column" jc="flex-start" ai="flex-start">
          {TRANSLATION_LANGUAGES.map(({ type, iconName, language }, i) => (
            <TouchableOpacity
              key={`${type}_${i}`}
              onPress={() => {
                setCurrentSelectedLanguage(type);
                changeLanguage(type);
              }}
            >
              <Wrapper fd="row" style={{ opacity: currentSelectedLanguage === type ? 1 : 0.4 }}>
                <Wrapper
                  h={ICON_DIMENSIONS - ICON_DIMENSION_OFFSET}
                  w={ICON_DIMENSIONS - ICON_DIMENSION_OFFSET}
                >
                  <Icon
                    name={iconName}
                    height={ICON_DIMENSIONS - ICON_DIMENSION_OFFSET}
                    width={ICON_DIMENSIONS - ICON_DIMENSION_OFFSET}
                  />
                </Wrapper>
                <Margin marginLeft="m" />
                <Wrapper ai="flex-start">
                  <Text
                    fontType={isWeb ? 'h5' : 'body'}
                    style={{
                      color: '#000',
                      textAlign: 'center',
                      fontWeight: currentSelectedLanguage === type ? 'bold' : 'normal',
                      ...lineHeight,
                    }}
                  >
                    {language} {currentSelectedLanguage === type && '✓'}
                  </Text>
                </Wrapper>
              </Wrapper>
            </TouchableOpacity>
          ))}
        </Wrapper>
      </Modal>
    </TranslationProviderWrapper>
  );
};

export default TranslationProvider;
