import { createSlice, PayloadAction, createEntityAdapter } from '@reduxjs/toolkit';
import { RootState } from '../../helpers/redux/store';

export interface ProfilesState {
  profile?: object;
  profiles?: object;
  profileIds: string[];
}

export const initialState: ProfilesState = {
  profiles: undefined,
  profileIds: [],
};

export const profilesAdapter = createEntityAdapter<ProfilesState>();

export const { reducer: profilesReducer, actions: profileActions } = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    addProfiles: (_, action: PayloadAction<any>) => action.payload,
    setSelectedProfile: (state, action: PayloadAction<any>) => ({
      ...state,
      profile: action.payload,
    }),
    removeProfiles: () => initialState,
  },
});

export const profilesSelector = (state: RootState) => state.root.profiles;
