import React from 'react';
import { DefaultTheme as DefaultThemeNative, ThemeProvider } from 'styled-components/native';
import {
  DefaultTheme as DefaultThemeWeb,
  ThemeProvider as ThemeProviderWeb,
} from 'styled-components';
import { useResponsiveMeasures } from '@lendticket/ui/hooks/useResponsiveMeasures';
import * as settings from '@lendticket/ui/theme';
import { UIProviderWrapper } from '../../contexts';

interface Props {
  children: React.ReactNode;
}

const UIProvider: React.FC<Props> = ({ children }): React.ReactElement => {
  const { deviceSizes, windowDimensions } = useResponsiveMeasures();
  const theme = {
    settings,
    deviceSizes,
    windowDimensions,
  };

  return (
    <UIProviderWrapper value={{ deviceSizes }}>
      <ThemeProvider theme={theme as DefaultThemeNative}>
        <ThemeProviderWeb theme={theme as DefaultThemeWeb}>{children}</ThemeProviderWeb>
      </ThemeProvider>
    </UIProviderWrapper>
  );
};

export default UIProvider;
