import React, { useMemo } from 'react';
import { TextInput } from 'react-native';
import { Controller, FieldValues, Control } from 'react-hook-form';
import { Margin, Wrapper } from '@lendticket/ui/components/atoms';
import { InputField, InputFieldWithIcon } from '@lendticket/ui/components/molecules';
import { useAppSelector } from 'hooks/redux';
import { userSelector } from 'reducers/slices/user';
import { shallowEqual } from 'react-redux';
import { useI18n } from 'hooks/i18n';

const getFormData = (i18n: any) => [
  {
    placeholder: i18n.t('placeholderEmail'),
    svgName: 'email',
    name: 'email',
    type: 'email',
    editable: false,
  },
  {
    placeholder: i18n.t('placeholderName'),
    svgName: 'userName',
    name: 'name',
    type: 'name',
  },
  {
    placeholder: i18n.t('placeholderPassword'),
    svgName: 'password',
    name: 'password',
    type: 'password',
  },
  {
    placeholder: i18n.t('placeholderConfirmPassword'),
    svgName: 'repeatPassword',
    name: 'repeatPassword',
    type: 'password',
  },
  {
    placeholder: 'Facebook social id',
    svgName: 'facebook_small',
    name: 'socialUserIdFacebook',
    type: 'numeric',
  },
  {
    placeholder: 'Google social id',
    svgName: 'google_small',
    name: 'socialUserIdGoogle',
    type: 'numeric',
  },
];

interface UpdateUserFormProps {
  inputRef: React.RefObject<TextInput>;
  control: Control<FieldValues, any>;
}

const UpdateUserForm: React.FC<UpdateUserFormProps> = ({ inputRef, control }) => {
  const i18n = useI18n();
  const user = useAppSelector(userSelector, shallowEqual);
  const formData = useMemo(() => getFormData(i18n), [i18n]);

  return (
    <Wrapper>
      <Margin marginTop="md" />
      {formData.map(({ placeholder, editable = true, svgName, name, type }, i) => (
        <InputFieldWithIcon key={`${placeholder}-${i}`} svgName={svgName}>
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, onBlur } }) => {
              let keyboardType: any = 'default';
              const isInputFieldPassword = type === 'password';
              const isInputFieldEmail = type === 'email';

              if (isInputFieldEmail) {
                keyboardType = 'email-address';
              }

              /* NOTE: The property name is the only one we need
              to explicit reset on every render to ensure a fresh
              form */
              return (
                <InputField
                  inputRef={name === 'name' ? inputRef : null}
                  hasBorder
                  hasFullWidth
                  hasPaddingLeft={false}
                  multiline={false}
                  editable={editable}
                  isPassword={isInputFieldPassword}
                  keyboardType={keyboardType}
                  placeholder={user?.[name] ?? placeholder}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </InputFieldWithIcon>
      ))}
    </Wrapper>
  );
};

export default UpdateUserForm;
