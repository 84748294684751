import { useCallback } from 'react';
import { alertMessage } from 'helpers/alertMessage';
import { useI18n } from 'hooks/i18n';

export const useEmailValidation = () => {
  const i18n = useI18n();

  return useCallback(
    (data: any) => {
      const { email } = data ?? {};

      if (email === '' || !email) {
        alertMessage(i18n.t('alertPleaseProvideEmail'));
      } else if (!/^\S+@\S+\.\S+$/.test(email)) {
        alertMessage(i18n.t('alertPleaseProvideValidEmail'));
      } else {
        return true;
      }
    },
    [i18n],
  );
};
