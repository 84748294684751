import { useTranslation, UseTranslationResponse } from 'react-i18next';

const { DEFAULT_NAMESPACE } = require('helpers/i18n');

const defaultNamespaces = [DEFAULT_NAMESPACE];

export const useI18n: (namespaces?: string[]) => {
  t: (s: string, options?: Record<string, any>) => string;
} = (namespaces: string[] = []): UseTranslationResponse<any[], undefined> =>
  useTranslation(defaultNamespaces.concat(namespaces));
