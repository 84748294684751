import React, { useContext } from 'react';
import { Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { SwiperButton, BottomNavigationBar } from '@lendticket/ui/components/organisms';
import { Wrapper, MarginColumn, Text } from '@lendticket/ui/components/atoms';
import { FullWrapperHeight, Gradient, SearchBar } from '@lendticket/ui/components/molecules';
import Animated from 'react-native-reanimated';
import { PageDimensionsContext } from '@lendticket/ui/containers/PageDimensionsProvider';
import { useI18n } from '@lendticket/app/src/hooks/i18n';

const isWeb = Platform.OS === 'web';
const PADDING = 8;

export interface CardParams {
  img: string;
  from: string;
  createdByUserId: string;
  createdAt: number;
  provider: string;
  lendTime: string;
  price: string;
  id: string;
  to: string;
  periodStart: string;
  periodEnd: string;
}

interface FindTicketsAndProfilesPageProps {
  shouldSort?: boolean | undefined;
  animatedValues: {
    translationX: Animated.SharedValue<number>;
    swiperWidth: Animated.SharedValue<number>;
  };
  swiperCallback: () => void;
  updateData: () => void;
  searchReset: () => void;
  sortList: () => void;
  children: React.ReactNode;
}

const FindTicketsAndProfilesPage: React.FC<FindTicketsAndProfilesPageProps> = ({
  animatedValues,
  shouldSort,
  updateData,
  swiperCallback,
  searchReset,
  sortList,
  children,
}) => {
  const i18n = useI18n();
  const { extraHeight } = useContext(PageDimensionsContext);
  const { deviceSizes, settings } = useTheme();
  const { SPACINGS, CONSTANTS, COLORS } = settings;
  const { isMobile } = deviceSizes;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;

  return (
    <SafeAreaView>
      <Gradient />
      <FullWrapperHeight hasBottomTabBar={!isWeb} footer={isWeb && <BottomNavigationBar />}>
        <Wrapper f={1} w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}>
          <Wrapper
            onLayout={e => {
              if (isWeb) {
                extraHeight.value = e.nativeEvent.layout.height;
              }
            }}
            fd="row"
            ai="flex-start"
          >
            <MarginColumn />
            <Wrapper f={1} fd="column" jc="center" ai="flex-start">
              <SwiperButton
                svgNameLeft="ticket"
                svgNameRight="ticketProfile"
                textLeft={i18n.t('findTicket')}
                textRight={i18n.t('findLoanTaker')}
                animatedValues={animatedValues}
                swiperCallback={swiperCallback}
              />
              <Wrapper jc={isMobile ? 'flex-start' : 'center'}>
                <Text
                  fontType="body"
                  style={{ color: COLORS.WHITE, textAlign: 'center', padding: PADDING }}
                >
                  {i18n.t('deletionJob')}
                </Text>
              </Wrapper>
              <SearchBar
                shouldSort={shouldSort}
                sortList={sortList}
                searchReset={searchReset}
                updateData={updateData}
              />
            </Wrapper>
            <MarginColumn />
          </Wrapper>
          <Wrapper f={1} fd="row" ai="flex-start" mb={SPACINGS.md}>
            <MarginColumn />
            {children}
            <MarginColumn />
          </Wrapper>
        </Wrapper>
      </FullWrapperHeight>
    </SafeAreaView>
  );
};

export default FindTicketsAndProfilesPage;
