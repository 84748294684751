import { useCallback } from 'react';
import { RootState } from 'helpers/redux';
import { areArraysEqual } from 'helpers/areArraysEqual';

interface UseGetDerivedSearchResultsParams {
  list: { [key: string]: string }[];
  search: RootState['root']['search'];
}

interface useGetDerivedSearchResultStatesParams extends UseGetDerivedSearchResultsParams {
  listToObserve: { [key: string]: string }[];
  isSearching: boolean;
}

/* NOTE: Complex factory pattern */
const useGetDerivedSearchResults = () =>
  useCallback(
    ({ list, search }: UseGetDerivedSearchResultsParams) =>
      list.filter(item => {
        return Object.keys(search).every(key => {
          if (search[key] === undefined) {
            return true;
          }

          if (typeof search[key] === 'string') {
            return item?.[key]?.toLowerCase().includes(search[key].toLowerCase());
          }

          return item[key] === search[key];
        });
      }),
    [],
  );

/* NOTE: Observable pattern */
export const useGetDerivedSearchResultStates = () => {
  const getDerivedSearchResults = useGetDerivedSearchResults();

  return useCallback(
    ({ list, listToObserve, isSearching, search }: useGetDerivedSearchResultStatesParams) => {
      const hasSearchQueries = Object.values(search).filter(item => item).length > 0;

      if (!hasSearchQueries) {
        return { resetIsSearching: isSearching };
      }

      const results = getDerivedSearchResults({ list, search });

      return {
        searchResults: results,
        setSearchToTrueWithNoResult:
          results.length === 0 && listToObserve.length === 0 && hasSearchQueries && !isSearching,
        resetIsSearching: isSearching && !hasSearchQueries,
        hasSearchChanges: !areArraysEqual(
          results.map(({ id }) => id),
          listToObserve.map(({ id }) => id),
        ),
      };
    },
    [getDerivedSearchResults],
  );
};
