import React, { useMemo } from 'react';
import {
  createStackNavigator,
  TransitionPresets,
  CardStyleInterpolators,
} from '@react-navigation/stack';
import Header from 'containers/Header';
import * as routeNames from 'constants/routeNames';
import { isWeb, isIOS } from 'helpers/platform';
import Introduction from 'areas/Introduction';
import Login from 'areas/Login';
import Chat from 'areas/Chat';
import ForgotPasswordNavigator from 'areas/Home/ForgotPassword/ForgotPasswordNavigator';
import Edit from 'areas/Edit';
import Redirect from 'areas/Redirect';
import Loading from 'areas/Loading';
import Upcoming from 'areas/Home/Upcoming';
import Support from 'areas/Home/Support';
import PrivacyPolicy from 'areas/Home/PrivacyPolicy';
import UpdateUser from 'areas/Home/UpdateUser';
import ResetPassword from 'areas/Home/ForgotPassword/ResetPassword';
import Unauthorized from 'areas/Unauthorized';
import Drawer from './Drawer';
import { useI18n } from 'hooks/i18n';

const Stack = createStackNavigator();

const TransitionPreset = isIOS
  ? TransitionPresets.ModalPresentationIOS
  : TransitionPresets.FadeFromBottomAndroid;

const Routes: React.FC = () => {
  const i18n = useI18n();
  const modalSettings = useMemo(
    () => ({
      headerTransparent: true,
      headerShown: true,
      presentation: 'modal',
      animationEnabled: true,
      cardOverlayEnabled: true,
      ...TransitionPreset,
    }),
    [],
  );

  return (
    <Stack.Navigator
      initialRouteName={routeNames.LOADING}
      screenOptions={{
        gestureEnabled: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        header: (props: any) => <Header {...props} />,
        cardStyleInterpolator: isIOS
          ? CardStyleInterpolators.forVerticalIOS
          : CardStyleInterpolators.forFadeFromBottomAndroid,
        headerShown: false,
        animationEnabled: !isWeb,
        cardStyle: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Stack.Screen name={routeNames.LOADING} component={Loading} />
      <Stack.Screen name={routeNames.DRAWER_AND_TABBAR} component={Drawer} />
      <Stack.Group
        screenOptions={{
          headerTransparent: true,
          headerShown: true,
          presentation: 'card',
        }}
      >
        <Stack.Screen name={routeNames.LOGIN} component={Login} options={{ title: 'Login' }} />
      </Stack.Group>
      <Stack.Group
        screenOptions={{
          presentation: 'transparentModal',
        }}
      >
        <Stack.Screen name={routeNames.UNAUTHORIZED} component={Unauthorized} />
      </Stack.Group>
      <Stack.Group
        // @ts-expect-error bug in ts
        screenOptions={{
          ...modalSettings,
          gestureEnabled: false,
        }}
      >
        <Stack.Screen
          name={routeNames.ONBOARDING}
          component={Introduction}
          options={{ title: 'Lend Ticket' }}
        />
        <Stack.Screen
          name={routeNames.PRIVACY_POLICY}
          component={PrivacyPolicy}
          options={{ title: 'Privacy policy' }}
        />
        <Stack.Screen
          name={routeNames.RESET_PASSWORD}
          component={ResetPassword}
          options={{ title: 'Reset password' }}
        />
        <Stack.Screen
          name={routeNames.FORGOT_PASSWORD}
          component={ForgotPasswordNavigator}
          options={{ title: i18n.t('forgotPasswordSimple') }}
        />
      </Stack.Group>
      <Stack.Group
        // @ts-expect-error bug in ts
        screenOptions={{
          ...modalSettings,
          gestureEnabled: true,
        }}
      >
        <Stack.Screen name={routeNames.REDIRECT} component={Redirect} />
        <Stack.Screen name={routeNames.CHAT} component={Chat} options={{ title: 'Chat' }} />
        <Stack.Screen name={routeNames.EDIT} component={Edit} options={{ title: 'Edit' }} />
        <Stack.Screen
          name={routeNames.SUPPORT}
          component={Support}
          options={{ title: 'Support' }}
        />
        <Stack.Screen
          name={routeNames.UPCOMING}
          component={Upcoming}
          options={{ title: i18n.t('titleUpcoming') }}
        />
        <Stack.Screen
          name={routeNames.UPDATE_USER}
          component={UpdateUser}
          options={{ title: i18n.t('titleUpdateUser') }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default Routes;
