import { withSpring, runOnJS } from 'react-native-reanimated';
import { DEFAULT_SPRING_CONFIG } from '@lendticket/ui/constants';
import type { GestureParams } from '../';

export const onEnd = ({
  panningDirection,
  swiperCallback,
  translationX,
  swiperWidth,
  dragX,
  prevDragX,
}: GestureParams) => {
  'worklet';

  panningDirection.value = prevDragX.value > dragX.value ? 'left' : 'right';

  if (panningDirection.value === 'right') {
    if (swiperCallback) {
      runOnJS(swiperCallback)('right');
    }

    translationX.value = withSpring(swiperWidth.value, DEFAULT_SPRING_CONFIG);
  } else {
    if (swiperCallback) {
      runOnJS(swiperCallback)('left');
    }

    translationX.value = withSpring(0, DEFAULT_SPRING_CONFIG);
  }
};
