import React from 'react';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import styled from 'styled-components/native';
import { useGetDerivedAnimatedValues } from '@lendticket/ui/hooks/footer';
import { getButtonOnPress } from '@lendticket/ui/helpers/footer';

const BUTTON_HEIGHT = 12;
const BUTTON_WIDTH_INACTIVE = 30;
const BORDER_RADIUS = 30;
const OPACITY_COLOR = 'rgba(255,255,255,0.5)';
const DEFAULT_WIDTH = 30;

interface SliderButtonProps {
  translationX: Animated.SharedValue<number>;
  currentItem: Animated.SharedValue<number>;
  i: number;
}

const Button = styled.TouchableOpacity`
  border-radius: ${BORDER_RADIUS}px;
  margin-right: ${({ theme }) => theme.settings.SPACINGS.s}px;
  width: ${BUTTON_WIDTH_INACTIVE}px;
  height: ${BUTTON_HEIGHT}px;
  background-color: ${OPACITY_COLOR};
`;

const AnimatedSliderButton = Animated.createAnimatedComponent(Button);

const SliderButton: React.FC<SliderButtonProps> = ({ currentItem, translationX, i }) => {
  const animatedValues = useGetDerivedAnimatedValues({ translationX, i });
  const buttonOnPress = getButtonOnPress({ currentItem, i });

  const animatedStyle = useAnimatedStyle(() => ({
    width: animatedValues.value?.width ?? DEFAULT_WIDTH,
    backgroundColor: animatedValues.value?.backgroundColor ?? OPACITY_COLOR,
  }));

  return <AnimatedSliderButton onPress={buttonOnPress} style={animatedStyle} />;
};

export default SliderButton;
