import React, { useEffect, useCallback } from 'react';
import { useRoute, useNavigation } from '@react-navigation/native';
import * as routeNames from 'constants/routeNames';
import { useAppDispatch } from 'helpers/redux/store';
import { alertMessage } from 'helpers/alertMessage';
import { useUpdateUserSetAuthTokenAndRedirect } from 'areas/Login/hooks/useUpdateUserSetAuthTokenAndRedirect';
import { loadingActions } from 'reducers/slices/loading';
import { isWeb } from 'helpers/platform';
import { useI18n } from 'hooks/i18n';

const useResetNavigation = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();

  return useCallback(() => {
    dispatch(loadingActions.setLoadingContent({ isLoading: false }));
    navigation.navigate(routeNames.LOGIN);
  }, [navigation, dispatch]);
};

const useNativeRedirection = () => {
  const i18n = useI18n();
  const resetNavigation = useResetNavigation();
  const updateUserSetAuthTokenAndRedirect = useUpdateUserSetAuthTokenAndRedirect();
  const { params }: any = useRoute() ?? {};

  return useCallback(() => {
    const { status, type: errorType, socialUserId, token } = params ?? {};

    if (status === 'success' && token) {
      updateUserSetAuthTokenAndRedirect({ token });
    } else {
      if (errorType && socialUserId) {
        alertMessage(i18n.t('alertAUserWithThatEmailAlreadyExist'));
      } else if (errorType) {
        alertMessage(i18n.t('alertAUserWithThatEmailAlreadyExist'));
      } else {
        alertMessage(i18n.t('alertOops'));
      }

      resetNavigation();
    }
  }, [params, i18n, resetNavigation, updateUserSetAuthTokenAndRedirect]);
};

const Redirect: React.FC = () => {
  const i18n = useI18n();
  const nativeRedirection = useNativeRedirection();
  const route: any = useRoute() ?? {};

  useEffect(() => {
    if (route?.params?.platform.startsWith('app') || !isWeb) {
      nativeRedirection();
    } else {
      if (route?.params?.status === 'fail') {
        if (!global.opener) {
          alertMessage(i18n.t('alertClosePageAndTryAgain'));
        }

        global.opener?.postMessage(
          JSON.stringify({
            type: route?.params?.status,
            errorType: route?.params?.type,
            socialUserId: route?.params?.socialUserId,
          }),
          '*',
        );
      } else {
        if (!global.opener) {
          nativeRedirection();
        } else {
          global.opener?.postMessage(
            JSON.stringify({
              type: route?.params?.status,
              token: route?.params?.token,
              route,
            }),
            '*',
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Redirect;
