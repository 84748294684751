import React from 'react';
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native';
import Animated, {
  withTiming,
  useAnimatedReaction,
  useSharedValue,
  useAnimatedStyle,
  interpolateColor,
} from 'react-native-reanimated';

const BORDER_RADIUS = 16;

export interface NativeAlertProps {
  title?: string;
  message: string;
  visible: boolean;
  onConfirmButtonText?: string;
  onCloseButtonText?: string;
  onConfirm?: () => void;
  onClose?: () => void;
}

const NativeAlert: React.FC<NativeAlertProps> = ({
  title,
  message,
  visible,
  onConfirmButtonText,
  onCloseButtonText,
  onConfirm,
  onClose,
}) => {
  const animationClock = useSharedValue(0);
  const tranlationY = useSharedValue(50);
  const animatedStyleOverlay = useAnimatedStyle(() => ({
    flex: 1,
    backgroundColor: interpolateColor(
      animationClock.value,
      [0, 20],
      ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.5)'],
    ),
    justifyContent: 'center',
    alignItems: 'center',
  }));

  const aniamtedStyleModal = useAnimatedStyle(() => ({
    backgroundColor: 'rgba(238, 240, 238, 1)',
    borderRadius: BORDER_RADIUS,
    width: '75%',
    maxWidth: 500,
    alignItems: 'center',
    elevation: 5,
    transform: [
      {
        translateY: tranlationY.value,
      },
    ],
  }));

  useAnimatedReaction(
    () => visible,
    value => {
      animationClock.value = withTiming(value ? 20 : 0, { duration: 250 });
      tranlationY.value = withTiming(value ? 0 : 10, { duration: 200 });
    },
    [visible],
  );

  return visible ? (
    <View style={styles.wrapper}>
      <Animated.View style={animatedStyleOverlay}>
        <Animated.View style={aniamtedStyleModal}>
          <View style={styles.content}>
            {title && <Text style={styles.title}>{title}</Text>}
            <Text style={styles.message}>{message}</Text>
          </View>
          {(onConfirm || onClose) && (
            <View style={styles.buttons}>
              {onConfirm && (
                <TouchableOpacity style={[styles.button, styles.confirmButton]} onPress={onConfirm}>
                  <Text style={styles.confirmButtonText}>{onConfirmButtonText}</Text>
                </TouchableOpacity>
              )}
              {onClose && (
                <TouchableOpacity style={styles.button} onPress={onClose}>
                  <Text style={styles.cancelButtonText}>{onCloseButtonText}</Text>
                </TouchableOpacity>
              )}
            </View>
          )}
        </Animated.View>
      </Animated.View>
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  wrapper: {
    elevation: 3,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    padding: 20,
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    paddingBottom: 6,
    fontWeight: 'bold',
    marginBottom: 6,
    color: 'black',
    textAlign: 'center',
  },
  message: {
    textAlign: 'center',
    color: 'black',
  },
  buttons: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
  },
  button: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderTopWidth: 0.4,
    borderBottomWith: 0.4,
    padding: 6,
    borderColor: '#B0B2AF',
  },
  confirmButton: {},
  cancelButtonText: {
    fontWeight: 'normal',
    color: '#2196F3',
    fontSize: 16,
    textAlign: 'center',
  },
  confirmButtonText: {
    fontWeight: 'bold',
    color: '#2196F3',
    fontSize: 16,
    textAlign: 'center',
  },
});

export default NativeAlert;
