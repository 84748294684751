import React, { useMemo, Suspense, lazy, useState } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { Wrapper, Text, Margin } from '@lendticket/ui/components/atoms';
import { Icon, DefaultProfileImage } from '@lendticket/ui/components/molecules';
import { CardGesture } from '@lendticket/ui/components/organisms';
import BottomLabelMessage from './BottomLabelMessage';
import Details from './Details';
import { useSharedValue } from 'react-native-reanimated';
import { useI18n } from '@lendticket/app/src/hooks/i18n';
import AuthorizationBar from './AuthorizationBar';
import Tag from './Tag';
import moment from 'moment';
import type { CardParams } from '@lendticket/ui/components/templates/FindTicketsPage';
import type { ChatParams } from '@lendticket/ui/components/templates/MessagesPage';

const LazyDetailsInformation = lazy(() => import('./DetailsInformation'));

const isWeb = Platform.OS === 'web';
const MAIN_CARD_HEIGHT = 120;
const MAIN_CARD_HEIGHT_MOBILE = 80;
const EXTRA_CARD_HEIGHT = 20;
const SELECTOR_WIDTH = 80;
const ICON_DIMENSIONS = 35;
const ICON_DIMENSIONS_MOBILE = 25;
const IMAGE_DIMENSIONS = 120 / 1.5;
const IMAGE_DIMENSIONS_MOBILE = 120 / 2;
const FONT_WEIGHT_DESKTOP = 'bold';
const RIGHT_WRAPPER_WIDTH = 100;
const BORDER_RADIUS = 7;
const LATEST_MESSAGE_TOP_MOBILE = 8;
const LATEST_MESSAGE_TOP_DEFAULT = 12;
const LATEST_MESSAGE_RIGHT_MOBILE = -12;
const LATEST_MESSAGE_RIGHT_DEFAULT = 0;
const SHOW_DETAILS_DEGREES_CLOSED = 270;
export const BOTTOM_CARD_HEIGHT = 30;
export const TOTAL_CARD_HEIGHT = MAIN_CARD_HEIGHT + BOTTOM_CARD_HEIGHT;
export const TOTAL_CARD_HEIGHT_MOBILE = MAIN_CARD_HEIGHT_MOBILE + BOTTOM_CARD_HEIGHT;

export interface CardProps extends CardParams, ChatParams {
  hiddenCardText: string;
  isRouteMessages?: boolean;
  hasAnimation?: boolean;
  isLoggedInWithFacebook?: boolean;
  isLoggedInWithGoogle?: boolean;
  isLoggedInWithApple?: boolean;
  isMessageNew?: boolean;
  initials?: string;
  name?: string;
  isTicket?: boolean;
  isStudent?: boolean;
  isAdult?: boolean;
  isFirstClass?: boolean;
  isIncludingAllOfSkaene?: boolean;
  isIncludingMetro?: boolean;
  isPayableWithMobilePay?: boolean;
  isPayableWithSwish?: boolean;
  isMessageOverview?: boolean;
  favoriteInitials?: string;
  favoriteImg?: string;
  currentUserName?: string;
  isFavorite?: boolean;
  periodStart?: string;
  periodEnd?: string;
  from?: string;
  favoriteName?: string;
  to?: string;
  price?: string;
  provider?: string;
  lendFromTime?: string;
  lendTime?: string;
  ticket: any;
  profile: any;
  messages?: any;
  onPressAction: (id: string) => void;
  onSwipeAction: (id: string) => void;
}

const ImageWrapper = styled.Image`
  border-radius: 999px;
  height: ${({ theme }) =>
    theme.deviceSizes.isMobile ? IMAGE_DIMENSIONS_MOBILE : IMAGE_DIMENSIONS}px;
  width: ${({ theme }) =>
    theme.deviceSizes.isMobile ? IMAGE_DIMENSIONS_MOBILE : IMAGE_DIMENSIONS}px;
`;

const Card: React.FC<CardProps> = ({
  id,
  hiddenCardText,
  periodStart,
  periodEnd,
  from,
  to,
  provider,
  img,
  lendFromTime,
  lendTime,
  price,
  isStudent,
  isAdult,
  isIncludingMetro,
  isPayableWithMobilePay,
  isPayableWithSwish,
  name,
  messages,
  chatParticipantIds,
  latestMessageDate,
  latestMessage,
  isIncludingAllOfSkaene,
  isLoggedInWithFacebook,
  isLoggedInWithGoogle,
  isLoggedInWithApple,
  isFirstClass,
  isFavorite,
  currentUserName,
  favoriteImg,
  favoriteName,
  favoriteInitials,
  isMessageNew,
  initials,
  ticket,
  profile,
  onSwipeAction,
  onPressAction,
}) => {
  const shouldShowDetails = useSharedValue(false);
  const showDetailsDegrees = useSharedValue(SHOW_DETAILS_DEGREES_CLOSED);
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const i18n = useI18n();
  const isLendProfile = !!name;
  const isChat = latestMessage || chatParticipantIds?.length;
  const isTicket = !isLendProfile && !isChat;
  const { settings, deviceSizes } = useTheme();
  const { COLORS, SHADOWS } = settings;
  const { BOX_SHADOW } = SHADOWS;
  const { isMobile } = deviceSizes;
  const isAuthorizedThroughSocialId =
    isLoggedInWithFacebook ?? isLoggedInWithGoogle ?? isLoggedInWithApple;
  const mainCardHeight =
    (isMobile ? MAIN_CARD_HEIGHT_MOBILE : MAIN_CARD_HEIGHT) +
    (isAuthorizedThroughSocialId ? EXTRA_CARD_HEIGHT : 0);
  const boxShadow: Object = isWeb ? { boxShadow: BOX_SHADOW.LIGHT } : {};
  const fontType = isMobile ? 'subtitle' : 'h4';
  const fontStyle = isMobile ? {} : { fontWeight: FONT_WEIGHT_DESKTOP };
  const isCurrentUserFavorite = currentUserName === favoriteName;
  const derivedName = isCurrentUserFavorite || !isFavorite ? name : favoriteName;
  const derivedImg = isCurrentUserFavorite || !isFavorite ? img : favoriteImg;
  const derivedInitials = isCurrentUserFavorite || !isFavorite ? initials : favoriteInitials;
  const upperTitle = isFavorite ? derivedName : `${periodStart} - ${periodEnd}`;
  const latestMessageCreatedAt = messages?.[messages?.length - 1]?.createdAt;
  const latestMessageCreatedAtFormatted = latestMessageCreatedAt
    ? moment(latestMessageCreatedAt).format('MM-DD')
    : undefined;

  const derivedTicketOrProfileInformation = useMemo(() => {
    const derivedDataSet = profile?.length > 0 ? profile : ticket;

    return {
      isLookingForTicket: profile?.length > 0,
      provider: derivedDataSet?.[0] ? derivedDataSet[0].provider : provider,
      lendFromTime: derivedDataSet?.[0] ? derivedDataSet[0].lendFromTime : lendFromTime,
      lendTime: derivedDataSet?.[0] ? derivedDataSet[0].lendTime : lendTime,
      price: derivedDataSet?.[0] ? derivedDataSet[0].price : price,
      isStudent: derivedDataSet?.[0] ? derivedDataSet[0].isStudent : isStudent,
      isFirstClass: derivedDataSet?.[0] ? derivedDataSet[0].isFirstClass : isFirstClass,
      isTicket: derivedDataSet?.[0] ? derivedDataSet[0].isTicket : isTicket,
      isAdult: derivedDataSet?.[0] ? derivedDataSet[0].isAdult : isAdult,
      isIncludingMetro: derivedDataSet?.[0] ? derivedDataSet[0].isIncludingMetro : isIncludingMetro,
      isPayableWithMobilePay: derivedDataSet?.[0]
        ? derivedDataSet[0].isPayableWithMobilePay
        : isPayableWithMobilePay,
      isPayableWithSwish: derivedDataSet?.[0]
        ? derivedDataSet[0].isPayableWithSwish
        : isPayableWithSwish,
    };
  }, [
    profile,
    ticket,
    provider,
    lendFromTime,
    lendTime,
    price,
    isStudent,
    isFirstClass,
    isTicket,
    isAdult,
    isIncludingMetro,
    isPayableWithMobilePay,
    isPayableWithSwish,
  ]);

  return (
    <>
      <CardGesture
        id={id}
        isLendProfile={isLendProfile}
        onPressAction={onPressAction}
        onSwipeAction={onSwipeAction}
        hiddenText={hiddenCardText}
      >
        <Wrapper
          fd="column"
          style={{
            borderBottomRightRadius: isWeb && isFavorite ? BORDER_RADIUS : 0,
            borderBottomLeftRadius: isWeb && isFavorite ? BORDER_RADIUS : 0,
            ...boxShadow,
          }}
        >
          <Wrapper
            fd="row"
            bc={COLORS.WHITE}
            style={{
              borderTopRightRadius: BORDER_RADIUS,
              borderTopLeftRadius: BORDER_RADIUS,
              borderBottomRightRadius: isFavorite && !latestMessageDate ? BORDER_RADIUS : 0,
              borderBottomLeftRadius: isFavorite && !latestMessageDate ? BORDER_RADIUS : 0,
            }}
          >
            <Wrapper w={isMobile ? IMAGE_DIMENSIONS_MOBILE + 20 : IMAGE_DIMENSIONS + 40}>
              {derivedImg ? (
                <ImageWrapper source={{ uri: derivedImg }} />
              ) : (
                <DefaultProfileImage
                  height={isMobile ? IMAGE_DIMENSIONS_MOBILE : IMAGE_DIMENSIONS}
                  width={isMobile ? IMAGE_DIMENSIONS_MOBILE : IMAGE_DIMENSIONS}
                  text={derivedInitials}
                  backgroundColor={COLORS.TEXT_LIGHT_PURPLE}
                />
              )}
            </Wrapper>
            <Wrapper f={1} ai="flex-start">
              <Text fontType={fontType} style={{ color: COLORS.TEXT_LIGHT_PURPLE, ...fontStyle }}>
                {upperTitle}
              </Text>
              <Margin marginTop={isMobile ? '' : 's'}>
                <Text fontType={fontType} style={{ ...fontStyle, color: COLORS.TEXT_PURPLE }}>
                  {isFavorite
                    ? i18n.t('favorite')
                    : isIncludingAllOfSkaene
                    ? i18n.t('allOfSkaene')
                    : `${from} - ${to}`}
                </Text>
              </Margin>
            </Wrapper>
            <Wrapper
              w={RIGHT_WRAPPER_WIDTH}
              h={mainCardHeight - (isAuthorizedThroughSocialId ? EXTRA_CARD_HEIGHT : 0)}
            >
              {(isTicket || isLendProfile || isFavorite) && (
                <Tag
                  isFavorite={isFavorite}
                  text={isTicket ? i18n.t('loanTicket') : i18n.t('loanProfile')}
                />
              )}
              {latestMessageDate ? (
                <Wrapper w={SELECTOR_WIDTH} h={mainCardHeight} jc="center">
                  <Wrapper
                    fd="column"
                    ofy="auto"
                    h={mainCardHeight}
                    style={{
                      position: 'absolute',
                      top: isMobile ? LATEST_MESSAGE_TOP_MOBILE : LATEST_MESSAGE_TOP_DEFAULT,
                      right: isMobile ? LATEST_MESSAGE_RIGHT_MOBILE : LATEST_MESSAGE_RIGHT_DEFAULT,
                    }}
                  >
                    <Text fontType={fontType} style={{ color: COLORS.TEXT_PURPLE }}>
                      {latestMessageDate.hour}:{latestMessageDate.mins}
                    </Text>
                    <Text fontType={fontType} style={{ color: COLORS.TEXT_LIGHT_PURPLE }}>
                      {latestMessageCreatedAtFormatted}
                    </Text>
                  </Wrapper>
                </Wrapper>
              ) : (
                <Wrapper f={1} jc="center">
                  <TouchableOpacity>
                    <Icon
                      name="rightArrowRound"
                      fill={COLORS.BACKGROUND_LIGHT_ORANGE}
                      height={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS}
                      width={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS}
                    />
                  </TouchableOpacity>
                </Wrapper>
              )}
            </Wrapper>
          </Wrapper>
          {(latestMessage || chatParticipantIds?.length) && (
            <>
              <Wrapper h={1} bc="#EEF0EF" />
              <Wrapper
                fd="row"
                bc={COLORS.WHITE}
                style={{
                  borderBottomRightRadius: isFavorite ? BORDER_RADIUS : 0,
                  borderBottomLeftRadius: isFavorite ? BORDER_RADIUS : 0,
                }}
              >
                <BottomLabelMessage
                  isMessageNew={isMessageNew}
                  latestMessage={latestMessage ?? i18n.t('noMessages')}
                />
              </Wrapper>
            </>
          )}
          {isAuthorizedThroughSocialId && (
            <AuthorizationBar
              isLoggedInWithApple={isLoggedInWithApple}
              isLoggedInWithGoogle={isLoggedInWithGoogle}
              isLoggedInWithFacebook={isLoggedInWithFacebook}
            />
          )}
        </Wrapper>
      </CardGesture>
      {!isFavorite && (
        <>
          <Details
            isDetailsVisible={isDetailsVisible}
            showDetailsDegrees={showDetailsDegrees}
            shouldShowDetails={shouldShowDetails}
            onPress={() => setIsDetailsVisible(!isDetailsVisible)}
          />
          {isDetailsVisible && (
            <Suspense
              fallback={
                <Wrapper jc="center" ai="center" bc={COLORS.BACKGROUND_LIGHT}>
                  <Text fontType="body" style={{ color: COLORS.TEXT_LIGHT_PURPLE }}>
                    ...
                  </Text>
                </Wrapper>
              }
            >
              <LazyDetailsInformation {...derivedTicketOrProfileInformation} />
            </Suspense>
          )}
        </>
      )}
    </>
  );
};

export default Card;
