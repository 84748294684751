import React from 'react';
import { TouchableOpacity } from 'react-native';
import { StyledComponent } from 'styled-components';
import styled, { DefaultTheme } from 'styled-components/native';

interface IconWrapperProps {
  isLeft?: boolean;
  isFlexEnd?: boolean;
  isFlexStart?: boolean;
  children?: React.ReactNode;
  onPress: () => void;
}

export const Button: StyledComponent<
  typeof TouchableOpacity,
  DefaultTheme,
  { isLeft?: boolean; isFlexEnd?: boolean; isFlexStart?: boolean },
  never
> = styled.TouchableOpacity<{ isLeft?: boolean; isFlexEnd?: boolean; isFlexStart?: boolean }>`
  flex: 1;
  ${({ isLeft, isFlexStart, isFlexEnd, theme }) => {
    const { isMobile } = theme.deviceSizes;
    const flexStartOrEnd = isLeft ? 'flex-start' : 'flex-end';

    if (isFlexStart) {
      return `
        justify-content: center;
        align-items: flex-start;
      `;
    }

    if (isFlexEnd) {
      return `
        justify-content: center;
        align-items: flex-end;
      `;
    }

    return `
      justify-content: ${isMobile ? 'center' : flexStartOrEnd};
      align-items: ${isMobile ? 'center' : flexStartOrEnd};
    `;
  }}
`;

const IconWrapper: React.FC<IconWrapperProps> = ({
  onPress,
  isLeft,
  isFlexEnd,
  isFlexStart,
  children,
}) => (
  <Button isLeft={isLeft} isFlexStart={isFlexStart} isFlexEnd={isFlexEnd} onPress={onPress}>
    {children}
  </Button>
);

export default IconWrapper;
