import { useTheme } from 'styled-components/native';
import Animated, { useDerivedValue, interpolate, interpolateColor } from 'react-native-reanimated';

const OPACITY_COLOR = 'rgba(255,255,255,0.5)';

interface UseGetDerivedAnimatedValues {
  translationX: Animated.SharedValue<number>;
  i: number;
}

export const useGetDerivedAnimatedValues = ({ translationX, i }: UseGetDerivedAnimatedValues) => {
  'worklet';

  const { windowDimensions, settings } = useTheme();
  const { windowWidth } = windowDimensions;
  const { WHITE } = settings.COLORS;

  const animatedValues = useDerivedValue(() => {
    const firstInterval = translationX.value > -windowWidth * 2;
    const secondInterval = translationX.value > -windowWidth * 3;
    const thirdInterval = translationX.value > -windowWidth * 5;

    const firstCondition = firstInterval && translationX.value <= 0;
    const secondCondition = secondInterval && translationX.value <= -windowWidth;
    const thirdCondition = thirdInterval && translationX.value <= -windowWidth * 2;

    const firstInterpolationCondition = translationX.value > -windowWidth;
    const secondInterpolationCondition = translationX.value > -windowWidth * 2;
    const thirdInterpolationCondition = translationX.value > -windowWidth * 3;

    if (firstCondition && i === 0) {
      return {
        width: firstInterpolationCondition
          ? interpolate(translationX.value, [0, -windowWidth], [30, 50])
          : interpolate(translationX.value, [-windowWidth, -windowWidth * 2], [50, 30]),
        backgroundColor: firstInterpolationCondition
          ? interpolateColor(translationX.value, [0, -windowWidth], [OPACITY_COLOR, WHITE])
          : interpolateColor(
              translationX.value,
              [-windowWidth, -windowWidth * 2],
              [WHITE, OPACITY_COLOR],
            ),
      };
    }

    if (secondCondition && i === 1) {
      return {
        width: secondInterpolationCondition
          ? interpolate(translationX.value, [-windowWidth, -windowWidth * 2], [30, 50])
          : interpolate(translationX.value, [-windowWidth * 2, -windowWidth * 3], [50, 30]),
        backgroundColor: secondInterpolationCondition
          ? interpolateColor(
              translationX.value,
              [-windowWidth, -windowWidth * 2],
              [OPACITY_COLOR, WHITE],
            )
          : interpolateColor(
              translationX.value,
              [-windowWidth * 2, -windowWidth * 3],
              [WHITE, OPACITY_COLOR],
            ),
      };
    }

    if (thirdCondition && i === 2) {
      return {
        width: thirdInterpolationCondition
          ? interpolate(translationX.value, [-windowWidth * 2, -windowWidth * 3], [30, 50])
          : interpolate(translationX.value, [-windowWidth * 3, -windowWidth * 4], [50, 50]),
        backgroundColor: thirdInterpolationCondition
          ? interpolateColor(
              translationX.value,
              [-windowWidth * 2, -windowWidth * 3],
              [OPACITY_COLOR, WHITE],
            )
          : interpolateColor(
              translationX.value,
              [-windowWidth * 3, -windowWidth * 4],
              [WHITE, WHITE],
            ),
      };
    }
  }, [translationX]);

  return animatedValues;
};
