import React from 'react';
import { Wrapper, Text, Margin } from '@lendticket/ui/components/atoms';
import { useTheme } from 'styled-components/native';
import { SPACINGS } from '@lendticket/ui/theme';

const ICON_DIMENSIONS = 35;
const ICON_DIMENSIONS_MOBILE = 30;

interface PoliciesProps {
  items: {
    title: string;
    text: string;
  }[];
}

const Policies: React.FC<PoliciesProps> = ({ items }) => {
  const { settings, deviceSizes } = useTheme();
  const { isMobile } = deviceSizes;
  const { COLORS, CONSTANTS } = settings;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;

  return (
    <>
      {items.map(({ title, text }, i) => (
        <Wrapper
          mt={i === 0 ? 0 : SPACINGS.md}
          w={isMobile ? undefined : DESKTOP_TABLET_WIDTH - 2 * SPACINGS.xl}
          key={`${title}-${i}`}
        >
          <Margin marginLeft="md" />
          <Wrapper ai="flex-start">
            <Text
              fontType={isMobile ? 'h4' : 'h3'}
              style={{ textAlign: 'left', color: COLORS.WHITE }}
            >
              {title}
            </Text>
          </Wrapper>
          <Margin marginLeft="md" />
          <Wrapper ai="flex-start">
            <Text fontType="body" style={{ textAlign: 'left', color: COLORS.WHITE }}>
              {text}
            </Text>
          </Wrapper>
        </Wrapper>
      ))}
    </>
  );
};

export default Policies;
