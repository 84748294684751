import React, { useRef } from 'react';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Wrapper } from '@lendticket/ui/components/atoms';
import { Gradient, FullWrapperHeight } from '@lendticket/ui/components/molecules';
import { WebView } from '@lendticket/ui/components/organisms';

interface LoginPageProps {
  source: Record<string, any>;
  height?: number;
  width?: number;
}

const WebViewPage: React.FC<LoginPageProps> = ({ source, ...props }) => {
  const parentRef = useRef(null);
  const { deviceSizes, settings } = useTheme();
  const { SPACINGS, CONSTANTS } = settings;
  const { isMobile } = deviceSizes;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;

  return (
    <SafeAreaView>
      <Gradient />
      <View ref={parentRef} style={{ flex: 1 }}>
        <FullWrapperHeight hasBottomTabBar={false}>
          <Wrapper
            f={1}
            ai="flex-start"
            mb={SPACINGS.md}
            w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}
          >
            <WebView
              source={source}
              width={isMobile ? undefined : DESKTOP_TABLET_WIDTH}
              {...props}
            />
          </Wrapper>
        </FullWrapperHeight>
      </View>
    </SafeAreaView>
  );
};

export default WebViewPage;
