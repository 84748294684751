import React from 'react';
import { useTheme } from 'styled-components/native';
import { Wrapper, Text, Margin } from '@lendticket/ui/components/atoms';
import { Icon } from '@lendticket/ui/components/molecules';
import { Appearance } from '@lendticket/ui/components/organisms';

const ICON_WIDTH = 155;
const ICON_HEIGHT = 166;

interface NoContentProps {
  text: string;
  hasFullHeight?: boolean;
}

const NoContent: React.FC<NoContentProps> = ({ text }) => {
  const { settings } = useTheme();
  const { SPACINGS, COLORS } = settings;

  return (
    <Wrapper jc="center" fd="column" mt={SPACINGS.xxl} style={{ display: 'flex' }}>
      <Appearance>
        <Wrapper jc="center">
          <Icon name="lendTicketLogo" fill={COLORS.WHITE} height={ICON_HEIGHT} width={ICON_WIDTH} />
          <Margin marginTop="md">
            <Text fontType="h3" style={{ color: COLORS.WHITE, textAlign: 'center' }}>
              {text}
            </Text>
          </Margin>
        </Wrapper>
      </Appearance>
    </Wrapper>
  );
};

export default NoContent;
