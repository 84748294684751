import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withDelay,
  useAnimatedReaction,
} from 'react-native-reanimated';
import { DEFAULT_SPRING_CONFIG } from '@lendticket/ui/constants';

const isWeb = Platform.OS === 'web';
const isAndroid = Platform.OS === 'android';

interface AppearanceProps {
  delay?: number;
  resetAppearance?: boolean;
  isReady?: boolean;
  isFlex?: boolean;
  children: React.ReactNode;
}

const Appearance: React.FC<AppearanceProps> = ({
  delay = 0,
  isReady = true,
  isFlex = false,
  resetAppearance,
  children,
}) => {
  const flexStyle = !isWeb && isFlex ? { flex: 1 } : {};

  const translationY = useSharedValue(100);
  const animatedStyle = useAnimatedStyle(() => ({
    width: '100%',
    ...flexStyle,
    opacity: isAndroid ? 1 : interpolate(translationY.value, [0, 100], [1, 0]),
    transform: [
      {
        translateY: translationY.value,
      },
    ],
  }));

  useAnimatedReaction(
    () => resetAppearance,
    res => {
      if (typeof res === 'boolean') {
        translationY.value = 100;
        translationY.value = withDelay(delay, withSpring(0, DEFAULT_SPRING_CONFIG));
      }
    },
    [resetAppearance],
  );

  useEffect(() => {
    if (!resetAppearance && isReady) {
      translationY.value = withDelay(delay, withSpring(0, DEFAULT_SPRING_CONFIG));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return <Animated.View style={animatedStyle}>{children}</Animated.View>;
};

export default Appearance;
