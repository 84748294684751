import React, { Suspense, lazy, useState } from 'react';
import { Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Margin, Wrapper, MarginColumn, Text } from '@lendticket/ui/components/atoms';
import Animated, { useAnimatedStyle, interpolate } from 'react-native-reanimated';
import Destinations from './Destinations';
import Practical from './Practical';
import Calendar from './Calendar';
import TicketTypesAndPayment from './TicketTypesAndPayment';
import type { CreatePageFormProps } from './';
import ShowCalendarButton from './ShowCalendarButton';
import { LabelAndDescription } from '@lendticket/ui/components/molecules';
import { useI18n } from '@lendticket/app/src/hooks/i18n';

const LazyCalendar = lazy(() => import('./Calendar'));

const isWeb = Platform.OS === 'web';
const PADDING_BOTTOM = 80;

const CreatePageForm: React.FC<CreatePageFormProps> = ({
  control,
  selectedPeriods,
  animatedValues,
  datePickerDates,
  toggleTicketTypesAndPayment,
  isAllOfSkaeneSelected,
  setIsAllOfSkaeneSelected,
  setToggleTicketTypesAndPayment,
  onDestinationSearch,
  updateSelectedPeriods,
  setDatePickerDates,
}) => {
  const i18n = useI18n();
  const [showCalendars, setShowCalendars] = useState({
    showLendTicketCalendar: false,
    showLendProfileCalendar: false,
  });
  const { windowDimensions, deviceSizes, settings } = useTheme();
  const { windowWidth } = windowDimensions;
  const { CONSTANTS, SPACINGS } = settings;
  const { isMobile, isDesktop } = deviceSizes;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const derivedWidth = isMobile
    ? windowWidth - SPACINGS.md * 2
    : isDesktop
    ? DESKTOP_TABLET_WIDTH - SPACINGS.xl * 2
    : DESKTOP_TABLET_WIDTH - SPACINGS.xl * 2;
  const { translationX, swiperWidth } = animatedValues;

  const animatedStyleCreateTicket = useAnimatedStyle(() => ({
    position: 'absolute',
    height: '100%',
    marginLeft: isMobile ? SPACINGS.md : SPACINGS.xl,
    width: derivedWidth,
    opacity: interpolate(translationX.value, [0, swiperWidth.value / 2], [1, 0]),
    transform: [
      {
        translateX: interpolate(
          translationX.value,
          [0, swiperWidth.value / 1.5],
          [0, -derivedWidth],
        ),
      },
    ],
  }));

  const animatedStyleCreateTicketLender = useAnimatedStyle(() => ({
    height: '100%',
    width: derivedWidth,
    marginLeft: isMobile ? SPACINGS.md : SPACINGS.xl,
    opacity: interpolate(translationX.value, [0, swiperWidth.value / 2], [0, 1]),
    transform: [
      {
        translateX: interpolate(translationX.value, [0, swiperWidth.value], [derivedWidth, 0]),
      },
    ],
  }));

  return (
    <Wrapper f={1} p="relative" fd="row" style={{ height: '100%', width: '100%' }}>
      <Animated.View style={animatedStyleCreateTicket}>
        <Animated.ScrollView style={{ paddingBottom: PADDING_BOTTOM }}>
          <Margin marginTop="md" marginBottom="lg">
            <Destinations
              isAllOfSkaeneSelected={isAllOfSkaeneSelected}
              isChecked={isAllOfSkaeneSelected.ticket}
              setIsAllOfSkaeneSelected={setIsAllOfSkaeneSelected}
              isLendingOutSelected
              control={control}
              onDestinationSearch={onDestinationSearch}
            />
          </Margin>
          <Margin marginTop="md" marginBottom="lg">
            <Practical isLendingOutSelected hasProvider control={control} />
          </Margin>
          <Margin marginTop="md" marginBottom="lg">
            <TicketTypesAndPayment
              isLendingOutSelected
              toggleTicketTypesAndPayment={toggleTicketTypesAndPayment}
              setToggleTicketTypesAndPayment={setToggleTicketTypesAndPayment}
            />
          </Margin>
          {isWeb ? (
            <Calendar
              isLendingOutSelected
              selectedPeriods={selectedPeriods.ticket}
              updateSelectedPeriods={updateSelectedPeriods}
              setDatePickerDates={setDatePickerDates}
              datePickerDates={datePickerDates.ticket}
            />
          ) : (
            <>
              <LabelAndDescription
                title={i18n.t('createCalendarTitle')}
                description={i18n.t('createCalendarDescription')}
              />
              {!showCalendars.showLendTicketCalendar && (
                <ShowCalendarButton
                  onPress={() =>
                    setShowCalendars(() => ({
                      ...showCalendars,
                      showLendTicketCalendar: true,
                    }))
                  }
                />
              )}
              {showCalendars.showLendTicketCalendar && (
                <Suspense fallback={<Text fontType="body">Henter kalender ...</Text>}>
                  <LazyCalendar
                    isLendingOutSelected
                    selectedPeriods={selectedPeriods.ticket}
                    updateSelectedPeriods={updateSelectedPeriods}
                    setDatePickerDates={setDatePickerDates}
                    datePickerDates={datePickerDates.ticket}
                  />
                </Suspense>
              )}
            </>
          )}
        </Animated.ScrollView>
      </Animated.View>
      <Animated.View style={animatedStyleCreateTicketLender}>
        <Animated.ScrollView style={{ paddingBottom: PADDING_BOTTOM }}>
          <Margin marginTop="md" marginBottom="lg">
            <Destinations
              isAllOfSkaeneSelected={isAllOfSkaeneSelected}
              isChecked={isAllOfSkaeneSelected.profile}
              setIsAllOfSkaeneSelected={setIsAllOfSkaeneSelected}
              control={control}
              isLendingOutSelected={false}
              onDestinationSearch={onDestinationSearch}
            />
          </Margin>
          <Margin marginTop="md" marginBottom="lg">
            <TicketTypesAndPayment
              isLendingOutSelected={false}
              toggleTicketTypesAndPayment={toggleTicketTypesAndPayment}
              setToggleTicketTypesAndPayment={setToggleTicketTypesAndPayment}
            />
          </Margin>
          <Margin marginTop="md" marginBottom="lg">
            <Practical hasProvider={false} isLendingOutSelected={false} control={control} />
          </Margin>
          {isWeb ? (
            <Calendar
              isLendingOutSelected={false}
              selectedPeriods={selectedPeriods.profile}
              datePickerDates={datePickerDates.profile}
              updateSelectedPeriods={updateSelectedPeriods}
              onDestinationSearch={onDestinationSearch}
              setDatePickerDates={setDatePickerDates}
            />
          ) : (
            <>
              <LabelAndDescription
                title="Vælg en eller flere datoer"
                description="Du kan vælge én dato eller et datointerval."
              />
              {!showCalendars.showLendProfileCalendar && (
                <ShowCalendarButton
                  onPress={() =>
                    setShowCalendars(() => ({
                      ...showCalendars,
                      showLendProfileCalendar: true,
                    }))
                  }
                />
              )}
              {showCalendars.showLendProfileCalendar && (
                <Suspense fallback={<Text fontType="body">Henter kalender ...</Text>}>
                  <LazyCalendar
                    isLendingOutSelected={false}
                    selectedPeriods={selectedPeriods.profile}
                    datePickerDates={datePickerDates.profile}
                    updateSelectedPeriods={updateSelectedPeriods}
                    onDestinationSearch={onDestinationSearch}
                    setDatePickerDates={setDatePickerDates}
                  />
                </Suspense>
              )}
            </>
          )}
        </Animated.ScrollView>
      </Animated.View>
      <MarginColumn />
    </Wrapper>
  );
};

export default CreatePageForm;
