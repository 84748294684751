import { useCallback } from 'react';
import { useTheme } from 'styled-components/native';
import { isWeb } from 'helpers/platform';

interface PopupParams {
  url: string;
  onSuccess: (data: any) => void;
  onFail: (data: any) => void;
}

const getBrowserFeatures = ({ windowDimensions, width, height }: any) => {
  const { windowWidth, windowHeight } = windowDimensions;
  var left = windowWidth / 2 - width / 2;
  var top = windowHeight / 2 - height / 2;

  return `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`;
};

const tryParse = (data: any) => {
  try {
    JSON.parse(data);
    return true;
  } catch (err) {
    return false;
  }
};

export const usePopup = () => {
  const { windowDimensions } = useTheme();

  return useCallback(
    ({ url, onSuccess, onFail }: PopupParams) => {
      if (!isWeb) {
        return undefined;
      }

      const browserFeatures = getBrowserFeatures({ windowDimensions, width: 700, height: 700 });

      let handler: any;
      const popupWindow: any = window.open(url, 'Authentication', browserFeatures);

      const removeHandler = () => {
        window.removeEventListener('message', handler);
        popupWindow.close();
      };

      handler = (e: any) => {
        try {
          if (!tryParse(e.data)) {
            return;
          }
          const data = JSON.parse(e.data);
          const { type } = data;
          if (type === 'success' && onSuccess) {
            removeHandler();
            onSuccess(data);
          }

          if (type === 'fail' && onFail) {
            onFail(data);
          }

          removeHandler();
        } catch (s) {
          console.error(s);
        }
      };

      window.addEventListener('message', handler);
    },
    [windowDimensions],
  );
};
