import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import { useTheme } from 'styled-components/native';
import { Text, Wrapper } from '@lendticket/ui/components/atoms';
import { Icon } from '@lendticket/ui/components/molecules';

const MARGIN_TOP = 12;
const BORDER_RADIUS = 5;
const PADDING_VERTICAL = 6;
const ICON_DIMENSIONS = 35;
const ICON_DIMENSIONS_MOBILE = 25;

interface TagProps {
  text: string;
  isFavorite?: boolean;
}

const Tag: React.FC<TagProps> = ({ isFavorite, text }) => {
  const { settings, deviceSizes } = useTheme();
  const { isMobile } = deviceSizes;
  const { SHADOWS, COLORS } = settings;

  return isFavorite ? null : (
    // <Wrapper f={1} jc="center" ai="center">
    //   <Icon
    //     name="starWithFill"
    //     fill={COLORS.BACKGROUND_LIGHT_ORANGE}
    //     height={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS}
    //     width={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS}
    //   />
    // </Wrapper>
    <LinearGradient
      style={{
        marginTop: MARGIN_TOP,
        borderRadius: BORDER_RADIUS,
        paddingLeft: PADDING_VERTICAL,
        paddingRight: PADDING_VERTICAL,
        ...SHADOWS.BOX_SHADOW_BOTTOM,
      }}
      colors={[COLORS.BACKGROUND_PRIMARY, '#ECA4A0', COLORS.BACKGROUND_PRIMARY]}
      start={{ x: 0, y: 0.7 }}
    >
      <Text fontType={isMobile ? 'subtitle' : 'body'} style={{ color: COLORS.WHITE }}>
        {text}
      </Text>
    </LinearGradient>
  );
};

export default Tag;
