import React from 'react';
import { useTheme } from 'styled-components/native';
import FlashMessage from 'react-native-flash-message';
import { getTextStyle } from '@lendticket/ui/helpers';

const BORDER_RADIUS = 7;
const OPACITY = 0.95;

const FlashNotification: React.FC = () => {
  const theme = useTheme();
  const { settings } = theme;
  const { SPACINGS, COLORS } = settings;
  const textStyleBody = getTextStyle({ fontType: 'body', theme });

  return (
    <FlashMessage
      textStyle={{ color: COLORS.WHITE, ...textStyleBody }}
      style={{
        borderTopRightRadius: BORDER_RADIUS,
        borderTopLeftRadius: BORDER_RADIUS,
        opacity: OPACITY,
        paddingLeft: SPACINGS.md,
      }}
      position="bottom"
    />
  );
};

export default FlashNotification;
