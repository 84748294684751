import React, { useEffect } from 'react';
import styled from 'styled-components';

interface WebViewProps {
  source: Record<string, any>;
  height?: number;
  width?: number;
}

const IFrame = styled.iframe`
  flex: 1;
  border: none;
`;

const WebView: React.FC<WebViewProps> = ({ source, height, width }) => {
  const ref = React.useRef<any>();

  return (
    <IFrame id="test" ref={ref} key={source.uri} src={source.uri} height={height} width={width} />
  );
};

export default WebView;
