import React, { useContext, Dispatch, SetStateAction } from 'react';
import { Platform } from 'react-native';
import {
  PanGestureHandlerGestureEvent,
  PanGestureHandler as RNGHPangestureHandler,
} from 'react-native-gesture-handler';
import Animated, {
  useSharedValue,
  useAnimatedGestureHandler,
  withSpring,
  useAnimatedReaction,
} from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';
import { GestureContext } from '@lendticket/ui/containers/GestureProvider';

const isWeb = Platform.OS === 'web';

interface GestureHandlerProps {
  children: React.ReactNode;
  disallowSlideLeftOnFirstScreen?: boolean;
  maxNumberOfItems: number;
  translationX: Animated.SharedValue<number>;
  currentItem: Animated.SharedValue<number>;
  visibleIndexes: number[];
  itemWidth?: number;
  isEnabled?: boolean;
  disallowGestureHandlingOnFirstScreen?: boolean;
  setVisibleIndexes: Dispatch<SetStateAction<number[]>>;
}

interface AnimatedGHContext {
  [key: string]: number;
  startX: number;
  startY: number;
}

const DEFAULT_SPRING_CONFIG = {
  damping: 20,
  mass: 1,
  stiffness: 120,
  overshootClamping: false,
  restSpeedThreshold: 1,
  restDisplacementThreshold: 1,
};

const PanGestureHandler: React.FC<GestureHandlerProps> = ({
  children,
  isEnabled,
  currentItem,
  translationX,
  maxNumberOfItems,
  itemWidth,
  disallowSlideLeftOnFirstScreen,
  disallowGestureHandlingOnFirstScreen,
}) => {
  const { panRef, tapRef, nativeScrollRef, isPanning, isScrolling } = useContext(GestureContext);
  const dragX = useSharedValue(0);
  const prevDragX = useSharedValue(0);
  const isSlidingRight = useSharedValue(false);
  const isSlidingLeft = useSharedValue(false);

  const theme = useTheme();

  const { windowWidth } = theme.windowDimensions;
  const gestureWidth = itemWidth ?? windowWidth;

  useAnimatedReaction(
    () => ({ translationX, currentItem }),
    res => {
      if (!isPanning?.value) {
        if (res.translationX.value !== -(gestureWidth * res.currentItem.value)) {
          translationX.value = withSpring(
            -(gestureWidth * res.currentItem.value),
            DEFAULT_SPRING_CONFIG,
          );
        }
      }
    },
    [currentItem, translationX, gestureWidth],
  );

  const gestureHandler = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    AnimatedGHContext
  >({
    onStart: (_, ctx) => {
      ctx.startX = translationX.value;
    },
    onActive: (event, ctx) => {
      if (!isScrolling.value) {
        isPanning.value = true;
        prevDragX.value = translationX.value;
        dragX.value = ctx.startX + event.translationX;

        if (disallowSlideLeftOnFirstScreen && dragX.value >= 0) {
        } else {
          translationX.value = dragX.value;
        }

        if (prevDragX.value > translationX.value) {
          if (isSlidingRight.value === false) {
            isSlidingRight.value = true;
            isSlidingLeft.value = false;
          }
        } else {
          if (isSlidingLeft.value === false) {
            isSlidingLeft.value = true;
            isSlidingRight.value = false;
          }
        }
      }
    },
    onEnd: _ => {
      const dimensions = gestureWidth * currentItem.value;
      const translationRightTrigger = translationX.value <= -dimensions - 10;
      const translationLeftTrigger = translationX.value >= -dimensions + 10;
      isPanning.value = false;

      if (translationRightTrigger) {
        if (currentItem.value === maxNumberOfItems) {
          translationX.value = withSpring(
            -(gestureWidth * currentItem.value),
            DEFAULT_SPRING_CONFIG,
          );
        } else {
          currentItem.value = currentItem.value + 1;
          translationX.value = withSpring(
            -(gestureWidth * currentItem.value),
            DEFAULT_SPRING_CONFIG,
          );
        }
      } else {
        translationX.value = withSpring(0, DEFAULT_SPRING_CONFIG);
      }

      if (translationLeftTrigger) {
        if (currentItem.value > 0) {
          currentItem.value = currentItem.value - 1;

          translationX.value = withSpring(
            -(gestureWidth * currentItem.value),
            DEFAULT_SPRING_CONFIG,
          );
        } else {
          translationX.value = withSpring(0, DEFAULT_SPRING_CONFIG);
        }
      }
    },
  });

  return (
    <RNGHPangestureHandler
      ref={panRef}
      simultaneousHandlers={isWeb ? [nativeScrollRef, tapRef] : []}
      enabled={disallowGestureHandlingOnFirstScreen ? currentItem.value > 0 : isEnabled}
      onGestureEvent={gestureHandler}
    >
      <Animated.View style={{ flex: 1 }}>{children}</Animated.View>
    </RNGHPangestureHandler>
  );
};

export default PanGestureHandler;
