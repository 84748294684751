import { createRef } from 'react';
import { getPathFromState as origPFS, getStateFromPath as origSFP } from '@react-navigation/native';
import * as routeNames from 'constants/routeNames';
import { isWeb } from 'helpers/platform';
import type { NavigationState, ParamListBase, PartialState } from '@react-navigation/routers';
import type { NavigationContainerRef } from '@react-navigation/core/lib/typescript/src';

export const navigationRef = createRef<NavigationContainerRef<ParamListBase>>();

export const getPathFromState = (
  state: NavigationState | Omit<PartialState<NavigationState>, 'stale'>,
  config: any,
) => {
  const path = origPFS(state, config);
  return path.replace(`/${routeNames.DRAWER_AND_TABBAR}/${routeNames.TABS}/`, '/');
};

export const getStateFromPath = (path: string, config: any) => {
  const isPathDrawer = path.startsWith(`/${routeNames.DRAWER_AND_TABBAR}`);

  if (isPathDrawer) {
    const adjustedPath = isWeb
      ? path.replace(`/${routeNames.DRAWER_AND_TABBAR}/${routeNames.TABS}`, '')
      : path;

    return origSFP(adjustedPath, config);
  }

  return origSFP(path, config);
};
