import React from 'react';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import styled, { useTheme } from 'styled-components/native';
import { Wrapper, Text, Margin } from '@lendticket/ui/components/atoms';
import { Icon } from '@lendticket/ui/components/molecules';
import { useI18n } from '@lendticket/app/src/hooks/i18n';

const isWeb = Platform.OS === 'web';
const ICON_DIMENSIONS = 20;
const ICON_DIMENSIONS_EXTRA = 3;

interface SearchBarProps {
  updateData: any;
  shouldSort?: boolean | undefined;
  searchReset: () => void;
  sortList: () => void;
}

const TouchableOpacity = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SearchBar: React.FC<SearchBarProps> = ({ shouldSort, updateData, sortList, searchReset }) => {
  const i18n = useI18n();
  const { deviceSizes, settings } = useTheme();
  const navigation = useNavigation();
  const { CONSTANTS, SPACINGS, COLORS } = settings;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const { isMobile } = deviceSizes;

  return (
    <Wrapper
      jc="center"
      ai="flex-start"
      w={isMobile ? undefined : DESKTOP_TABLET_WIDTH - SPACINGS.xxl * 2}
    >
      <Wrapper fd="column">
        <Wrapper fd="row" mb={isMobile ? SPACINGS.s : SPACINGS.m}>
          <TouchableOpacity onPress={navigation.openDrawer}>
            <Text fontType={isWeb ? 'h4' : 'h5'} style={{ textAlign: 'left', color: COLORS.WHITE }}>
              {i18n.t('search')}
            </Text>
          </TouchableOpacity>
          <Margin horizontal="s">
            <Text fontType={isWeb ? 'h4' : 'h5'} style={{ textAlign: 'left', color: COLORS.WHITE }}>
              |
            </Text>
          </Margin>
          <TouchableOpacity
            onPress={searchReset}
            style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
          >
            <Text fontType={isWeb ? 'h4' : 'h5'} style={{ textAlign: 'left', color: COLORS.WHITE }}>
              {i18n.t('reset')}
            </Text>
            <Margin marginLeft="xs" />
          </TouchableOpacity>
          <Wrapper f={1} />
          <Margin marginRight="m" />
          <Margin marginRight="s">
            <Wrapper fd="row">
              <TouchableOpacity onPress={sortList}>
                <Icon
                  name={!shouldSort ? 'sortNewest' : 'sortOldest'}
                  fill={COLORS.WHITE}
                  height={ICON_DIMENSIONS + ICON_DIMENSIONS_EXTRA}
                  width={ICON_DIMENSIONS + ICON_DIMENSIONS_EXTRA}
                />
              </TouchableOpacity>
              <Margin marginLeft="s" />
              <TouchableOpacity onPress={updateData}>
                <Icon
                  name="refresh"
                  fill={COLORS.WHITE}
                  height={ICON_DIMENSIONS}
                  width={ICON_DIMENSIONS}
                />
              </TouchableOpacity>
            </Wrapper>
          </Margin>
        </Wrapper>
        <Margin marginBottom="xs" />
      </Wrapper>
    </Wrapper>
  );
};

export default SearchBar;
