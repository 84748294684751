import React, { useContext, useState } from 'react';
import { FlatList, Platform } from 'react-native';
import { SafeAreaView, SafeAreaProvider } from 'react-native-safe-area-context';
import styled, { useTheme } from 'styled-components/native';
import { Appearance } from '@lendticket/ui/components/organisms';
import { Wrapper, Text, Margin, MarginColumn } from '@lendticket/ui/components/atoms';
import { FullWrapperHeight, Gradient, Card, Icon } from '@lendticket/ui/components/molecules';
import { PageDimensionsContext } from '@lendticket/ui/containers/PageDimensionsProvider';
import { useAnimatedReaction, runOnJS } from 'react-native-reanimated';
import { useI18n } from '@lendticket/app/src/hooks/i18n';

const isWeb = Platform.OS === 'web';
const ICON_WIDTH = 155;
const ICON_HEIGHT = 166;
const ANDROID_FADING_EDGE_LENGTH = 16;
const ICON_DIMENSIONS = 20;

const Seperator = styled.View`
  margin-top: ${({ theme }) => theme.settings.SPACINGS.md}px;
`;

const TouchableOpacity = styled.TouchableOpacity``;

export interface CardParams {
  img: string;
  from: string;
  createdByUserId: string;
  createdAt: number;
  provider: string;
  lendTime: string;
  price: string;
  id: string;
  to: string;
  periodStart: string;
  periodEnd: string;
}

interface EditPage {
  cards: CardParams[];
  hiddenCardText: string;
  hasTicketsAndProfiles: boolean;
  updateData: () => void;
  onEndReached: () => void;
  onSwipeAction: (id: string, isLoading: Animated.SharedValue<boolean>) => void;
}

const EditPage: React.FC<EditPage> = ({
  cards,
  hiddenCardText,
  hasTicketsAndProfiles,
  onEndReached,
  onSwipeAction,
  updateData,
}) => {
  const i18n = useI18n();
  const { contentHeight, extraHeight } = useContext(PageDimensionsContext);
  const [derivedContentHeight, setDerivedContentHeight] = useState(0);
  const { deviceSizes, settings } = useTheme();
  const { SPACINGS, CONSTANTS, COLORS } = settings;
  const { isMobile } = deviceSizes;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;

  useAnimatedReaction(
    () => ({ contentHeight, extraHeight }),
    () => {
      if (isWeb && contentHeight.value !== derivedContentHeight) {
        runOnJS(setDerivedContentHeight)(contentHeight.value - extraHeight.value - SPACINGS.md);
      }
    },
    [contentHeight, extraHeight],
  );

  const height = isWeb ? { height: derivedContentHeight } : {};

  return (
    <SafeAreaProvider>
      <SafeAreaView>
        <Gradient />
        <FullWrapperHeight hasBottomTabBar={false} isModal>
          <Wrapper f={1} w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}>
            <Wrapper f={1} fd="row" ai="flex-start" mb={SPACINGS.md}>
              <MarginColumn />
              <Wrapper f={1} fd="column" ai="flex-start" style={{ alignSelf: 'stretch' }}>
                <Wrapper
                  onLayout={e => {
                    if (isWeb) {
                      extraHeight.value = e.nativeEvent.layout.height;
                    }
                  }}
                >
                  <Text fontType="body" style={{ color: COLORS.WHITE }}>
                    {i18n.t('editPleaseBeAware')}
                  </Text>
                  <Margin marginTop="md" />
                  <Text fontType="body" style={{ color: COLORS.WHITE, fontWeight: 'bold' }}>
                    {i18n.t('editPleaseSlide')}
                  </Text>
                  <Wrapper style={{ marginTop: 6, marginBottom: 16 }}>
                    <TouchableOpacity
                      onPress={() => updateData()}
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Text fontType={isWeb ? 'h5' : 'body'} style={{ color: COLORS.WHITE }}>
                        {i18n.t('updateOverview')}
                      </Text>
                      <Margin marginLeft="s" />
                      <Icon
                        name="refresh"
                        fill={COLORS.WHITE}
                        height={ICON_DIMENSIONS}
                        width={ICON_DIMENSIONS}
                      />
                    </TouchableOpacity>
                  </Wrapper>
                </Wrapper>
                {hasTicketsAndProfiles ? (
                  <Appearance isFlex>
                    <FlatList
                      onEndReached={onEndReached}
                      fadingEdgeLength={ANDROID_FADING_EDGE_LENGTH}
                      style={{ width: '100%', ...height }}
                      refreshing={false}
                      onRefresh={() => {
                        updateData();
                      }}
                      data={cards}
                      ItemSeparatorComponent={Seperator}
                      renderItem={({ item }) => (
                        <Card
                          hiddenCardText={hiddenCardText}
                          onSwipeAction={onSwipeAction}
                          {...item}
                        />
                      )}
                      keyExtractor={({ createdAt }, i) => `${createdAt}-${i}`}
                    />
                  </Appearance>
                ) : (
                  <Wrapper jc="center" fd="column" mt={SPACINGS.xxl}>
                    <Appearance>
                      <Wrapper jc="center">
                        <Icon
                          name="lendTicketLogo"
                          fill={COLORS.WHITE}
                          height={ICON_HEIGHT}
                          width={ICON_WIDTH}
                        />
                        <Margin marginTop="md">
                          <Text fontType="h3" style={{ color: COLORS.WHITE, textAlign: 'center' }}>
                            {i18n.t('editYouHaveNothingToEdit')}
                          </Text>
                        </Margin>
                      </Wrapper>
                    </Appearance>
                  </Wrapper>
                )}
              </Wrapper>
              <MarginColumn />
            </Wrapper>
          </Wrapper>
        </FullWrapperHeight>
      </SafeAreaView>
    </SafeAreaProvider>
  );
};

export default EditPage;
