import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import * as routeNames from 'constants/routeNames';
import { MessagesPage } from '@lendticket/ui/components/templates';
import GestureProvider from '@lendticket/ui/containers/GestureProvider';
import api from 'reducers/slices/api';
import { useUpdateChats } from './hooks/useUpdateChats';
import { NotificationContext, SocketContext } from '../../contexts';
import { useAppSelector } from 'hooks/redux';
import { userSelector } from 'reducers/slices/user';
import { shallowEqual } from 'react-redux';
import { useAppDispatch } from 'helpers/redux/store';
import { alertMessage } from 'helpers/alertMessage';
import { ticketActions } from 'reducers/slices/tickets';
import { useI18n } from 'hooks/i18n';
import { isAndroid, isWeb } from 'helpers/platform';
import { useHeadlessTask } from '@lendticket/ui/hooks';
import { loadingActions } from 'reducers/slices/loading';

const INITIAL_STATE = {
  deletedChatMessages: {} as Record<string, any>,
  deletedTicketId: undefined,
  newChat: {
    chatId: [],
    newMessageIds: [],
    chat: undefined,
    newMessage: undefined,
  },
  liveChats: {
    unAnsweredChatIds: [],
    allChats: [],
    chats: [],
    chatIds: [],
    newChatIds: [],
    hasChats: true,
    isInitiated: false,
    currentUserName: '',
    checkForUpdates: false,
  },
};
interface ChatMessageParams {
  id?: string;
  name?: string;
  dateTime?: string;
  destinationFrom?: string;
  destinationTo?: string;
  img?: string;
  latestMessageFrom?: string;
  latestMessageDate?: string;
  latestMessage?: string;
  chatParticipantIds?: string[];
  messages?: {
    text: string;
    chatId: string;
    userId: string;
    user: string;
    timestamp: string;
  }[];
}

export const transformData = (data: ChatMessageParams[]) => {
  return data?.map(item => ({
    ...item,
    latestMessageFrom: item?.messages?.[item?.messages.length - 1]?.from,
    latestMessageDate: item?.messages?.[item?.messages.length - 1]?.timestamp,
    latestMessage: item?.messages?.[item?.messages.length - 1]?.text,
  }));
};

const Messages: React.FC = () => {
  const i18n = useI18n();
  const { removeNotifications } = useContext(NotificationContext);
  const { createSocket, removeSocket } = useContext(SocketContext);
  const [newChat, setNewChat] = useState(INITIAL_STATE.newChat);
  const [refreshData, setRefreshData] = useState(false);
  const [deletedChatMessages, setDeletedChatMessages] = useState(INITIAL_STATE.deletedChatMessages);
  const [liveChats, setLiveChats] = useState(INITIAL_STATE.liveChats);

  const isFocused = useIsFocused();
  const dispatch = useAppDispatch();
  const [deleteChatByChatId] = api.useDeleteChatByChatIdMutation();
  const updateChats = useUpdateChats();
  const user = useAppSelector(userSelector, shallowEqual);
  const navigation = useNavigation();

  const { getChatsByUserId } = api.endpoints;

  const deleteChatMessages = useCallback(
    ({ chatId, chatIds }: any) => {
      const updateSetOfChats = liveChats.chats.filter(({ id }) =>
        chatIds ? !chatIds.includes(id) : chatId !== id,
      );

      setLiveChats({
        ...liveChats,
        allChats: updateSetOfChats,
        chats: updateSetOfChats,
        chatIds: updateSetOfChats.map(({ id }) => id),
        hasChats: updateSetOfChats.length > 0,
      });
    },
    [liveChats],
  );

  const onSwipeAction = useCallback(
    (_: any, chatId: string) => {
      deleteChatByChatId(chatId)
        .then(() => deleteChatMessages({ chatId }))
        .catch(error => {
          alertMessage(error);
        });
    },
    [deleteChatByChatId, deleteChatMessages],
  );

  const onPressAction = useCallback(
    ({ ticketId, profileId, chatId, chatOwnerId }: any) => {
      dispatch(ticketActions.setIsTicketOwner(chatOwnerId === user?.id));
      navigation.navigate(routeNames.CHAT, {
        ticketId,
        chatId,
        userId: user?.id,
        profileId,
        isFavorite: liveChats.chats.some(({ id, isFavorite }) => id === chatId && isFavorite),
        isLendProfile: liveChats.chats.some(
          ({ id, isLendProfile }) => id === chatId && isLendProfile,
        ),
      });

      if (liveChats.unAnsweredChatIds.includes(chatId)) {
        setLiveChats({
          ...liveChats,
          unAnsweredChatIds: liveChats.unAnsweredChatIds.filter(id => id !== chatId),
        });
      }
    },
    [dispatch, user, navigation, liveChats],
  );

  const initiate = useCallback(async () => {
    const { id: userId } = user ?? {};

    if (!userId) {
      return;
    }

    const { refetch } = dispatch(getChatsByUserId.initiate(userId));
    const { data } = await refetch();
    const { chats } = data ?? {};
    const transformedChats: any = transformData(chats);
    const sortedChats = [...transformedChats].sort((a: any, b: any) => b.createdAt - a.createdAt);

    /* NOTE: There is a wierd bug when coming from a headless
      state. The background color is active. This extra dispatching
      event ensures that the background color dissapear */
    dispatch(loadingActions.setLoadingContent({ isLoading: false, hasBackgroundColor: false }));

    setLiveChats({
      ...liveChats,
      allChats: chats,
      chats: sortedChats,
      chatIds: sortedChats?.map(({ id }: { id: string }) => id),
      newChatIds: isAndroid ? [] : sortedChats?.map(({ id }: { id: string }) => id).slice(0, 5),
      unAnsweredChatIds: sortedChats
        ?.filter(
          ({ id, latestMessageFrom }: { id: string; latestMessageFrom: string }) =>
            latestMessageFrom !== user?.id && id,
        )
        ?.map(({ id }) => id),
      hasChats: chats?.length > 0,
      currentUserName: user?.name,
      isInitiated: true,
      checkForUpdates: false,
    });
  }, [dispatch, getChatsByUserId, liveChats, user]);

  /* NOTE: Live update behaviour */
  useEffect(() => {
    if (Object.keys(newChat?.chat ?? {}).length > 0) {
      updateChats({
        liveChats,
        newChat,
        callback: (res: any) => {
          setNewChat(INITIAL_STATE.newChat);
          setLiveChats({
            ...res,
            chats: transformData(res.chats),
            unAnsweredChatIds: transformData(res.chats)
              ?.filter(({ id, latestMessageFrom }: any) => latestMessageFrom !== user?.id && id)
              ?.map(({ id }) => id),
          });
        },
      });
    }
  }, [liveChats, newChat, updateChats, user]);

  /* NOTE: Handle chat deletions */
  useEffect(() => {
    if (Object.keys(deletedChatMessages)?.length > 0) {
      setDeletedChatMessages(INITIAL_STATE.deletedChatMessages);
      deleteChatMessages(deletedChatMessages);
    }
  }, [deleteChatMessages, deletedChatMessages]);

  useEffect(() => {
    if (isFocused && !liveChats.isInitiated) {
      initiate();
    } else if (isFocused && refreshData) {
      setRefreshData(false);
      initiate();
    } else if (!isFocused) {
      setRefreshData(true);
    }
  }, [isFocused, refreshData, liveChats, initiate]);

  /* NOTE: Socket connection */
  useEffect(() => {
    const socketChatId = 'socketChat';
    const socketChat = createSocket(socketChatId);

    socketChat.on('chatUpdates', (data: any) => {
      if (data) {
        setNewChat({
          chat: data.chatDetails,
          chatId: data.chatId,
          newMessageIds: data.newMessageIds,
          newMessage: data.newMessage,
        });
      }
    });

    socketChat.on('chatDeletion', ({ chatId, chatIds }: { chatId: string; chatIds: string[] }) => {
      setDeletedChatMessages({ chatId, chatIds });
    });

    return () => {
      socketChat.off('chatUpdates');
      socketChat.off('chatDeletion');
      removeSocket(socketChat);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEndReached = useCallback(() => {
    if (!isWeb) {
      return;
    }

    const numberOfVisualChats = liveChats.chats.length;
    const minimumRequireMentOfVisualChats = numberOfVisualChats >= 10;
    const allChats = [...liveChats.allChats].sort((a: any, b: any) => b.createdAt - a.createdAt);

    if (minimumRequireMentOfVisualChats && numberOfVisualChats < allChats.length) {
      const diff = allChats.length - numberOfVisualChats;
      const loadMoreLiveChats = liveChats.chats.concat(
        allChats.slice(liveChats.chats.length, liveChats.chats.length + (diff >= 10 ? 10 : diff)),
      );

      setLiveChats({
        ...liveChats,
        chats: loadMoreLiveChats,
        chatIds: loadMoreLiveChats.map(({ id }) => id),
      });
    }
  }, [liveChats]);

  useHeadlessTask({
    matchRoute: routeNames.MESSAGES,
    handleHeadlessTask: () => {
      removeNotifications();
      setLiveChats(INITIAL_STATE.liveChats);
    },
  });

  return (
    <GestureProvider>
      <MessagesPage
        currentUserName={liveChats?.currentUserName}
        isInitiated={liveChats?.isInitiated}
        chats={liveChats.chats.sort((a: any, b: any) => b.createdAt - a.createdAt)}
        newChatIds={liveChats.newChatIds}
        hasChats={liveChats.hasChats}
        unAnsweredChatIds={liveChats.unAnsweredChatIds}
        updateChatMessages={initiate}
        onPressAction={onPressAction}
        onSwipeStartChat={onSwipeAction}
        onEndReached={onEndReached}
        title={i18n.t('messages')}
        hiddenCardText={i18n.t('delete')}
      />
    </GestureProvider>
  );
};

export default Messages;
