import React from 'react';
import * as Progress from 'react-native-progress';
import { Wrapper, Margin, MarginColumn } from '@lendticket/ui/components/atoms';
import { Platform, ActivityIndicator, ViewStyle } from 'react-native';
import Animated, {
  AnimatedStyleProp,
  useSharedValue,
  useAnimatedStyle,
  useAnimatedReaction,
} from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';

const isWeb = Platform.OS === 'web';

interface Props {
  children: React.ReactNode;
  hasBackgroundColor?: Animated.SharedValue<boolean>;
  indicator: 'circle' | 'line';
  isLoading: Animated.SharedValue<boolean>;
}

const OpacityAnimation = Animated.View;

const LoadingPage: React.FC<Props> = ({ isLoading, hasBackgroundColor, indicator, children }) => {
  const opacity = useSharedValue(0);
  const display = useSharedValue('none');

  const { deviceSizes, windowDimensions, settings } = useTheme();
  const { COLORS } = settings;
  const { isMobile } = deviceSizes;
  const { windowWidth, windowHeight } = windowDimensions;
  const isIndicatorCircle = indicator === 'circle';

  const animatedStyle = useAnimatedStyle(
    (): AnimatedStyleProp<ViewStyle & { position: any; display: any }> => ({
      zIndex: 1,
      position: isWeb ? 'fixed' : 'absolute',
      height: '100%',
      width: '100%',
      backgroundColor: hasBackgroundColor?.value ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)',
      display: display.value,
      opacity: opacity.value,
    }),
  );

  useAnimatedReaction(
    () => isLoading,
    res => {
      if (res.value) {
        opacity.value = 1;
        display.value = 'flex';
      } else {
        opacity.value = 0;
        display.value = 'none';
      }
    },
    [isLoading],
  );

  return (
    <>
      <OpacityAnimation style={animatedStyle}>
        {isIndicatorCircle ? (
          <Wrapper fd="column" h={windowHeight} w={windowWidth} jc="center" ai="center">
            <ActivityIndicator size="large" color={COLORS.WHITE} />
            <Margin marginTop="md">
              <Wrapper w={isMobile ? windowWidth - 40 * 2 : undefined} jc="center" fd="row">
                <MarginColumn />
                {children}
                <MarginColumn />
              </Wrapper>
            </Margin>
          </Wrapper>
        ) : (
          <Wrapper jc="flex-start" ai="flex-start">
            <Progress.Bar
              indeterminate
              height={5}
              width={windowWidth}
              borderColor="transparent"
              borderWidth={0}
              color={COLORS.BACKGROUND_LIGHT}
            />
          </Wrapper>
        )}
      </OpacityAnimation>
    </>
  );
};

export default LoadingPage;
