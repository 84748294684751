import React from 'react';
import { View, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTheme } from 'styled-components/native';
import { Wrapper, MarginColumn } from '@lendticket/ui/components/atoms';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const isIOS = Platform.OS === 'ios';
const BOTTOM_TABBAR_HEIGHT = 97;
const SCROLL_EVENT_THROTTHLE = 16;

interface KeyboardAvoidingViewProps {
  extraWidth?: number;
  hasMarginTop: boolean;
  children: React.ReactNode;
}

const KeyboardAvoidingView: React.FC<KeyboardAvoidingViewProps> = ({ hasMarginTop, children }) => {
  const { settings, deviceSizes } = useTheme();
  const { top } = useSafeAreaInsets();

  const { isMobile } = deviceSizes;
  const { CONSTANTS, SPACINGS } = settings;
  const derivedBottomTabbarHeight = BOTTOM_TABBAR_HEIGHT + top;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const isIOSAndMobile = isIOS && isMobile;

  return (
    <KeyboardAwareScrollView
      style={{
        flex: 1,
        width: isMobile ? '100%' : DESKTOP_TABLET_WIDTH - SPACINGS.xl * 2,
        marginBottom: SPACINGS.md,
        marginTop: hasMarginTop ? SPACINGS.md : 0,
      }}
      enableResetScrollToCoords={false}
      keyboardShouldPersistTaps="handled"
      keyboardDismissMode="on-drag"
      scrollEventThrottle={SCROLL_EVENT_THROTTHLE}
      extraScrollHeight={derivedBottomTabbarHeight}
    >
      <Wrapper f={1} fd="row" jc="center" ai="flex-start">
        {isIOSAndMobile && <MarginColumn />}
        <Wrapper f={1} fd="column" ai="flex-start" style={{ alignSelf: 'stretch' }}>
          <View
            style={{
              width: isMobile ? '100%' : DESKTOP_TABLET_WIDTH - SPACINGS.xl * 2,
              marginBottom: SPACINGS.md,
              marginTop: SPACINGS.md,
            }}
          >
            {children}
          </View>
        </Wrapper>
        {isIOSAndMobile && <MarginColumn />}
      </Wrapper>
    </KeyboardAwareScrollView>
  );
};

export default KeyboardAvoidingView;
