import React from 'react';
import styled from 'styled-components/native';

export interface WrapperProps {
  fd?: 'row' | 'column';
  b?: string;
  br?: number;
  bc?: string;
  h?: number;
  w?: number;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  jc?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  ai?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  pg?: number | string;
  f?: number;
  p?: 'relative' | 'flex' | 'absolute';
  of?: 'hidden' | 'auto' | 'visible' | 'scroll';
  ofy?: 'hidden' | 'auto' | 'visible' | 'scroll';
  ofx?: 'hidden' | 'auto' | 'visible' | 'scroll';
  bs?: string;
  test?: boolean;
  style?: any;
  onLayout?: (event: any) => void;
  children?: React.ReactNode;
}

const View = styled.View<WrapperProps>`
  padding: ${({ pg }) => {
    if (typeof pg === 'number') {
      return `${pg}px`;
    }

    if (typeof pg === 'string') {
      return pg;
    }

    return '0px';
  }};
  position: ${({ p }) => p ?? 'relative'};
  flex-direction: ${({ fd }) => fd ?? 'column'};
  background-color: ${({ bc }) => bc ?? 'transparent'};
  ${({ h }) => {
    if (h) {
      return `height: ${h}px`;
    }
  }};
  width: ${({ w }) => (w ? `${w}px` : '100%')};
  margin-top: ${({ mt }) => mt ?? 0}px;
  margin-bottom: ${({ mb }) => mb ?? 0}px;
  margin-left: ${({ ml }) => ml ?? 0}px;
  margin-right: ${({ mr }) => mr ?? 0}px;
  justify-content: ${({ jc }) => jc ?? 'flex-start'};
  align-items: ${({ ai }) => ai ?? 'center'};
  flex: ${({ f }) => f ?? 'none'};
  border: ${({ test, b }) => {
    if (test) {
      return '5px solid green';
    }

    if (b) {
      return b;
    }

    return '0px';
  }}
  border-radius: ${({ br }) => br ?? 0}px;
  overflow-y: ${({ ofy }) => ofy ?? 'hidden'};
  overflow-x: ${({ ofx }) => ofx ?? 'hidden'};
  ${({ bs }) => {
    if (bs) {
      return `box-shadow: ${bs}`;
    }
  }};
  user-select: none;
`;

const Wrapper: React.FC<WrapperProps> = ({ children, ...rest }) => (
  <View {...rest}>{children}</View>
);

export default Wrapper;
