import React from 'react';
import { useTheme } from 'styled-components/native';
import { Wrapper, Button, Text } from '@lendticket/ui/components/atoms';
import type { WrapperProps } from '@lendticket/ui/components/atoms/Wrapper';

const HEIGHT = 50;

const WRAPPER_STYLE = {
  h: HEIGHT,
  br: 8,
  b: '2px solid white',
  jc: 'center',
  ai: 'center',
} as Pick<WrapperProps, 'h' | 'br' | 'b' | 'jc' | 'ai' | 'bs'>;

interface BorderButtonProps {
  title: string;
  style?: object;
  jc?: string;
  ai?: string;
  w?: number;
  onPress: () => void;
}

const BorderButton: React.FC<BorderButtonProps> = ({ title, style, onPress, ...rest }) => {
  const { deviceSizes } = useTheme();
  const { isMobile } = deviceSizes;

  return (
    <Button onPress={onPress} h={HEIGHT} {...(rest as any)} style={style ?? {}}>
      <Wrapper {...WRAPPER_STYLE}>
        <Text fontType={isMobile ? 'h5' : 'h3'} style={{ color: 'white', textAlign: 'center' }}>
          {title}
        </Text>
      </Wrapper>
    </Button>
  );
};

export default BorderButton;
