import React from 'react';
import styledWeb, { useTheme } from 'styled-components';
import { DefaultTheme } from 'styled-components/native';
import { getTextStyle } from '@lendticket/ui/helpers';

type FontType = 'h1' | 'h2' | 'h3' | 'body' | 'description' | 'subtitle';
interface TextProps {
  fontType: FontType;
  style?: {
    color?: string;
    fontWeight?: string;
    textAlign?: string;
    textColor?: string;
    textShadow?: string;
    fontFamily?: string;
    paddingTop?: number;
    paddingLeft?: number;
    paddingRight?: number;
    lineHeight?: number;
    numberOfLines?: number;
    width?: number;
    textDecoration?: 'overline' | 'line-through' | 'underline' | 'underline overline';
    wordBreak?: boolean;
  };
  children: React.ReactNode;
}

const TextComponent: any = styledWeb.div.attrs(
  ({
    fontType,
    theme,
    style = {},
  }: {
    fontType: FontType;
    theme: DefaultTheme;
    style: TextProps['style'];
  }) => ({ style: getTextStyle({ fontType, theme, style }) }),
)``;

const Text: React.FC<TextProps> = ({ fontType, children, style }) => {
  const theme = useTheme();

  return (
    <TextComponent fontType={fontType} theme={theme} style={style}>
      {children}
    </TextComponent>
  );
};

export default Text;
