import React, { useContext, useCallback, useRef } from 'react';
import { Platform } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import Animated, { useAnimatedReaction, runOnJS } from 'react-native-reanimated';
import { ScrollView, NativeViewGestureHandler } from 'react-native-gesture-handler';
import { useKeyboardDimensions } from '@lendticket/ui/hooks';
import { Text, Margin, Wrapper, MarginColumn } from '@lendticket/ui/components/atoms';
import { SvgImage } from '@lendticket/ui/components/molecules';
import { Appearance, HideOnKeyboard } from '@lendticket/ui/components/organisms';
import { GestureContext } from '@lendticket/ui/containers/GestureProvider';
import type { SvgImageProps } from '@lendticket/ui/components/molecules/SvgImage';

const isIOS = Platform.OS === 'ios';
const isWeb = Platform.OS === 'web';
const SCROLL_EVENT_THROTTHLE = 16;
const DEFAULT_HEIGHT = 500;
export const SVG_WRAPPER_HEIGHT = 230;

interface DefaultPageProps {
  teaserTitle?: string;
  svgName?: SvgImageProps['name'];
  teaserText?: string;
  svgMarginTop?: string;
  inputFieldsContainerHeight?: number;
  isScrollEnabled?: Animated.SharedValue<boolean>;
  inputFieldsComponent?: React.ReactNode;
  contentComponent?: React.ReactNode;
  topComponent?: React.ReactNode;
  children?: React.ReactNode;
}

const InputAndContentWrapper = styled.View<{ inputFieldsContainerHeight?: number }>`
  ${({ inputFieldsContainerHeight, theme }) => {
    const { deviceSizes } = theme;
    const { isMobile } = deviceSizes;

    if (!isMobile) {
      return `width: ${inputFieldsContainerHeight}px`;
    }
  }};
`;

const DefaultPage: React.FC<DefaultPageProps> = ({
  teaserTitle,
  teaserText,
  svgName,
  inputFieldsContainerHeight = DEFAULT_HEIGHT,
  inputFieldsComponent: InputFieldsComponent,
  topComponent: TopComponent,
  contentComponent: ContentComponent,
}) => {
  const { isScrolling, tapRef, panRef, nativeScrollRef } = useContext(GestureContext);
  const scrollViewRef = useRef<ScrollView>(null);
  const keyboardValues = useKeyboardDimensions();
  const { settings, deviceSizes } = useTheme();
  const { isMobile } = deviceSizes;
  const { SHADOWS, SPACINGS, COLORS, CONSTANTS } = settings;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const { TEXT_SHADOW } = SHADOWS;

  const scrollToTop = useCallback(() => {
    // if (scrollViewRef?.current) {
    //   scrollViewRef.current?.scrollTo({
    //     y: 0,
    //     animated: false,
    //   });
    // }
  }, []);

  useAnimatedReaction(
    () => keyboardValues.value.isVisible,
    res => {
      if (res) {
        runOnJS(scrollToTop)();
      }
    },
    [keyboardValues],
  );

  return (
    <NativeViewGestureHandler
      enabled={isWeb}
      ref={nativeScrollRef}
      waitFor={tapRef}
      simultaneousHandlers={panRef}
    >
      <ScrollView
        ref={scrollViewRef}
        bounces={false}
        scrollEventThrottle={SCROLL_EVENT_THROTTHLE}
        onScroll={() => {
          if (typeof isScrolling?.value === 'boolean' && !isScrolling.value) {
            isScrolling.value = true;
          }
        }}
        contentContainerStyle={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Wrapper fd="row" mb={SPACINGS.md} w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}>
          <MarginColumn />
          <Wrapper f={1}>
            <HideOnKeyboard>
              {svgName && (
                <Wrapper h={SVG_WRAPPER_HEIGHT}>
                  <Appearance>
                    <SvgImage name={svgName} />
                  </Appearance>
                </Wrapper>
              )}
              {teaserTitle && (
                <Margin vertical="md">
                  <Text
                    fontType="h2"
                    style={{ color: COLORS.WHITE, textAlign: 'center', textShadow: TEXT_SHADOW }}
                  >
                    {teaserTitle}
                  </Text>
                </Margin>
              )}
            </HideOnKeyboard>
            {teaserText && (
              <HideOnKeyboard>
                <Margin marginBottom="xl">
                  <Text
                    fontType={isIOS ? 'h4' : 'h3'}
                    style={{ color: COLORS.WHITE, textAlign: 'center', textShadow: TEXT_SHADOW }}
                  >
                    {teaserText}
                  </Text>
                </Margin>
              </HideOnKeyboard>
            )}
            <InputAndContentWrapper inputFieldsContainerHeight={inputFieldsContainerHeight}>
              {TopComponent && TopComponent}
              {InputFieldsComponent && InputFieldsComponent}
              {ContentComponent && <HideOnKeyboard>{ContentComponent}</HideOnKeyboard>}
            </InputAndContentWrapper>
          </Wrapper>
          <MarginColumn />
        </Wrapper>
      </ScrollView>
    </NativeViewGestureHandler>
  );
};

export default DefaultPage;
