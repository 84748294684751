const COLORS = {
  DARK_MODE: {
    GRADIENT_0: '#DC798B',
    GRADIENT_100: '#DB867B',
  },
  BACKGROUND_PRIMARY: '#DC8083',
  BACKGROUND_LIGHT: '#F4D7DB',
  BACKGROUND_LIGHT_ORANGE: '#E5A5A2',
  BACKGROUND_EXTRA_LIGHT_ORANGE: '#F7E4E3',
  TEXT_LIGHT_PURPLE: '#777695',
  TEXT_PURPLE: '#151248',
  NOT_SELECTED: '#EFC8C5',
  OPACITY_COLOR: '#EFC5C5',
  OPACITY_COLOR_TEXT: '#FCDADA',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  GREEN: '#89A3AA',
  TEXT_BLACK: '#46505A',
  TEXT_BLACK_SECONDARY: '#847592',
  ERROR: '#FF0000',
  SUCCESS: '#74d680',
  PINK: 'rgb(237, 83, 157)',
  LIGHT_RED: '#EC7F68',
  RED: 'rgb(238, 103, 92)',
  ORANGE: '#ff8c00',
  LINK: '#0645AD',
  MEDITERRANEAN: 'rgb(84, 128, 146)',
  MEDITERRANEAN_INACTIVE: 'rgb(174, 199, 208)',
};

const FONTS = {
  SIZES: {
    DESKTOP: {
      h1: {
        fontSize: '50px',
        fontSizeNative: 50,
        fontWeight: 900,
        fontStetch: '90%',
        lineHeightWeb: '1.25em',
        lineHeightNative: 55,
        fontFamily: 'Hubot-Sans-Regular',
      },
      h2: {
        fontSize: '40px',
        fontSizeNative: 40,
        fontWeight: 900,
        fontStretch: '90%',
        lineHeightWeb: '1.25em',
        lineHeightNative: 45,
        fontFamily: 'Hubot-Sans-ExtraBold',
      },
      h3: {
        fontSize: '30px',
        fontSizeNative: 30,
        fontWeight: 700,
        fontStretch: '80%',
        lineHeightWeb: '1.25em',
        lineHeightNative: 35,
        fontFamily: 'Hubot-Sans-BoldSemi',
      },
      h4: {
        fontSize: '20px',
        fontSizeNative: 20,
        fontWeight: 700,
        fontStretch: '80%',
        lineHeightWeb: '1.25em',
        lineHeightNative: 35,
        fontFamily: 'Hubot-Sans-BoldSemi',
      },
      h5: {
        fontSize: '18px',
        fontSizeNative: 18,
        fontWeight: 700,
        fontStretch: '80%',
        lineHeightWeb: '1.25em',
        lineHeightNative: 35,
        fontFamily: 'Hubot-Sans-BoldSemi',
      },
      body: {
        fontSize: '14px',
        fontSizeNative: 14,
        fontWeight: 400,
        fontStretch: '90%',
        lineHeightWeb: '1.5em',
        lineHeightNative: 19,
        fontFamily: 'Hubot-Sans-BoldSemi',
      },
      subtitle: {
        fontSize: '12px',
        fontSizeNative: 12,
        fontWeight: 400,
        fontStretch: '90%',
        lineHeightWeb: '1.5em',
        lineHeightNative: 17,
        fontFamily: 'Hubot-Sans-BoldSemi',
      },
    },
    MOBILE: {
      h1: {
        fontSize: '40px',
        fontSizeNative: 40,
        fontWeight: 900,
        fontStretch: '90%',
        lineHeightWeb: '1.25em',
        lineHeightNative: 45,
        fontFamily: 'Hubot-Sans-Black',
      },
      h2: {
        fontSize: '30px',
        fontSizeNative: 30,
        fontWeight: 900,
        fontStretch: '90%',
        lineHeight: '1.25em',
        lineHeightNative: 35,
        fontFamily: 'Hubot-Sans-BoldSemi',
      },
      h3: {
        fontSizeWeb: '25px',
        fontSizeNative: 23,
        fontWeight: 700,
        fontStretch: '80%',
        lineHeightWeb: '1.25em',
        lineHeightNative: 30,
        fontFamily: 'Hubot-Sans-BoldSemi',
      },
      h4: {
        fontSize: '20px',
        fontSizeNative: 20,
        fontWeight: 700,
        fontStretch: '80%',
        lineHeightWeb: '1.25em',
        lineHeightNative: 28,
        fontFamily: 'Hubot-Sans-BoldSemi',
      },
      h5: {
        fontSize: '18px',
        fontSizeNative: 18,
        fontWeight: 700,
        fontStretch: '80%',
        lineHeightWeb: '1.25em',
        lineHeightNative: 28,
        fontFamily: 'Hubot-Sans-BoldSemi',
      },
      body: {
        fontSizeWeb: '14px',
        fontSizeNative: 14,
        fontWeight: 400,
        fontStretch: '100%',
        lineHeightWeb: '1.5em',
        lineHeightNative: 24,
        fontFamily: 'Hubot-Sans-Regular',
      },
      subtitle: {
        fontSize: '12px',
        fontSizeNative: 12,
        fontWeight: 400,
        fontStretch: '100%',
        lineHeightWeb: '1.5em',
        lineHeightNative: 22,
        fontFamily: 'Hubot-Sans-SemiBoldSemi',
      },
    },
  },
};

const SPACINGS = {
  xxxxl: 60,
  xxxl: 50,
  xxl: 40,
  xl: 32,
  lg: 24,
  md: 16,
  m: 12,
  ms: 10,
  sm: 8,
  s: 6,
  xs: 4,
  xxs: 2,
};

const CONSTANTS = {
  WINDOW_HEIGHT_RATIO: 1.25,
  DESKTOP_SMALL_WIDTH: 500,
  DESKTOP_TABLET_WIDTH: 750,
  DESKTOP_WRAPPER_WIDTH_LARGE: 660,
  DESKTOP_WRAPPER_WIDTH_EXTRA_LARGE: 1000,
  BUTTON_HEIGHT: 60,
  BUTTON_WIDTH: 200,
};

const BORDERS = {
  DEFAULT_BORDER: '3px solid rgba(255, 255, 255, 1)',
  DEFAULT_BORDER_RADIUS: '7px',
};

const SHADOWS = {
  TEXT_SHADOW: '0px 2px 10px rgba(0, 0, 0, 0.0125)',
  BOX_SHADOW_BOTTOM: {
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 3,
    elevation: 5,
  },
  BOX_SHADOW_BOTTOM_SECONDARY: {
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 0.125,
    shadowRadius: 3,
    elevation: 5,
  },
  BOX_SHADOW_TOP: {
    shadowColor: '#000',
    shadowOffset: { width: 1, height: -2 },
    shadowOpacity: 0.0125,
    shadowRadius: 3,
    elevation: 5,
  },
  BOX_SHADOW: {
    LIGHT: '0px 3px 3px rgba(0, 0, 0, 0.125)',
    MEDIUM_LIGHT: '0px 3px 10px rgba(0, 0, 0, 0.25)',
    DARK: '0px 3px 10px rgba(0, 0, 0, 0.65)',
  },
};

export { COLORS, CONSTANTS, FONTS, SPACINGS, BORDERS, SHADOWS };
