import React from 'react';
import { Platform } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { PanGestureHandlerGestureEvent, PanGestureHandler } from 'react-native-gesture-handler';
import Animated, {
  useAnimatedReaction,
  interpolate,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withSpring,
  useAnimatedGestureHandler,
} from 'react-native-reanimated';
import { Wrapper, Text, Margin } from '@lendticket/ui/components/atoms';
import { Icon } from '@lendticket/ui/components/molecules';
import { DEFAULT_SPRING_CONFIG } from '@lendticket/ui/constants';
import { onStart, onActive, onEnd } from './gestures';

const isIOS = Platform.OS === 'ios';
const HEIGHT_MOBILE = 50;
const HEIGHT_DESKTOP_TABLET = 60;
const BORDER_RADIUS = 7;
const PADDING = 7;
const ICON_DIMENSIONS = 26;
const ICON_DIMENSIONS_MOBILE = 18;

interface SwiperButtonProps {
  isRouteMessages?: boolean;
  textLeft: string;
  textRight: string;
  svgNameLeft?: string;
  svgNameRight?: string;
  swiperCallback?: (val?: string) => void;
  animatedValues?: {
    swiperWidth: Animated.SharedValue<number>;
    translationX: Animated.SharedValue<number>;
  };
}

interface AnimatedGHContext {
  [key: string]: number;
  startX: number;
  startY: number;
}

export interface GestureParams {
  startX: Animated.SharedValue<number>;
  prevDragX: Animated.SharedValue<number>;
  dragX: Animated.SharedValue<number>;
  translationX: Animated.SharedValue<number>;
  swiperWidth: Animated.SharedValue<number>;
  panningDirection: Animated.SharedValue<string>;
  ctx?: AnimatedGHContext;
  e?: any;
  swiperCallback?: (val?: string) => void;
}

const TouchableOpacity = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
`;

const Swiper = styled.View<{ transparent?: boolean }>`
  background-color: ${({ transparent, theme }) =>
    transparent ? 'transparent' : theme.settings.COLORS.BACKGROUND_PRIMARY};
  height: 100%;
  border-radius: ${BORDER_RADIUS}px;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const AnimatedSwiper = Animated.createAnimatedComponent(Swiper);

const SwiperButton: React.FC<SwiperButtonProps> = ({
  textLeft,
  textRight,
  animatedValues,
  svgNameLeft,
  svgNameRight,
  swiperCallback,
}) => {
  const { settings, deviceSizes } = useTheme();
  const { COLORS } = settings;
  const { isMobile } = deviceSizes;

  const startX = useSharedValue(0);
  const prevDragX = useSharedValue(0);
  const dragX = useSharedValue(0);
  const translationX = useSharedValue(0);
  const swiperWidth = useSharedValue(0);
  const panningDirection = useSharedValue('none');

  useAnimatedReaction(
    () => ({ translationX, swiperWidth }),
    res => {
      if (animatedValues) {
        animatedValues.translationX.value = res.translationX.value;
        if (animatedValues.swiperWidth.value === 0) {
          animatedValues.swiperWidth.value = res.swiperWidth.value;
        }
      }
    },
    [translationX, swiperWidth],
  );

  const fontType = isMobile ? 'body' : 'h3';
  const height = isMobile ? HEIGHT_MOBILE : HEIGHT_DESKTOP_TABLET;

  const gestureParams = {
    startX,
    prevDragX,
    dragX,
    translationX,
    panningDirection,
    swiperWidth,
    swiperCallback,
  };

  const shadows = isIOS
    ? {
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 1,
      }
    : { elevation: 5 };

  const animatedStyle = useAnimatedStyle(() => ({
    zIndex: 9,
    shadowColor: '#000',
    ...shadows,
    backgroundColor: COLORS.BACKGROUND_PRIMARY,
    transform: [
      {
        translateX: translationX.value,
      },
    ],
  }));

  const styleTextLendTicket = useDerivedValue(() => ({
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: swiperWidth.value,
    display: translationX.value <= swiperWidth.value / 2 ? 'flex' : 'none',
    opacity: interpolate(translationX.value, [0, swiperWidth.value / 2], [1, 0]),
  }));

  const styleTextTicketSeekers = useDerivedValue(() => ({
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: swiperWidth.value,
    display: translationX.value > swiperWidth.value / 3 ? 'flex' : 'none',
    opacity: interpolate(translationX.value, [swiperWidth.value / 3, swiperWidth.value], [0, 1]),
  }));

  const animatedStyleTextLendTickets = useAnimatedStyle((): any => styleTextLendTicket.value);
  const animatedStyleTextTicketSeekers = useAnimatedStyle((): any => styleTextTicketSeekers.value);
  const animatedStyleTextBackgroundTicketSeekers = useAnimatedStyle((): any => ({
    ...styleTextLendTicket.value,
    transform: [
      {
        translateX: interpolate(
          translationX.value,
          [0, swiperWidth.value / 2],
          [0, -swiperWidth.value / 2],
        ),
      },
    ],
  }));

  const animatedStyleTextBackgroundLendTicket = useAnimatedStyle((): any => ({
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    display: translationX.value >= swiperWidth.value / 2 ? 'flex' : 'none',
    width: swiperWidth.value,
    opacity: interpolate(translationX.value, [0, swiperWidth.value], [0, 1]),
    transform: [
      {
        translateX: interpolate(
          translationX.value,
          [0, swiperWidth.value / 2],
          [0, -swiperWidth.value / 2],
        ),
      },
    ],
  }));

  const gestureHandler = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    AnimatedGHContext
  >({
    onStart: (_, ctx) => onStart({ ...gestureParams, ctx }),
    onActive: (e, ctx) => onActive({ ...gestureParams, ctx, e }),
    onEnd: () => onEnd(gestureParams),
  });

  return (
    <Wrapper fd="row" pg={PADDING} br={BORDER_RADIUS} bc={COLORS.BACKGROUND_LIGHT} h={height}>
      <PanGestureHandler onGestureEvent={gestureHandler}>
        <AnimatedSwiper
          onLayout={e => {
            if (swiperWidth.value === 0) {
              swiperWidth.value = e.nativeEvent.layout.width;
            }
          }}
          style={animatedStyle}
        >
          <Animated.View style={animatedStyleTextLendTickets}>
            {svgNameLeft && (
              <Margin marginRight="m">
                <Icon
                  name={svgNameLeft}
                  fill={COLORS.WHITE}
                  height={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS}
                  width={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS}
                />
              </Margin>
            )}
            <Text fontType={fontType} style={{ textAlign: 'center', color: COLORS.WHITE }}>
              {textLeft}
            </Text>
          </Animated.View>
          <Animated.View style={animatedStyleTextTicketSeekers}>
            {svgNameRight && (
              <Margin marginRight="m">
                <Icon
                  name={svgNameRight}
                  fill={COLORS.WHITE}
                  height={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS}
                  width={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS}
                />
              </Margin>
            )}
            <Text fontType={fontType} style={{ textAlign: 'center', color: COLORS.WHITE }}>
              {textRight}
            </Text>
          </Animated.View>
        </AnimatedSwiper>
      </PanGestureHandler>
      <Swiper transparent>
        <TouchableOpacity
          onPress={() => {
            if (swiperCallback) {
              swiperCallback('right');
            }

            translationX.value = withSpring(swiperWidth.value, DEFAULT_SPRING_CONFIG);
          }}
        >
          <Animated.View style={animatedStyleTextBackgroundTicketSeekers}>
            {svgNameRight && (
              <Margin marginRight="m">
                <Icon
                  name={svgNameRight}
                  fill={COLORS.TEXT_BLACK_SECONDARY}
                  height={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS}
                  width={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS}
                />
              </Margin>
            )}
            <Text
              fontType={fontType}
              style={{ textAlign: 'center', color: COLORS.TEXT_BLACK_SECONDARY }}
            >
              {textRight}
            </Text>
          </Animated.View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            if (swiperCallback) {
              swiperCallback('left');
            }

            translationX.value = withSpring(0, DEFAULT_SPRING_CONFIG);
          }}
        >
          <Animated.View style={animatedStyleTextBackgroundLendTicket}>
            {svgNameLeft && (
              <Margin marginRight="m">
                <Icon
                  name={svgNameLeft}
                  fill={COLORS.TEXT_BLACK_SECONDARY}
                  height={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS}
                  width={isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS}
                />
              </Margin>
            )}
            <Text
              fontType={fontType}
              style={{ textAlign: 'center', color: COLORS.TEXT_BLACK_SECONDARY }}
            >
              {textLeft}
            </Text>
          </Animated.View>
        </TouchableOpacity>
      </Swiper>
    </Wrapper>
  );
};

export default SwiperButton;
