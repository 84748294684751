import React from 'react';
import SvgIcon, { Props as SvgIconTypeProps } from './SvgIcon';
import svgs from '@lendticket/ui/assets/svgs';

const VIEW_BOX_DEFAULT = '0 0 100 100';
const ICON_HEIGHT = 108.431 / 2;
const ICON_WIDTH = 474.474 / 2;

type IconProps = Pick<SvgIconTypeProps, 'fill' | 'name' | 'viewBox'> &
  Partial<Pick<SvgIconTypeProps, 'width' | 'height'>> & {
    style?: Record<string, any>;
  };

const Icon: React.FC<IconProps> = (props: IconProps) => (
  <SvgIcon
    {...props}
    viewBox={props.viewBox ?? VIEW_BOX_DEFAULT}
    fill={props.fill}
    width={props.width || ICON_WIDTH}
    height={props.height || ICON_HEIGHT}
    svgs={svgs}
  />
);

export default Icon;
