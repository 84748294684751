import React, { useRef } from 'react';
import { Platform, ScrollView } from 'react-native';
import { Wrapper, Margin } from '@lendticket/ui/components/atoms';
import { ChatMessage } from '@lendticket/ui/components/molecules';
import type { MessagesParams } from '@lendticket/ui/components/templates/ChatPage';
import Appearance from '../Appearance';

const isWeb = Platform.OS === 'web';

interface ChatMessagesProps {
  userId?: string;
  messages: MessagesParams[];
  newMessageIds: string[];
}

const ChatMessages: React.FC<ChatMessagesProps> = ({ messages, userId, newMessageIds }) => {
  const scrollViewRef = useRef<ScrollView>(null);

  return (
    <ScrollView
      ref={scrollViewRef}
      onContentSizeChange={() => {
        scrollViewRef.current.scrollToEnd({ animated: true });
      }}
      style={{ width: '100%' }}
    >
      <Margin horizontal={isWeb ? 'md' : ''}>
        {messages?.map(({ text, id, from, name }, i) => (
          <Wrapper fd="row" key={`${text}-${i}`}>
            {from === userId && <Wrapper f={1} />}

            <Margin marginTop="md">
              {newMessageIds.includes(id) ? (
                <Appearance>
                  <ChatMessage text={text} name={name} isFlexEnd={from === userId} />
                </Appearance>
              ) : (
                <ChatMessage text={text} name={name} isFlexEnd={from === userId} />
              )}
            </Margin>

            {from !== userId && <Wrapper f={1} />}
          </Wrapper>
        ))}
      </Margin>
    </ScrollView>
  );
};

export default ChatMessages;
