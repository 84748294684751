import React, { Fragment } from 'react';
import { Platform } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {
  FullWrapperHeight,
  Gradient,
  SettingsRow,
  Label,
  DefaultProfileImage,
} from '@lendticket/ui/components/molecules';
import { BottomNavigationBar } from '@lendticket/ui/components/organisms';
import { Margin, Wrapper, Button } from '@lendticket/ui/components/atoms';
import { useI18n } from '@lendticket/app/src/hooks/i18n';

const MINIMUM_WINDOW_HEIGHT_FOR_IMAGE = 670;

const isWeb = Platform.OS === 'web';
const BORDER_BOTTOM_RADIUS = 7;
const IMAGE_DIMENSIONS = 200;
const DEFAULT_IMAGE_DIMENSIONS = 150;
const IMAGE_DIMENSIONS_WEB = IMAGE_DIMENSIONS / 1.5;
const IMAGE_DIMENSIONS_MOBILE = IMAGE_DIMENSIONS / 2;
const DEFAULT_IMAGE_DIMENSIONS_WEB = DEFAULT_IMAGE_DIMENSIONS / 1.5;
const DEFAULT_IMAGE_DIMENSIONS_MOBILE = DEFAULT_IMAGE_DIMENSIONS / 2;

interface HomePageProps {
  options: {
    iconName: string;
    text: string;
    onPress: () => void;
  }[];
}

const ImageWrapper = styled.Image`
  height: ${({ theme }) =>
    theme.deviceSizes.isMobile ? IMAGE_DIMENSIONS_MOBILE : IMAGE_DIMENSIONS_WEB}px;
  width: ${({ theme }) =>
    theme.deviceSizes.isMobile ? IMAGE_DIMENSIONS_MOBILE : IMAGE_DIMENSIONS_WEB}px;
`;

const HomePage: React.FC<HomePageProps> = ({ options, profile }) => {
  const i18n = useI18n();
  const { deviceSizes, settings, windowDimensions } = useTheme();
  const { windowWidth, windowHeight } = windowDimensions;
  const { isMobile } = deviceSizes;
  const { COLORS, CONSTANTS, SHADOWS } = settings;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const shadow = isMobile ? SHADOWS.BOX_SHADOW_BOTTOM_SECONDARY : {};

  return (
    <SafeAreaView>
      <Gradient />
      <FullWrapperHeight
        hasHeader
        hasBottomTabBar={!isWeb}
        footer={isWeb && <BottomNavigationBar />}
      >
        <Wrapper f={1} w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}>
          <Wrapper
            fd="column"
            bc={isMobile ? COLORS.BACKGROUND_LIGHT : 'transparent'}
            style={{
              borderBottomLeftRadius: BORDER_BOTTOM_RADIUS,
              borderBottomRightRadius: BORDER_BOTTOM_RADIUS,
              ...shadow,
            }}
          >
            {windowHeight >= MINIMUM_WINDOW_HEIGHT_FOR_IMAGE && (
              <Wrapper style={{ display: 'flex', marginTop: 12 }}>
                {profile?.img ? (
                  <Margin marginBottom="md">
                    <ImageWrapper source={{ uri: profile?.img }} style={{ borderRadius: 999 }} />
                  </Margin>
                ) : (
                  <DefaultProfileImage
                    height={
                      isMobile ? DEFAULT_IMAGE_DIMENSIONS_MOBILE : DEFAULT_IMAGE_DIMENSIONS_WEB
                    }
                    width={
                      isMobile ? DEFAULT_IMAGE_DIMENSIONS_MOBILE : DEFAULT_IMAGE_DIMENSIONS_WEB
                    }
                    text={profile?.name?.slice(0, 2).toUpperCase()}
                    backgroundColor={COLORS.TEXT_LIGHT_PURPLE}
                  />
                )}
              </Wrapper>
            )}
            <Margin vertical="lg">
              <Label
                text={i18n.t('positiveEvaluations', {
                  ratingGood: profile?.userEvaluations?.good ?? 0,
                })}
                backgroundColor="#89A3AA"
              />
              <Margin marginTop="s">
                <Label
                  text={i18n.t('negativeEvaluations', {
                    ratingBad: profile?.userEvaluations?.bad ?? 0,
                  })}
                  textColor={isMobile ? COLORS.WHITE : COLORS.BACKGROUND_PRIMARY}
                  backgroundColor={isMobile ? COLORS.BACKGROUND_PRIMARY : COLORS.BACKGROUND_LIGHT}
                />
              </Margin>
            </Margin>
          </Wrapper>
          <Wrapper f={1} fd="column" jc="flex-end">
            {options.map((option, i) => (
              <Fragment key={`${option.text}-${i}`}>
                {i !== 0 && <Wrapper h={1} bc="#E6E6E6" />}
                <Button
                  activeOpacity={0.925}
                  style={{ width: isMobile ? windowWidth : DESKTOP_TABLET_WIDTH }}
                  onPress={() => {
                    if (option?.onPress) {
                      option.onPress();
                    }
                  }}
                >
                  <SettingsRow {...option} index={i} />
                </Button>
              </Fragment>
            ))}
          </Wrapper>
        </Wrapper>
      </FullWrapperHeight>
    </SafeAreaView>
  );
};

export default HomePage;
