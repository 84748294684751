/* Onboarding */
export const ONBOARDING = 'onboarding';
export const ONBOARDING_INTRODUCTION = 'introduction';

/* Unathorized */
export const UNAUTHORIZED = 'unauthorized';

/* Login */
export const LOGIN = 'login';

/* Find tickets */
export const FIND_TICKETS_AND_PROFILES = 'find-tickets-and-profiles';

/* Loading */
export const LOADING = 'loading';

/* Chat */
export const CHAT = 'chat';

/* Messages */
export const MESSAGES = 'messages';

/* Tabs */
export const TABS = 'tabs';

/* Redirect */
export const REDIRECT = 'redirect';

/* Home */
export const HOME = 'home';
export const FAVORITES = 'favorites';
export const UPDATE_USER = 'update-user';
export const UPCOMING = 'upcoming';
export const SUPPORT = 'support';
export const PRIVACY_POLICY = 'privacy-policy';

/* Create */
export const CREATE = 'create';
export const EDIT = 'edit';

/* Forgot password */
export const RESET_PASSWORD = 'reset-password';
export const FORGOT_PASSWORD = 'forgot-password';
export const PROVIDE_EMAIL = 'email';
export const EMAIL_SENT = 'sent';

/* Seach drawer */
export const SEARCH = 'search';
export const DRAWER_AND_TABBAR = 'drawer';

/* Sunset */
export const SUNSET = 'sunset';
