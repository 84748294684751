import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import api from 'reducers/slices/api';
import { userSelector } from 'reducers/slices/user';
import { shallowEqual } from 'react-redux';

export const useGetFreshChatInformation = () => {
  const { getUserByAttribute } = api.endpoints;
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector, shallowEqual);

  return useCallback(
    async ({ data, chat, profileId, favoriteId }: any) => {
      const { refetch } = dispatch(getUserByAttribute.initiate(favoriteId ?? data.createdByUserId));
      const { id: userId } = user ?? {};
      const { data: latestUserData } = await refetch();
      const { user: latestUser } = latestUserData ?? {};

      if (Object.keys(chat ?? {}).length > 0) {
        return chat;
      } else if (favoriteId) {
        return {
          id: uuidv4(),
          img: user?.img,
          name: user?.name,
          favoriteName: latestUser?.name,
          favoriteImg: latestUser?.img,
          favoriteInitials: latestUser?.name?.slice(0, 2)?.toUpperCase(),
          isFavorite: true,
          favoriteId,
          chatOwnerId: userId,
          chatOwnerRecommendations: latestUser.userEvaluations,
          chatParticipantIds: [favoriteId, userId],
          initials: user?.name?.slice(0, 2)?.toUpperCase(),
          messages: [],
        };
      } else {
        return {
          id: uuidv4(),
          img: data?.img,
          isLendProfile: data?.isLendProfile,
          chatOwnerId: data.createdByUserId,
          chatOwnerRecommendations: latestUser.userEvaluations,
          chatParticipantIds: [data.createdByUserId, userId],
          ticketId: data.id,
          profileId,
          periodStart: data.periodStart,
          periodEnd: data.periodEnd,
          isIncludingAllOfSkaene: data?.isIncludingAllOfSkaene,
          from: data.from,
          to: data.to,
          initials: data?.initials?.slice(0, 2)?.toUpperCase(),
          messages: [],
        };
      }
    },
    [user, getUserByAttribute, dispatch],
  );
};
