import React, { useRef, useEffect, MutableRefObject } from 'react';
import { Platform, View } from 'react-native';
import { useHeaderHeight } from '@react-navigation/elements';
import { useTheme } from 'styled-components/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Animated, {
  useAnimatedStyle,
  useAnimatedReaction,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import { useKeyboardDimensions } from '@lendticket/ui/hooks';
import { DEFAULT_SPRING_CONFIG } from '@lendticket/ui/constants';

const ANDROID_TABLET_NATIVE_NAVIGATION_HEIGHT = 60;
/* NOTE: On a real Android device then this is needed */
const TABLET_EXTRA_HEIGHT = 24;
const isWeb = Platform.OS === 'web';
const isIOS = Platform.OS === 'ios';
const isAndroid = Platform.OS === 'android';

interface KeyboardAvoid {
  isModal?: boolean;
  isRelativeToParent?: boolean;
  parentRef?: MutableRefObject<null>;
  children: React.ReactNode;
}

const KeyboardAvoid: React.FC<KeyboardAvoid> = ({
  isRelativeToParent,
  isModal,
  parentRef,
  children,
}) => {
  const childRef = useRef(null);
  const { settings, deviceSizes } = useTheme();
  const { isTablet } = deviceSizes;
  const { SPACINGS } = settings;
  const { top: IOSTop, bottom } = useSafeAreaInsets();
  const iOSHeaderHeight = useHeaderHeight();

  const componentHeight = useSharedValue(0);
  const componentPositionY = useSharedValue(0);
  const translateY = useSharedValue(0);
  const keyboardValues = useKeyboardDimensions();
  const animatedStyle = useAnimatedStyle(() => ({
    width: '100%',
    transform: [
      {
        translateY: translateY.value,
      },
    ],
  }));

  useAnimatedReaction(
    () => ({ componentHeight, componentPositionY, keyboardValues }),
    res => {
      if (!isWeb && componentHeight.value > 0) {
        const keyboardPositionY = res.keyboardValues.value.coordinates.end.screenY;
        const keyboardHeight = res.keyboardValues.value.keyboardHeight;
        const overlapDiff = componentPositionY.value - keyboardPositionY;
        const diff = componentHeight.value + overlapDiff;
        const offsetIOS = isModal ? SPACINGS.md : SPACINGS.xxxxl;
        const translateYEndPosition = isRelativeToParent
          ? -diff + (isIOS ? offsetIOS : -SPACINGS.md)
          : -keyboardHeight;

        const derivedIOSTranslateYEndPosition = isIOS && isTablet ? bottom : isAndroid ? 30 : 0;

        if (res.keyboardValues.value.isVisible) {
          translateY.value = withSpring(
            isAndroid ? -25 : translateYEndPosition + derivedIOSTranslateYEndPosition,
            DEFAULT_SPRING_CONFIG,
          );
        } else {
          translateY.value = withSpring(0, DEFAULT_SPRING_CONFIG);
        }
      }
    },
    [componentHeight, componentPositionY, keyboardValues],
  );

  /* NOTE: This is a customized solution that respects the animation time for modals before
  calculating its measurements. */
  useEffect(() => {
    if (isModal) {
      setTimeout(() => {
        if (childRef?.current && parentRef?.current) {
          // @ts-expect-error bug in @types/react-native (the type is never) */
          childRef.current.measureLayout(parentRef.current, (left, top, width, height) => {
            const offsetIOS = iOSHeaderHeight + IOSTop;
            componentPositionY.value = (isIOS ? offsetIOS : 0) + top;
            componentHeight.value = height;
          });
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View
      ref={childRef}
      onLayout={e => {
        if (!isModal) {
          e.target?.measure((x, y, width, height, pageX, pageY) => {
            if (componentPositionY.value === 0) {
              componentPositionY.value = pageY;
              componentHeight.value = height;
            }
          });
        }
      }}
    >
      <Animated.View style={animatedStyle}>{children}</Animated.View>
    </View>
  );
};

export default KeyboardAvoid;
