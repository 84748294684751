import React from 'react';
import { useTheme } from 'styled-components/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Animated from 'react-native-reanimated';
import { Wrapper } from '@lendticket/ui/components/atoms';
import { Gradient, FullWrapperHeight } from '@lendticket/ui/components/molecules';
import { SwiperButton } from '@lendticket/ui/components/organisms';
import { useI18n } from '@lendticket/app/src/hooks/i18n';

interface LoginPageProps {
  inputFieldsComponent: React.ReactNode;
  animatedValues?: {
    swiperWidth: Animated.SharedValue<number>;
    translationX: Animated.SharedValue<number>;
  };
}

const LoginPage: React.FC<LoginPageProps> = ({
  animatedValues,
  inputFieldsComponent: InputFieldsComponent,
}) => {
  const i18n = useI18n();
  const { deviceSizes, settings } = useTheme();
  const { CONSTANTS, SPACINGS } = settings;
  const { isMobile } = deviceSizes;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;

  return (
    <SafeAreaView>
      <Gradient />
      <FullWrapperHeight hasBottomTabBar={false}>
        <Wrapper f={1} w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}>
          <Wrapper style={{ paddingLeft: SPACINGS.md, paddingRight: SPACINGS.md }}>
            <Wrapper fd="column" jc="center" ai="flex-start">
              <SwiperButton
                textLeft={i18n.t('login')}
                textRight={i18n.t('createUser')}
                animatedValues={animatedValues}
              />
            </Wrapper>
            {InputFieldsComponent}
          </Wrapper>
        </Wrapper>
      </FullWrapperHeight>
    </SafeAreaView>
  );
};

export default LoginPage;
