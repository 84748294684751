import { getDeviceSizes } from './getDeviceSizes';
import { getTextStyle } from './getTextStyle';
import { getAnimatedMeasures } from './getAnimatedMeasures';
import { removeCommaAndSpacesFromString } from './removeCommasAndSpacesFromString';
import { capitalizeFirstLetter } from './capitalizeFirstLetter';

export {
  getDeviceSizes,
  getTextStyle,
  getAnimatedMeasures,
  capitalizeFirstLetter,
  removeCommaAndSpacesFromString,
};
