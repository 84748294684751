import { useCallback } from 'react';
import { useAppSelector } from 'hooks/redux';
import { userSelector } from 'reducers/slices/user';
import { shallowEqual } from 'react-redux';

export const useUpdateChats = () => {
  const user = useAppSelector(userSelector, shallowEqual);

  return useCallback(
    async ({ liveChats, newChat, callback }: any) => {
      const { id: userId } = user ?? {};

      if (newChat.chat.chatParticipantIds.includes(userId)) {
        const hasChat = liveChats.chatIds.includes(newChat.chat.id || newChat.chat.ticketId);

        if (hasChat) {
          /* NOTE: You need to update a chat message */
          const updatedChats = liveChats.chats.map((item: any) => {
            if ([item.id, item.ticketId].includes(newChat.chat.id)) {
              return {
                ...item,
                messages: [...item.messages, newChat.newMessage],
              };
            }

            return item;
          });

          callback({
            ...liveChats,
            chats: updatedChats,
            chatIds: updatedChats?.map(({ id }: { id: string }) => id),
          });
        } else {
          /* NOTE: You need to create a new chat message */
          const newChats = [newChat.chat, ...liveChats.chats].map((item, i) => {
            if (i === 0 && item.messages.length === 0) {
              return {
                ...item,
                messages: [newChat.newMessage],
              };
            }

            return item;
          });

          callback({
            ...liveChats,
            chats: newChats,
            chatIds: newChats.map(({ id }) => id),
            hasChats: newChats?.length > 0,
            newChatIds: [newChat.chat.id],
          });
        }
      }
    },
    [user],
  );
};
