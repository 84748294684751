import React, { useContext } from 'react';
import { Platform } from 'react-native';
import { NativeViewGestureHandler as RNGHNativeViewGestureHandler } from 'react-native-gesture-handler';
import { GestureContext } from '@lendticket/ui/containers/GestureProvider';

const isWeb = Platform.OS === 'web';

const NativeViewGestureHandler: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { nativeScrollRef, tapRef, panRef } = useContext(GestureContext);

  return (
    <RNGHNativeViewGestureHandler
      ref={nativeScrollRef}
      waitFor={isWeb ? tapRef : undefined}
      simultaneousHandlers={panRef}
      disallowInterruption
    >
      {children}
    </RNGHNativeViewGestureHandler>
  );
};

export default NativeViewGestureHandler;
