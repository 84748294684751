import { useEffect, useRef, useState, useCallback } from 'react';
import { getDeviceSizes } from '../helpers/getDeviceSizes';

interface ReturnOutput {
  windowDimensions: {
    windowWidth: number;
    windowHeight: number;
  };
  deviceSizes: {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
  };
}

export const useResponsiveMeasures = (): ReturnOutput => {
  const deviceSizeObserver = useRef<ReturnOutput['deviceSizes'] | null>(null);
  const [deviceSizes, setDeviceSizes] = useState(
    getDeviceSizes(global.window.innerWidth, global.window.innerHeight),
  );

  const [windowDimensions, setWindowDimensions] = useState({
    windowWidth: global.window.innerWidth,
    windowHeight: global.window.innerHeight,
  });

  const updateWidth = useCallback(() => {
    const currentDeviceSizes = getDeviceSizes(global.window.innerWidth, global.window.innerHeight);
    setWindowDimensions({
      windowWidth: global.window.innerWidth,
      windowHeight: global.window.innerHeight,
    });

    if (JSON.stringify(currentDeviceSizes) !== JSON.stringify(deviceSizeObserver.current)) {
      deviceSizeObserver.current = currentDeviceSizes;
      setDeviceSizes(currentDeviceSizes);
    }
  }, []);

  useEffect(() => {
    global.window.addEventListener('resize', updateWidth);

    return (): any => global?.window?.removeEventListener('resize', updateWidth);
  }, []);

  return {
    deviceSizes,
    windowDimensions,
  };
};
