export interface DeviceSizes {
  isGalaxyFold: boolean;
  isMicroDevice: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
}

export const getDeviceSizes = (deviceWidth: number, deviceHeight: number): DeviceSizes => ({
  isGalaxyFold: deviceWidth > 0 && deviceWidth <= 280,
  isMicroDevice: deviceWidth >= 0 && deviceWidth <= 320,
  isMobile:
    (deviceWidth >= 0 && deviceWidth <= 767) ||
    (deviceWidth >= 568 && deviceWidth < 1024 && deviceHeight <= 767),
  isTablet: deviceWidth >= 767 && deviceWidth <= 1024 && deviceHeight >= 767,
  isLaptop: deviceWidth >= 1024 && deviceWidth <= 1280 && deviceHeight >= 767,
  isDesktop: deviceWidth >= 1280 && deviceHeight >= 767,
});
