import styled from 'styled-components/native';

const MARGIN_WIDTH = 40;
const MARGIN_WIDTH_MOBILE = 16;

const MarginColumn: React.FC = styled.View`
  width: ${({ theme }) => {
    const { deviceSizes } = theme;
    const { isMobile } = deviceSizes;

    return isMobile ? MARGIN_WIDTH_MOBILE : MARGIN_WIDTH;
  }}px;
  height: 100%;
  user-select: none;
`;

export default MarginColumn;
