import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type { TagTypes } from '.';

export const lendtickets = (
  build: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
    TagTypes,
    'api'
  >,
) => ({
  getLendTicketByTicketIdAndUserId: build.query<any, any>({
    query: searchParams =>
      `/lendtickets/ticket/${searchParams.ticketId}/user/${searchParams.userId}`,
  }),
  getLendTicketByTicketId: build.query<any, void>({
    query: ticketId => `/lendtickets/ticket/id/${ticketId}`,
  }),
  getLendTicketsByUserId: build.query<any, string>({
    query: userId => `/lendtickets/ticket/user/${userId}`,
  }),
  getLendTickets: build.query<any, void>({
    query: () => '/lendtickets',
  }),
  createLendTicket: build.mutation<any, any>({
    query: (data: any) => ({
      url: '/lendtickets/create',
      method: 'POST',
      body: data,
    }),
  }),
  deleteLendTicket: build.mutation<any, any>({
    query: (id: string) => ({
      url: `/lendtickets/ticket/${id}`,
      method: 'DELETE',
    }),
  }),
});

export const selectApiTickets = (state: any) => {
  const { queries } = state.api;
  const queryIndex = Object.keys(queries).findIndex(key => key.includes('getLendTickets'));

  if (queryIndex > 0) {
    const { data } = queries[Object.keys(queries)[queryIndex]] ?? {};

    if (data?.status === 200) {
      return data.tickets;
    }
  }
};
