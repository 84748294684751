import React from 'react';
import { useTheme } from 'styled-components/native';
import { Wrapper, Text, Margin } from '@lendticket/ui/components/atoms';

const PADDING = '2px 12px';
const BORDER_RADIUS = 7;

const SearchCriteriaBox: React.FC = () => {
  const { settings } = useTheme();
  const { COLORS } = settings;

  return (
    <Margin marginRight="s">
      <Wrapper
        b={`2px solid ${COLORS.BACKGROUND_LIGHT}`}
        jc="center"
        pg={PADDING}
        br={BORDER_RADIUS}
      >
        <Text fontType="body" style={{ color: COLORS.WHITE }}>
          8 timers billet x
        </Text>
      </Wrapper>
    </Margin>
  );
};

export default SearchCriteriaBox;
