import React, { useEffect } from 'react';
import { useTheme } from 'styled-components/native';
import { Text, Wrapper, Margin, Button } from '@lendticket/ui/components/atoms';
import { Label } from '@lendticket/ui/components/molecules';
import { AnimatedEvaluationButton } from '@lendticket/ui/components/organisms';
import Animated, { withTiming, useAnimatedStyle, useSharedValue } from 'react-native-reanimated';
import { alertMessage } from '@lendticket/app/src/helpers/alertMessage';
import { useI18n } from '@lendticket/app/src/hooks/i18n';

interface AreYouSatisfiedWithUserProps {
  handleUserEvaluations: (value: string) => void;
  isUserEvaluationsVisible: Animated.SharedValue<boolean>;
  isChatCreated: boolean;
  ratingGood: number;
  ratingBad: number;
}

const AreYouSatisfiedWithUser: React.FC<AreYouSatisfiedWithUserProps> = ({
  isChatCreated,
  ratingGood,
  ratingBad,
  isUserEvaluationsVisible,
  handleUserEvaluations,
}) => {
  const i18n = useI18n();
  const { settings, deviceSizes } = useTheme();
  const { isMobile } = deviceSizes;
  const { COLORS } = settings;
  const translationYEvaluations = useSharedValue(-5);
  const displayEvaluations = useSharedValue('none');

  useEffect(() => {
    if (!isChatCreated) {
      /* NOTE: This is a wierd hack towards solving this want. It seems like this approach
      respects the UI thread asynchrousnesly together with other animation activities. Alternatively
      it should be possible to write the animation without timing but doing so will make this
      behaviour inconsistent. */
      translationYEvaluations.value = withTiming(0, { duration: 0 }, isAnimationDone => {
        if (isAnimationDone) {
          displayEvaluations.value = 'flex';
          isUserEvaluationsVisible.value = true;
        }
      });
    }
  }, [displayEvaluations, isChatCreated, isUserEvaluationsVisible, translationYEvaluations]);

  const animatedStyleEvaluations = useAnimatedStyle(() => ({
    display: displayEvaluations.value,
    transform: [
      {
        translateY: translationYEvaluations.value,
      },
    ],
  }));

  return (
    <Wrapper fd="column" jc="center">
      <Wrapper jc="center" ai="center" fd="row">
        <AnimatedEvaluationButton
          onPress={() => {
            if (!isChatCreated) {
              alertMessage(i18n.t('youCantJudgePriorToStart'));
            } else {
              handleUserEvaluations('good');
            }
          }}
          backgroundColor="#89A3AA"
          text={i18n.t('yes')}
          textColor={COLORS.WHITE}
        />
        <Margin marginLeft="s" />
        <AnimatedEvaluationButton
          onPress={() => {
            if (!isChatCreated) {
              alertMessage(i18n.t('youCantJudgePriorToStart'));
            } else {
              handleUserEvaluations('bad');
            }
          }}
          text={i18n.t('no')}
          textColor={isMobile ? COLORS.WHITE : COLORS.BACKGROUND_PRIMARY}
          backgroundColor={isMobile ? COLORS.BACKGROUND_PRIMARY : COLORS.BACKGROUND_LIGHT}
        />
      </Wrapper>
      <Margin marginTop="s" />
      <Text
        fontType={isMobile ? 'subtitle' : 'body'}
        style={{
          textAlign: 'center',
          color: isMobile ? COLORS.TEXT_LIGHT_PURPLE : COLORS.WHITE,
        }}
      >
        {i18n.t('areYouSatisfiedWithUser')}
      </Text>
      <Button
        onPress={() => {
          const isDisplayValueNone = displayEvaluations.value === 'none';
          isUserEvaluationsVisible.value = isDisplayValueNone;
          displayEvaluations.value = isDisplayValueNone ? 'flex' : 'none';
          translationYEvaluations.value = withTiming(isDisplayValueNone ? 0 : -5, {
            duration: 250,
          });
        }}
      >
        <Text
          fontType={isMobile ? 'subtitle' : 'body'}
          style={{
            textAlign: 'center',
            color: isMobile ? COLORS.TEXT_LIGHT_PURPLE : COLORS.WHITE,
          }}
        >
          {i18n.t('showEvaluations')}
        </Text>
        <Margin marginLeft="xxs" />
      </Button>
      <Animated.View style={animatedStyleEvaluations}>
        <Margin vertical="s">
          <Label text={i18n.t('positiveEvaluations', { ratingGood })} backgroundColor="#89A3AA" />
          <Margin marginTop="s">
            <Label
              text={i18n.t('negativeEvaluations', { ratingBad })}
              textColor={isMobile ? COLORS.WHITE : COLORS.BACKGROUND_PRIMARY}
              backgroundColor={isMobile ? COLORS.BACKGROUND_PRIMARY : COLORS.BACKGROUND_LIGHT}
            />
          </Margin>
        </Margin>
      </Animated.View>
      <Margin marginBottom="s" />
    </Wrapper>
  );
};

export default AreYouSatisfiedWithUser;
