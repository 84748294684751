import { cancelAnimation } from 'react-native-reanimated';
import type { GestureParams } from '../';

export const onStart = ({
  translationX,
  ctx,
}: {
  translationX: GestureParams['translationX'];
  ctx: any;
}) => {
  'worklet';

  cancelAnimation(translationX);
  ctx.startX = translationX.value;
};
