import React from 'react';
import { Platform, Linking } from 'react-native';
import styled from 'styled-components/native';
import { Wrapper, Margin } from '@lendticket/ui/components/atoms';
import { Icon } from '@lendticket/ui/components/molecules';

const ICON_WIDTH = 119.664;
const ICON_HEIGHT = 40;

const { default: googleImg } = require('@lendticket/ui/assets/imgs/google-play-badge.png');

const ImageWrapper = styled.Image`
  height: ${ICON_HEIGHT}px;
  width: ${ICON_WIDTH}px;
`;
const TouchableOpacity = styled.TouchableOpacity``;

const AppAndPlayStore: React.FC = () =>
  Platform.select({
    default: null,
    web: (
      <Wrapper fd="column" jc="center">
        <Margin marginTop="md">
          <TouchableOpacity
            onPress={() =>
              Linking.openURL('https://apps.apple.com/se/app/lend-ticket/id6445863215')
            }
          >
            <Icon name="appStore" height={ICON_HEIGHT} width={ICON_WIDTH} />
          </TouchableOpacity>
        </Margin>
        <Margin marginTop="xs">
          <TouchableOpacity
            onPress={() => {
              Linking.openURL('https://play.google.com/store/apps/details?id=com.lendticket');
            }}
          >
            <ImageWrapper source={googleImg} />
          </TouchableOpacity>
        </Margin>
      </Wrapper>
    ),
  });

export default AppAndPlayStore;
