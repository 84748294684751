import { createSlice, PayloadAction, createEntityAdapter } from '@reduxjs/toolkit';
import { RootState } from '../../helpers/redux/store';

export interface UserState {
  info: any;
}

export const initialState: UserState = {
  info: undefined,
};

export const userAdapter = createEntityAdapter<UserState>();

export const { reducer: userReducer, actions: userActions } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser: (state, action: PayloadAction<any>) => {
      state.info = action.payload;
    },
    redirectUserToOnboarding: (state, action) => {
      state.info = {
        ...state.info,
        redirectUserToOnboarding: action.payload,
      };
    },
    redirectUserToHome: (state, action) => {
      state.info = {
        ...state.info,
        redirectUserToHome: action.payload,
      };
    },
    resetUserInfo: state => {
      state.info = initialState.info;
    },
    resetUserRedirect: state => {
      delete state.info?.redirectUserToHome;
      delete state.info?.redirectUserToOnboarding;
    },
  },
});

export const userSelector = (state: RootState) => state.root.user.info;
