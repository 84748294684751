import { useCallback } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigation } from '@react-navigation/native';
import { useAppDispatch } from 'hooks/redux';
import * as routeNames from 'constants/routeNames';
import persisted from 'helpers/storage/persisted';
import { userActions } from 'reducers/slices/user';
import { useRegisterNotification } from './useRegisterNotification';
import { isWeb } from 'helpers/platform';

export const useUpdateUserSetAuthTokenAndRedirect = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const registerNotification = useRegisterNotification();

  return useCallback(
    async (data?: any, withNavigation = true) => {
      if (data) {
        let authToken = data?.token;

        persisted.setItem('authToken', data.token);
        const decodedToken = jwt_decode(data.token);
        dispatch(userActions.addUser(decodedToken));

        if (!isWeb) {
          registerNotification({ authToken, withNavigation });
        } else if (withNavigation) {
          (navigation as any).navigate(routeNames.DRAWER_AND_TABBAR, {
            screen: routeNames.TABS,
            params: { screen: routeNames.HOME },
          });
        }
      } else if (withNavigation) {
        (navigation as any).navigate(routeNames.DRAWER_AND_TABBAR, {
          screen: routeNames.TABS,
          params: { screen: routeNames.HOME },
        });
      }
    },
    [dispatch, navigation, registerNotification],
  );
};
