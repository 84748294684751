import React from 'react';
import { useTheme } from 'styled-components/native';
import { Wrapper, BorderButton, Text } from '@lendticket/ui/components/atoms';

const DEFAULT_BUTTON_WIDTH = 500;

interface FooterForgotPasswordPageProps {
  footerDescription?: string;
  footerText: string;
  footerOnPress: () => void;
}

const FooterForgotPasswordPage: React.FC<FooterForgotPasswordPageProps> = ({
  footerText,
  footerDescription,
  footerOnPress,
}) => {
  const { settings, deviceSizes, windowDimensions } = useTheme();
  const { windowWidth } = windowDimensions;
  const { isMobile } = deviceSizes;
  const { SPACINGS, COLORS } = settings;

  return (
    <Wrapper mb={SPACINGS.md}>
      <BorderButton
        onPress={footerOnPress}
        w={isMobile ? windowWidth - SPACINGS.xl * 2 : DEFAULT_BUTTON_WIDTH}
        jc="center"
        ai="center"
        title={footerText}
      />
      {footerDescription && (
        <Wrapper
          mt={SPACINGS.ms}
          w={isMobile ? windowWidth - SPACINGS.xl * 2 : DEFAULT_BUTTON_WIDTH}
        >
          <Text fontType="body" style={{ textAlign: 'center', color: COLORS.WHITE }}>
            {footerDescription}
          </Text>
        </Wrapper>
      )}
    </Wrapper>
  );
};

export default FooterForgotPasswordPage;
