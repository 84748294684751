import React from 'react';
import { Control } from 'react-hook-form';
import { FlatList, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import styled, { useTheme } from 'styled-components/native';
import { BottomNavigationBar, Appearance } from '@lendticket/ui/components/organisms';
import { Wrapper, MarginColumn, Text, Margin } from '@lendticket/ui/components/atoms';
import { FullWrapperHeight, Gradient, Card, NoContent } from '@lendticket/ui/components/molecules';
import Search from './Search';
import { useI18n } from '../../../../app/src/hooks/i18n';

const isWeb = Platform.OS === 'web';
const ANDROID_FADING_EDGE_LENGTH = 16;
export interface FavoriteParams {
  id?: string;
  name?: string;
  initials?: string;
  from?: string;
  to?: string;
  img?: string;
  introText?: string;
}

interface FavoritesPageProps {
  favorites: FavoriteParams[];
  hasFavorites: boolean;
  hiddenCardText: string;
  introText: string;
  newFavoriteIds: string[];
  control: Control;
  isInitiated: boolean;
  onPressAction: (props: any) => void;
  onSwipeDeleteFavorite: (userId: string) => void;
  onSearchChange: (string: value) => void;
}

const Seperator = styled.View`
  margin-top: ${({ theme }) => theme.settings.SPACINGS.md}px;
`;

const FavoritesPage: React.FC<FavoritesPageProps> = ({
  onPressAction,
  onSwipeDeleteFavorite,
  onSearchChange,
  newFavoriteIds,
  favorites,
  hasFavorites,
  hiddenCardText,
  introText,
  control,
  isInitiated,
}) => {
  const i18n = useI18n();
  const { deviceSizes, settings } = useTheme();
  const { SPACINGS, CONSTANTS, COLORS } = settings;
  const { isMobile } = deviceSizes;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;

  return (
    <SafeAreaView>
      <Gradient />
      <FullWrapperHeight hasBottomTabBar={!isWeb} footer={isWeb && <BottomNavigationBar />}>
        <Wrapper f={1} w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}>
          <Wrapper f={1} fd="row" ai="flex-start" mb={SPACINGS.md}>
            <MarginColumn />
            <Wrapper f={1} fd="column" ai="flex-start" style={{ alignSelf: 'stretch' }}>
              {!isInitiated && (
                <Wrapper jc="center" ai="center">
                  <Text fontType={isWeb ? 'h5' : 'body'} style={{ color: 'white' }}>
                    {i18n.t('pleaseWaitFetchingFavorites')}
                  </Text>
                </Wrapper>
              )}
              {isInitiated && !hasFavorites && <NoContent text={i18n.t('noFavorites')} />}
              {isInitiated && hasFavorites && (
                <>
                  <Wrapper style={{ marginTop: SPACINGS.s, marginBottom: SPACINGS.md }}>
                    <Text fontType={isWeb ? 'h5' : 'body'} style={{ color: COLORS.WHITE }}>
                      {introText}
                    </Text>
                  </Wrapper>
                  <Search control={control} onSearchChange={onSearchChange} />
                  <Margin marginTop="md" />
                  <FlatList
                    fadingEdgeLength={ANDROID_FADING_EDGE_LENGTH}
                    style={{ width: '100%' }}
                    refreshing={false}
                    data={favorites}
                    ItemSeparatorComponent={Seperator}
                    renderItem={({ item, index }) =>
                      newFavoriteIds.includes(item.id) ? (
                        <Appearance delay={(index + 1) * 100}>
                          <Card
                            isFavorite
                            onPressAction={() => onPressAction({ id: item.id })}
                            onSwipeAction={() => onSwipeDeleteFavorite(item.id)}
                            hiddenCardText={hiddenCardText}
                            {...item}
                          />
                        </Appearance>
                      ) : (
                        <Card
                          isFavorite
                          onPressAction={() => onPressAction({ id: item.id })}
                          onSwipeAction={() => onSwipeDeleteFavorite(item.id)}
                          hiddenCardText={hiddenCardText}
                          {...item}
                        />
                      )
                    }
                    keyExtractor={({ id }, i) => `${id}-${i}`}
                  />
                </>
              )}
            </Wrapper>
            <MarginColumn />
          </Wrapper>
        </Wrapper>
      </FullWrapperHeight>
    </SafeAreaView>
  );
};

export default FavoritesPage;
