import React, { useRef, useState, useCallback } from 'react';
import { Platform, TextInput as RNTextInput, KeyboardTypeOptions } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { Wrapper } from '@lendticket/ui/components/atoms';

const isWeb = Platform.OS === 'web';
const INPUT_HEIGHT = 50;
interface InputFieldProps {
  placeholder: string;
  inputRef?: React.RefObject<RNTextInput | null>;
  desktopWidth?: number;
  error?: boolean;
  isPassword?: boolean;
  hasFlexibleHeight?: boolean;
  hasDefaultHeight?: boolean;
  hasMultiline?: boolean;
  multiline?: boolean;
  hasBorder?: boolean;
  hasFullWidth?: boolean;
  editable?: boolean;
  hasPaddingLeft?: boolean;
  keyboardType?: KeyboardTypeOptions;
  style?: any;
  value?: any;
  textStyle?: any;
  placeholderTextColor?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange: (value: any) => void;
  onKeyDown?: () => void;
}

const BORDER_BOTTOM_COLOR = 'rgba(255,255,255,0.5)';
const PLACEHOLDER_TEXT_COLOR = 'rgba(255,255,255,0.75)';
const PLACEHOLDER_TEXT_COLOR_NOT_EDITABLE = 'rgba(255,255,255,0.5)';

const TextInput = styled.TextInput``;

const InputField: React.FC<InputFieldProps> = ({
  hasFlexibleHeight,
  hasBorder,
  inputRef,
  hasPaddingLeft = true,
  placeholder,
  keyboardType,
  isPassword,
  editable,
  value,
  style,
  textStyle,
  placeholderTextColor,
  onKeyDown,
  onChange,
  onBlur,
  ...rest
}) => {
  const defaultRef = useRef(null);
  const [heights, setHeights] = useState({
    defaultHeight: 0,
    height: 0,
  });

  const handleKeyDownOnEnter = useCallback(
    e => {
      if (isWeb && e.nativeEvent.key === 'Enter' && onKeyDown) {
        onKeyDown();
      }
    },
    [onKeyDown],
  );

  const handleSubmit = () => {
    let derivedRef: any = inputRef?.current ?? defaultRef.current;

    if (derivedRef?.current) {
      derivedRef.current.blur(); // Manually dismiss the keyboard
    }
  };

  const theme = useTheme();
  const { COLORS } = theme.settings;

  const TEXT_INPUT_STYLE = {
    fontFamily: 'Hubot-Sans',
    fontSize: 16,
    paddingLeft: hasPaddingLeft ? 16 : 0,
    overflow: 'hidden',
    color: COLORS.WHITE,
    textShadow: 'none',
    fontWeight: 'bold',
    outline: 'none',
    textAlign: 'left',
    width: '100%',
    ...textStyle,
  };

  return (
    <Wrapper
      f={1}
      h={INPUT_HEIGHT}
      jc="center"
      ai="flex-start"
      style={{
        borderBottomWidth: hasBorder ? 2 : 0,
        borderBottomColor: BORDER_BOTTOM_COLOR,
      }}
    >
      <TextInput
        multiline
        blurOnSubmit
        clearButtonMode="always"
        editable={editable}
        ref={inputRef ?? defaultRef}
        textAlignVertical="center"
        returnKeyType="default"
        numberOfLines={1}
        style={TEXT_INPUT_STYLE as any}
        placeholder={placeholder}
        onSubmitEditing={handleSubmit}
        onBlur={onBlur}
        onKeyPress={handleKeyDownOnEnter}
        onContentSizeChange={event => {
          if (hasFlexibleHeight) {
            setHeights({
              defaultHeight: heights.defaultHeight
                ? event.nativeEvent.contentSize.height
                : heights.defaultHeight,
              height: event.nativeEvent.contentSize.height,
            });
          }
        }}
        placeholderTextColor={
          placeholderTextColor
            ? placeholderTextColor
            : editable
            ? PLACEHOLDER_TEXT_COLOR
            : PLACEHOLDER_TEXT_COLOR_NOT_EDITABLE
        }
        keyboardType={keyboardType ?? 'default'}
        secureTextEntry={isPassword}
        onChangeText={v => {
          if (hasFlexibleHeight && value === '' && heights.height > 0) {
            setHeights({
              ...heights,
              height: heights.defaultHeight,
            });
          }

          onChange(v);
        }}
        {...rest}
      />
    </Wrapper>
  );
};

export default InputField;
