import React from 'react';
import { Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import Animated, { withTiming, useSharedValue, useAnimatedStyle } from 'react-native-reanimated';
import { Button, Wrapper, Text } from '@lendticket/ui/components/atoms';

const isWeb = Platform.OS === 'web';
const BORDER_RADIUS = 5;
const ICON_DIMENSIONS = 30;

interface AnimatedEvaluationButtonProps {
  backgroundColor: string;
  textColor?: string;
  text: string;
  onPress: () => void;
}

const AnimatedEvaluationButton: React.FC<AnimatedEvaluationButtonProps> = ({
  backgroundColor,
  textColor,
  text,
  onPress,
}) => {
  const { settings, deviceSizes } = useTheme();
  const { SHADOWS, COLORS } = settings;
  const scale = useSharedValue(1);

  const { isMobile } = deviceSizes;
  const { BOX_SHADOW, BOX_SHADOW_ANDROID_AND_IOS } = SHADOWS;
  const derivedTextColor = textColor ?? COLORS.WHITE;
  const fontType = isMobile ? 'body' : 'h4';
  const boxShadow: Object = isWeb ? { boxShadow: BOX_SHADOW.LIGHT } : BOX_SHADOW_ANDROID_AND_IOS;

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [
      {
        scale: scale.value,
      },
    ],
  }));

  return (
    <Button
      onPress={() => {
        scale.value = withTiming(0.9, { duration: 120 }, isAnimationDone => {
          if (isAnimationDone) {
            scale.value = withTiming(1, { duration: 120 });
          }
        });

        onPress();
      }}
    >
      <Animated.View style={animatedStyle}>
        <Wrapper
          bc={backgroundColor}
          jc="center"
          w={ICON_DIMENSIONS + 16}
          h={ICON_DIMENSIONS}
          style={{ borderRadius: BORDER_RADIUS, ...boxShadow }}
        >
          <Text fontType={fontType} style={{ color: derivedTextColor }}>
            {text}
          </Text>
        </Wrapper>
      </Animated.View>
    </Button>
  );
};

export default AnimatedEvaluationButton;
