import React from 'react';
import { Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { Wrapper, Margin, Text } from '@lendticket/ui/components/atoms';
import { FullWrapperHeight, Gradient } from '@lendticket/ui/components/molecules';
import { KeyboardAvoidingView } from '@lendticket/ui/components/organisms';

const isWeb = Platform.OS === 'web';

interface ForgotPasswordPageProps {
  teaserTitle: string;
  teaserText: string;
  footerComponent: React.ReactNode;
  notificationComponent: React.ReactNode;
  notificationInformation: React.ReactNode;
}

const UpdateUserPage: React.FC<ForgotPasswordPageProps> = ({
  teaserTitle,
  teaserText,
  footerComponent,
  notificationComponent: NotificationComponent,
  inputFieldsComponent: InputFieldsComponent,
}) => {
  const { deviceSizes, settings } = useTheme();
  const { CONSTANTS, COLORS, SHADOWS } = settings;
  const { isMobile } = deviceSizes;
  const { TEXT_SHADOW } = SHADOWS;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;

  return (
    <SafeAreaProvider>
      <SafeAreaView>
        <Gradient />
        <Wrapper>
          <FullWrapperHeight isModal hasBottomTabBar={false} footer={footerComponent}>
            <Wrapper f={1} w={isMobile ? undefined : DESKTOP_TABLET_WIDTH}>
              <KeyboardAvoidingView hasMarginTop={false}>
                <Margin marginBottom="md">
                  <Text
                    fontType={isWeb ? 'h3' : 'h4'}
                    style={{ color: COLORS.WHITE, textAlign: 'center', textShadow: TEXT_SHADOW }}
                  >
                    {teaserTitle}
                  </Text>
                  <Margin marginTop="m">
                    <Text
                      fontType="body"
                      style={{ color: COLORS.WHITE, textAlign: 'center', textShadow: TEXT_SHADOW }}
                    >
                      {teaserText}
                    </Text>
                  </Margin>
                </Margin>
                {InputFieldsComponent}
                {NotificationComponent}
              </KeyboardAvoidingView>
            </Wrapper>
          </FullWrapperHeight>
        </Wrapper>
      </SafeAreaView>
    </SafeAreaProvider>
  );
};

export default UpdateUserPage;
