import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../helpers/redux/store';

export interface State {
  shouldRedirect: boolean;
  loginUri?: string;
}

export const initialState: State = {
  shouldRedirect: false,
  loginUri: undefined,
};

export const { reducer: redirectReducer, actions: redirectActions } = createSlice({
  name: 'redirect',
  initialState,
  reducers: {
    updateRedirect: (state, action: PayloadAction<boolean>) => {
      state.shouldRedirect = action.payload;
    },
    updateLoginUri: (state, action: PayloadAction<string>) => {
      state.loginUri = action.payload;
    },
  },
});

export const redirectSelector = (state: RootState) => state.redirect;
