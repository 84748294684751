import { useMemo } from 'react';
import Animated from 'react-native-reanimated';

interface UseGetOnboardingFooterActionsParams {
  currentItem: Animated.SharedValue<number>;
  navigateToLoginScreen: () => void;
  navigateToTicketsAndProfilesOverview: () => void;
}

export const useGetOnboardingFooterActions = ({
  currentItem,
  navigateToLoginScreen,
  navigateToTicketsAndProfilesOverview,
}: UseGetOnboardingFooterActionsParams) =>
  useMemo(
    () => [
      {
        leftAction: () => (currentItem.value = 1),
        rightAction: () => (currentItem.value = 2),
      },
      {
        leftAction: navigateToTicketsAndProfilesOverview,
        rightAction: () => (currentItem.value = 2),
      },
      {
        leftAction: () => (currentItem.value = 4),
        rightAction: () => (currentItem.value = 3),
      },
      {
        leftAction: navigateToTicketsAndProfilesOverview,
        rightAction: navigateToLoginScreen,
      },
      {
        leftAction: navigateToTicketsAndProfilesOverview,
        rightAction: navigateToLoginScreen,
      },
    ],
    [currentItem, navigateToTicketsAndProfilesOverview, navigateToLoginScreen],
  );
