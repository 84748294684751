import React from 'react';
import { useTheme } from 'styled-components/native';
import { Text, Wrapper, Margin } from '@lendticket/ui/components/atoms';

const MIN_WIDTH_RATIO = 1.5;
const BORDER_RADIUS = 7;

interface ChatMessageProps {
  text: string;
  name?: string;
  isFlexEnd?: boolean;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ text, name, isFlexEnd }) => {
  const { windowDimensions, settings, deviceSizes } = useTheme();
  const { isMobile } = deviceSizes;
  const { CONSTANTS, COLORS, SPACINGS } = settings;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const { windowWidth } = windowDimensions;

  return (
    <>
      <Wrapper
        bc="#E5A5A2"
        ai={isFlexEnd ? 'flex-end' : 'flex-start'}
        style={{
          alignSelf: 'stretch',
          width: '100%',
          borderRadius: BORDER_RADIUS,
          maxWidth: isMobile
            ? windowWidth / MIN_WIDTH_RATIO
            : DESKTOP_TABLET_WIDTH / MIN_WIDTH_RATIO,
        }}
      >
        <Text
          selectable={true}
          fontType={isMobile ? 'body' : 'h4'}
          style={{
            paddingTop: SPACINGS.xs,
            paddingBottom: SPACINGS.xs,
            paddingRight: SPACINGS.md,
            paddingLeft: SPACINGS.md,
            color: COLORS.WHITE,
            textAlign: isFlexEnd ? 'right' : 'left',
          }}
        >
          {text}
        </Text>
      </Wrapper>
      <Margin marginTop="xs" />
      <Wrapper fd="row">
        {isFlexEnd && <Wrapper f={1} />}
        <Text fontType="body" style={{ color: COLORS.BACKGROUND_LIGHT }}>
          {name}
        </Text>
        {!isFlexEnd && <Wrapper f={1} />}
      </Wrapper>
    </>
  );
};

export default ChatMessage;
