import React from 'react';
import { ViewStyle } from 'react-native';
import Animated, {
  interpolate,
  withTiming,
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
  AnimatedStyleProp,
} from 'react-native-reanimated';

const ANIMATION_TIMER = 20;
const ANIMATION_DURATION = 250;
const NEGATIVE_TRANSLATE_X = -10;

interface AnimatedTextProps {
  isPanning: Animated.SharedValue<boolean>;
  activeIndex: Animated.SharedValue<number>;
  index: number;
  children: React.ReactNode;
}

const AnimatedText: React.FC<AnimatedTextProps> = ({ activeIndex, isPanning, index, children }) => {
  const animationClock = useSharedValue(0);
  const runAnimation = useSharedValue(false);

  const animatedStyle = useAnimatedStyle(
    (): AnimatedStyleProp<ViewStyle & { display: any } & { pointerEvents: string }> => ({
      display: activeIndex.value !== index ? 'none' : 'flex',
      pointerEvents: 'none',
      opacity:
        activeIndex.value === index
          ? 1
          : interpolate(animationClock.value, [0, ANIMATION_TIMER], [0, 1]),
      transform: [
        {
          translateX: interpolate(
            animationClock.value,
            [0, ANIMATION_TIMER],
            [-NEGATIVE_TRANSLATE_X, 0],
          ),
        },
      ],
    }),
  );

  useAnimatedReaction(
    () => activeIndex,
    res => {
      if (res.value === index && !runAnimation.value) {
        runAnimation.value = true;
        animationClock.value = 0;
        animationClock.value = withTiming(ANIMATION_TIMER, { duration: ANIMATION_DURATION });
      } else if (res.value !== index) {
        runAnimation.value = false;
      }
    },
    [activeIndex, isPanning],
  );

  return <Animated.View style={animatedStyle}>{children}</Animated.View>;
};

export default AnimatedText;
