import { useCallback } from 'react';
import api from 'reducers/slices/api';
import persisted from 'helpers/storage/persisted';
import { alertMessage } from 'helpers/alertMessage';
import { useUpdateUserSetAuthTokenAndRedirect } from './useUpdateUserSetAuthTokenAndRedirect';
import { loadingActions } from 'reducers/slices/loading';
import { useAppDispatch } from 'helpers/redux/store';

export const useLoginUser = () => {
  const [userLoginNormal] = api.useUserLoginNormalMutation();
  const updateUserSetAuthTokenAndRedirect = useUpdateUserSetAuthTokenAndRedirect();
  const dispatch = useAppDispatch();

  return useCallback(
    (data: any) => {
      userLoginNormal(data)
        .then(async (res: any) => {
          if (res.error) {
            dispatch(loadingActions.setLoadingContent({ isLoading: false }));
            alertMessage(res.error.data.message);
            return;
          }

          const token = await persisted.getItem('authToken');

          if (token) {
            updateUserSetAuthTokenAndRedirect();
          } else if (res.data.token) {
            updateUserSetAuthTokenAndRedirect(res.data);
          }
        })
        .catch(error => {
          alertMessage(error);
        });
    },
    [dispatch, updateUserSetAuthTokenAndRedirect, userLoginNormal],
  );
};
