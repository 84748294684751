import React, { useRef } from 'react';
import { Platform, TextInput } from 'react-native';
import { useForm, Controller } from 'react-hook-form';
import styled, { useTheme } from 'styled-components/native';
import { Wrapper, Margin } from '@lendticket/ui/components/atoms';
import { InputField, Icon } from '@lendticket/ui/components/molecules';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useI18n } from '@lendticket/app/src/hooks/i18n';

const isIOS = Platform.OS === 'ios';
const ICON_DIMENSIONS = 35;
const ICON_DIMENSIONS_MOBILE = 30;
const BORDER_TOP_RADIUS = 7;
const HEIGHT = 80;

const PADDING_TOP = 12;
const MARGIN_LEFT = 8;
const BUTTON_MARGIN_TOP = 16;
const BUTTON_MARGIN_RIGHT = 22;

interface SendMessageInputFieldProps {
  isChatDead?: boolean;
  onPress: (message: string) => void;
}

const TouchableOpacity = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  padding-bottom: ${isIOS ? 16 : 0}px;
`;

const SendMessageInputField: React.FC<SendMessageInputFieldProps> = ({ isChatDead, onPress }) => {
  const i18n = useI18n();
  const inputRef = useRef<TextInput>(null);
  const { deviceSizes, settings, windowDimensions } = useTheme();
  const { top, bottom } = useSafeAreaInsets();
  const { windowWidth } = windowDimensions;
  const { SHADOWS, COLORS, CONSTANTS } = settings;
  const { DESKTOP_TABLET_WIDTH } = CONSTANTS;
  const { isMobile } = deviceSizes;
  const iconDimensions = isMobile ? ICON_DIMENSIONS_MOBILE : ICON_DIMENSIONS;
  const shadow = isMobile ? SHADOWS.BOX_SHADOW_TOP : {};

  const { control, handleSubmit, reset } = useForm({ mode: 'onBlur' });

  const onSubmit = (data: Record<string, any>) => {
    onPress(data.chatMessage);
    inputRef?.current?.clear();
    inputRef?.current?.blur();
    reset({});
  };

  return (
    <Wrapper
      fd="row"
      ai="center"
      jc="center"
      bc={COLORS.BACKGROUND_LIGHT_ORANGE}
      style={{
        marginBottom: isIOS ? bottom + 16 : 0,
        paddingBottom: 16,
        alignItems: 'flex-start',
        height: HEIGHT,
        width: isMobile ? windowWidth : DESKTOP_TABLET_WIDTH,
        borderTopRightRadius: BORDER_TOP_RADIUS,
        borderTopLeftRadius: BORDER_TOP_RADIUS,
        ...shadow,
      }}
    >
      <Margin marginLeft="md" />
      <Controller
        control={control}
        name="chatMessage"
        render={({ field: { onChange, value, onBlur } }) => (
          <InputField
            enabled
            editable={!isChatDead}
            inputRef={inputRef}
            hasDefaultHeight={true}
            hasFlexibleHeight={false}
            hasMultiline={true}
            hasFullWidth={true}
            hasBorder={false}
            placeholder={i18n.t('placeholderWriteNewMessage')}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={handleSubmit(onSubmit)}
            value={value}
            style={{ paddingTop: PADDING_TOP, marginLeft: MARGIN_LEFT }}
          />
        )}
      />
      <TouchableOpacity
        onPress={handleSubmit(onSubmit)}
        style={{ marginRight: BUTTON_MARGIN_RIGHT, marginTop: BUTTON_MARGIN_TOP }}
      >
        <Icon
          name="sendMessage"
          fill={COLORS.BACKGROUND_EXTRA_LIGHT_ORANGE}
          height={iconDimensions}
          width={iconDimensions}
        />
      </TouchableOpacity>
    </Wrapper>
  );
};

export default SendMessageInputField;
